import {
	IonButtons,
	IonHeader,
	IonIcon,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonAvatar, IonContent, IonList, IonInput, IonSpinner
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { archiveIcon, arrowTopIcon, askNewIcon, doneIcon, lockIcon, managerIcon } from "../../assets";
import "./styles.css"
import { useHistory } from "react-router";
import { useStore } from "effector-react";
import { List } from "immutable";
import { InputChangeEventDetail, ScrollDetail } from "@ionic/core";
import {
	activeQuestionUidStore,
	createQuestionEffect,
	fetchClientQuestionsEffect,
	ClientQuestion,
	setActiveQuestionUidEvent, clientQuestionsStore
} from "../../api/questions";
import {
	addMessage,
	deliveredMessagesStore,
	fetchClientMessagesEffect, fetchManagerMessagesEffect,
	Message, messagesFetchInterval,
	messagesStore,
	sendClientMessageAction
} from "../../api/messages";
import moment from "moment";
import { v4 } from "uuid"
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArchiveIcon } from "../../components/icons/ArchiveIcon";
import { formatDateTime } from "../../helpers/format";
import { $attachedManager } from "../../api/auth";

interface LocationState {
	question?: ClientQuestion
}

export const ClientChat = () => {

	const history = useHistory<LocationState>();
	const deliveredMessages = useStore(deliveredMessagesStore);

	const allMessages = useStore(messagesStore);
	const activeQuestionUid = useStore(activeQuestionUidStore);
	const questions = useStore(clientQuestionsStore);
	const attachedManager = useStore($attachedManager);

	const [messages, setMessages] = useState<List<Message>>(List());
	const [message, setMessage] = useState("");
	const [question, setQuestion] = useState<ClientQuestion | null>(null);
	const [lockChat, setLockChat] = useState(false);
	const [loader, setLoader] = useState(false);

	const chatRef = useRef<HTMLIonContentElement | null>(null);

	useEffect(() => {
		activeQuestionUid && fetchClientMessagesEffect(activeQuestionUid).then(() => {
			setTimeout(() => {
				chatRef.current?.scrollToBottom();
			}, 300);
		})
	}, [activeQuestionUid]);

	useEffect(() => {
		activeQuestionUid && setMessages(allMessages.get(activeQuestionUid) || List())
	}, [allMessages]);

	useEffect(() => {
		return createQuestionEffect.doneData.watch(data => {
			setActiveQuestionUidEvent(data.question.uid)
		})
	}, [])

	const createNewChat = () => {
		setActiveQuestionUidEvent(null);
		history.push("/chat");
	};

	useEffect(() => {
		return fetchClientMessagesEffect.pending.watch(v => setLoader(v))
	}, [])

	useEffect(() => {
		const find = questions.find(q => q.uid === activeQuestionUid);
		find && setQuestion(find);
	}, [questions, activeQuestionUid])

	useEffect(() => {
		return messagesFetchInterval.watch(() => {
			activeQuestionUid && fetchClientMessagesEffect(activeQuestionUid).then()
		})
	}, [activeQuestionUid])

	useEffect(() => {
		if (question && question?.result !== null) {
			setLockChat(true);
		}
	}, [question])

	const renderManagerMessage = (message: Message) => {
		const messageStyle = {...styles.message, marginBottom: 15};
		return (
			<div key={message.uid} style={messageStyle}>
				<IonAvatar style={{width: 35, height: 35}}>
					<img src={question?.employee.avatar ?? managerIcon}/>
				</IonAvatar>
				<div style={{...styles.messageBox, backgroundColor: "rgba(255, 255, 255, 0.92)"}}>
					{message.text}
				</div>
			</div>
		)
	};

	const renderOwnMessage = (message: Message) => {
		const messageItemStyle = {
			...styles.message,
			justifyContent: "flex-end",
		};
		return (
			<div key={message.uid} style={{display: "flex", flexDirection: "column", marginBottom: 10}}>
				<div style={messageItemStyle}>
					<div style={{...styles.messageBox, backgroundColor: "#96D1A0", color: "#222222"}}>
						{message.text}
					</div>
				</div>
				<div style={{alignSelf: "flex-end", marginRight: 16}}>
					<span style={styles.messageTime}>{formatDateTime(message.created_at, true)}</span>
					{deliveredMessages.has(message.uid) && <img src={doneIcon} style={{marginRight: 5}}/>}
				</div>
			</div>
		)
	};

	const renderTopBlock = (text: string) => {
		const container = {
			display: "flex",
			width: "100%",
			flex: 1,
			top: -1,
			justifyContent: "center",
			alignItems: "center",
			height: 32,
			backgroundColor: question?.category?.name === undefined ? "#66B980" : question?.result === null ? "#6CA3DF" : "#9B9B9B"
		};
		const textStyle = {
			fontSize: 15,
			fontWeight: 500,
			color: "white"
		};
		const iconName = question?.category?.icon || "faComment";
		// @ts-ignore
		const icon = faIcons[iconName];
		const iconCmp = <FontAwesomeIcon icon={icon} size={"lg"} color="white"/>
		return (
			<div style={container} slot="fixed">
				<div style={{position: "absolute", left: 10}}>{iconCmp}</div>
				<IonText style={textStyle}>{text}</IonText>
			</div>
		)
	};

	const renderInput = (lockChat: boolean) => {
		const sendHandler = async () => {
			if (message.length === 0) return;
			const uuid = v4();
			const newMessage = {
				created_at: moment().toISOString(),
				is_my: true,
				is_read: false,
				sender: null,
				text: message,
				uid: v4()
			}
			if (activeQuestionUid) {
				addMessage({message: newMessage, questionId: activeQuestionUid})
				await sendClientMessageAction({questionId: activeQuestionUid, message, uuid});
				await fetchClientMessagesEffect(activeQuestionUid);
				setMessage("");
			}
			if (activeQuestionUid === null && message.length > 0) {
				addMessage({message: newMessage, questionId: null});
				await createQuestionEffect({title: "NEW QUESTION", message: {content: message, uuid: uuid}});
				setMessage("");
			}
			await fetchClientQuestionsEffect()
		}
		const keyDown = async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
			if (event.keyCode === 13 && question?.uid && message.length > 0) {
				await sendHandler();
			}
		};
		const onChange = (event: CustomEvent<InputChangeEventDetail>) => {
			setMessage(event.detail.value || "")
		};
		const sendButtonStyle = {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: 30,
			height: 30,
			borderRadius: 15,
			backgroundColor: "#007AFF",
			marginRight: 4
		}
		return (
			<div style={styles.inputContainer} slot="fixed">
				<div style={styles.input}>
					<IonInput placeholder="Type a message" disabled={lockChat} onKeyDown={keyDown} value={message} onIonChange={onChange}/>
					{message != '' ? <div style={sendButtonStyle} onClick={sendHandler}>
						<img src={arrowTopIcon}/>
					</div> : null}
				</div>
			</div>
		)
	};

	const renderBottomButton = () => {
		return null;
		const contentStyle = {
			width: 176,
			height: 48,
			backgroundColor: "#007AFF",
			borderRadius: 40,
			boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
			bottom: 0,
			right: 0,
			display: "flex",
			flex: 1,
			justifyContent: "center",
			alignItems: "center",
			marginRight: 16,
			marginBottom: 22,
			zIndex: 101,
		};
		return (
			<div style={contentStyle} slot="fixed" onClick={createNewChat}>
				<IonText color="primary" style={{color: "white", marginLeft: 5, fontWeight: 500}}>Vraag new</IonText>
				<IonIcon src={askNewIcon} style={{marginLeft: 5, width: 28, height: 28}}/>
			</div>
		)
	};

	const renderMessages = () => {
		return messages.map(message => {
			return message.is_my ? renderOwnMessage(message) : renderManagerMessage(message)
		})
	};

	const renderLoader = () => {
		if (!loader) return null;
		return (
			<div style={{position: "absolute", top: "45%", left: "45%"}}>
				<IonSpinner color="primary"/>
			</div>
		)
	}

	const managerAvatar = attachedManager ? attachedManager.avatar : question?.employee.avatar ?? ""

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{display: "flex", flex: 1, alignItems: "center"}}>
					<IonButtons slot="start">
						<IonBackButton text="Vragen" color="primary"/>
					</IonButtons>
					<IonTitle>Manager</IonTitle>
					<IonButtons slot="end">
						<IonAvatar style={{width: 40, height: 40, marginRight: '5px'}}>
							{managerAvatar && <img src={managerAvatar}/>}
						</IonAvatar>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-client-content-chat" ref={chatRef}>
				{lockChat && <div slot="fixed" style={styles.lock}>
					<div style={styles.lockChat}>
						<ArchiveIcon color="#000000a6" width="48" height="42"/>
						<div style={{fontSize: 17, color: "#626463", marginTop: 5}}>Archived</div>
					</div>
				</div>}
				{renderTopBlock(question?.title || "New question")}
				<IonList style={{paddingTop: 50, paddingBottom: question?.result === null ? 50 : 90}}>
					{renderLoader()}
					{renderMessages()}
				</IonList>
				{renderBottomButton()}
				{renderInput(lockChat)}
			</IonContent>
		</IonPage>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	lockChat: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: '80px'
	},
	lock: {
		display: "flex",
		flexFlow: "column",
		justifyContent: "flex-end",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		zIndex: 100,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		pointerEvents: "none"
	},
	message: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		marginLeft: 10,
		marginRight: 10
	},
	messageBox: {
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		whiteSpace: "pre-wrap",
		marginLeft: 10,
		// marginRight: 10,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 7,
		paddingBottom: 7,
		borderRadius: 17,
		maxWidth: "90%"
	},
	messageTime: {
		marginRight: 10,
		fontSize: 11,
		color: "#869188"
	},

	infoBlock: {
		height: 64,
		borderRadius: 18,
		boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
		backgroundColor: "white",
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		paddingLeft: 19,
		paddingRight: 19,
	},
	infoTitle: {
		marginTop: 13,
		fontSize: 18,
		color: "#222222",
		fontWeight: "bold"
	},
	category: {
		fontSize: 13,
		color: "#858585"
	},
	statusText: {
		fontSize: 17,
		fontWeight: "bold",
		display: "flex",
		alignItems: "center"
	},
	inputContainer: {
		height: 52,
		width: "100%",
		bottom: 0,
		backgroundColor: "#FBFAFA",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTop: "1px solid #C9C8C8",
		paddingLeft: 10,
		paddingRight: 10
	},
	input: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		paddingLeft: 16,
		height: 36,
		border: "1px solid #EBEBEB",
		borderRadius: 16,
	}
};
