import React, { useEffect, useState } from 'react';
import { countryIcon, LoginIcon } from "../../assets";
import { Link } from "react-router-dom";
import { IonAlert, IonContent, IonInput, IonPage } from "@ionic/react";
import styles from "./css/FirstScreenLogIn.module.css"
import "./css/style.css"
import { useHistory } from "react-router";
import { InputChangeEventDetail } from "@ionic/core";
import { generateCodeByPhoneEffect, uidAuthStore } from "../../api/auth";
import { useStore } from "effector-react";
import { APP_VARIANT, AppVariant } from "../../config";

function FirstScreenLogIn() {
	const history = useHistory();
	const photoInit = APP_VARIANT === AppVariant.Client ? "2222222" : "1111111";
	const [phone, setPhone] = useState(photoInit);
	const [alert, setAlert] = useState(false);

	const uidAuth = useStore(uidAuthStore);

	useEffect(() => {
		return generateCodeByPhoneEffect.doneData.watch(data => {
			if (data.uid === null) {
				setAlert(true)
			}
		})
	}, []);

	useEffect(() => {
		generateCodeByPhoneEffect.fail.watch(console.log)
		generateCodeByPhoneEffect.failData.watch(console.log)
	}, [])

	useEffect(() => {
		if (uidAuth !== null && history.location.pathname !== "/code") {
			history.push("/code");
		}
	}, [uidAuth])


	const onPhoneChanged = (event: CustomEvent<InputChangeEventDetail>) => {
		setPhone(event.detail?.value ?? "");
	}

	const sendPhone = async () => {
		if (phone.length > 0) {
			generateCodeByPhoneEffect(phone).then();
		}
	}

	const inputRef = (ref: HTMLIonInputElement | null) => {
		ref?.getInputElement().then(el => el.focus())
	}

	const keyDown = async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
		if (event.keyCode === 13) {
			await sendPhone()
		}
	};

	const renderContainer = () => {
		return (
			<div className={styles.container}>
				<div className={styles.title}>
					Via deze dit venster kunnen allerlei vragen en berichten aan ons worden verstuurd.
				</div>
				<div className={styles.contact}>
					Contact App
				</div>
				<img className={styles.image} src={LoginIcon} alt="login"/>
				<Link style={{textDecoration: 'none'}} to={`/`}>
					<div className={styles.telefon}>Vul je telefoonnummer in
					</div>
				</Link>
				<div className={styles.wprapperInput}>
					<div className={styles.choiceCountry}>
						<img className={styles.iconCountry} src={countryIcon} alt="countryIcon"/>
						<span>+31</span>
					</div>
					<IonInput className={styles.input} inputmode="tel" type="tel" autofocus={true}
					          onIonChange={onPhoneChanged} value={phone} ref={inputRef} onKeyDown={keyDown}/>
				</div>
			</div>
		)
	}

	return (
		<IonPage>
			<IonContent>
				<IonAlert
					isOpen={alert}
					onDidDismiss={() => setAlert(false)}
					header={'Error'}
					message={'Code sending timeout, please try again later'}
					buttons={['OK']}
				/>
				{renderContainer()}
				<div slot="fixed" style={{bottom: 0, width: "100%", zIndex: 99999}} onClick={() => sendPhone()}>
					<div className={styles.button}>Verstuur SMS</div>
				</div>
			</IonContent>
		</IonPage>
	);
}

export default FirstScreenLogIn;


