import React, { useEffect, useState } from 'react';
import {
	IonContent,
	IonHeader,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar, isPlatform
} from '@ionic/react';
import { ManagerChatItem } from "../../components/ManagerChatItem";
import { useHistory } from "react-router";
import { useStore } from "effector-react";
import {
	fetchManagerQuestionsEffect, ManagerQuestion,
	managerQuestionsStore, setActiveQuestionUidEvent,
} from "../../api/questions";
import { searchIcon } from "../../assets";
import { orderBy, sortBy } from "lodash"

const IncomingList: React.FC = () => {

	const history = useHistory();
	const questions = useStore(managerQuestionsStore);

	const [search, setSearch] = useState("");

	const goToChat = (uid: string) => {
		setActiveQuestionUidEvent(uid)
		history.push("/chat");
	};

	useEffect(() => {
		fetchManagerQuestionsEffect().then()
	}, []);

	const renderQuestions = () => {
		return orderBy(questions, ["result", "changed_at"], ["asc"]).reverse().filter(el => el.client.name.toLowerCase().includes(search.toLowerCase())).map(question => {
			return <ManagerChatItem key={question.uid} question={question} onClick={() => goToChat(question.uid)}/>
		})
	};

	const renderContent = () => {
		return (
			<IonContent style={{backgroundColor: "#F8F7F7"}}>
				<IonList>
					{renderQuestions()}
				</IonList>
			</IonContent>
		)
	};

	const searchChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	}

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{marginTop: `${isPlatform('ios') ? '-10px' : '0px'}`,display: "flex", height:`${isPlatform('ios') ? '90px' : '50px'}` , justifyContent: "flex-start"}}>
					<div style={styles.title}>Inbox</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7">
					<div style={{position: "relative"}}>
						<input placeholder='Search in questions' style={{
							width: 'calc(100% - 10px)',
							margin: '0 5px',
							height: 36,
							background: 'rgba(142, 142, 147, 0.12)',
							borderRadius: '10px',
							border: 'none',
							paddingLeft: 30
						}} type="text" onChange={searchChangeText}/>
						<img style={{position: "absolute", top: '10px', left: "17px"}} src={searchIcon} alt=""/>
					</div>
				</IonToolbar>
			</IonHeader>
			{renderContent()}
		</IonPage>
	);
};
export default IncomingList;

const styles: { [key: string]: React.CSSProperties } = {
	newAsk: {
		display: 'flex',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end"
	},
	blueText: {
		fontStyle: "normal",
		fontWeight: "bold",
		fontSize: 17,
		color: "#007AFF"
	},
	title: {
		fontFamily: 'Medium',
		fontWeight: 500,
		fontSize: '17px',
		lineHeight: '22px',
		textAlign: 'center',
		letterSpacing: '0.02em',
		color: '#222222',
	},
	item: {
		marginTop: 10,
		marginBottom: 10,
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between"
	},
	itemTitle: {
		fontSize: 17,
		color: "#000000"
	},
	lastMsg: {
		fontSize: 15,
	},
	leftSideArea: {},
	date: {
		fontSize: 13,
		color: "#33D165"
	},
	unreadMsgCircle: {
		backgroundColor: "#33D165",
		width: 24,
		height: 24,
		color: "white",
		borderRadius: 24,
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 15
	},
	emptyContainer: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	emptyGray: {
		fontSize: 24,
		fontWeight: 500,
		color: "#B9B9B9",
		display: "flex",
		alignItems: "center"
	}
};
