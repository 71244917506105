import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const ForwardIcon = ({color,style}: Props) => {
	return (
		<div style={style}>
			<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 19L20 14L15 9M8 19L13 14L8 9" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>

)
};


