import React, { useEffect, useState } from 'react';
import styles from '../css/caseloadItem.module.css'
import { useHistory } from "react-router";
import { avatarIcon, keyIcon, lockIcon, NonSignedUpIcon } from "../../assets";
import LockIcon from "../client/SVGIcons/LockIcon";
import { useStore } from "effector-react";
import { caseloadStore, GroupByUser, setActiveCaseloadEvent, setActiveCaseloadUidEvent } from "../../api/caseload";
import { $clients, fetchClients, Client } from "../../api/clients";
import { fetchManagerQuestionsEffect } from "../../api/questions";
import { CaseItem } from "../CaseItem";
import moment from "moment";
import { $caseLoadTab } from "../../api/tabs";
import { formatDateTime, getAbbreviation } from "../../helpers/format";

interface Props {
	setPath: any,
	path: string,
	id: string,
	setId: any,
	setSearch: any,
	search: string,
	setGroup: any
}

const CaseLoadItems = (props: Props) => {


	const caseload = useStore(caseloadStore)
	const clients = useStore($clients);
	const caseloadTab = useStore($caseLoadTab);


	useEffect(() => {
		fetchManagerQuestionsEffect().then();
		fetchClients().then();
		props.setGroup(caseload)
	}, [])

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	const history = useHistory();


	const goToCaseLoadDetail = (group: GroupByUser) => {
		props.setId(group.uid)
		console.log('group',group)
		setActiveCaseloadEvent(group);
		setActiveCaseloadUidEvent(group.uid);
		history.push(`/caseload/detail/${group.uid}`);
		props.setSearch('');
	};

	const renderNonSigned = (client: Client) => {
		return (
			<div key={client.uid} className={styles.item}>
				<div className={styles.wrapperAbreviature}>
					<div style={{backgroundColor: 'rgba(250, 76, 65, 0.6)'}}
					     className={styles.abreviature}>{getAbbreviation(client.name)}</div>
				</div>
				<div className={styles.itemContentWrapper}>
					<div className={styles.itemInfo}>
						<div className={styles.name}>{client.name}</div>
					</div>
					<div className={styles.wrapperTime}>
						<div className={styles.time}></div>
						<div className={styles.stateItem}>
							<div className={styles.wrapperState}><span
								style={{color: '#FA4C41'}}>Not signed up</span><img src={NonSignedUpIcon}
							                                                        alt="lockIcon"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderClosedCase = (Case: GroupByUser) => {
		return (
			<div key={Case.clientName} className={styles.item} onClick={() => goToCaseLoadDetail(Case)}>
				<div className={styles.wrapperAbreviature}>
					<div style={{backgroundColor: 'rgba(0, 0, 0, 0.85)'}}
					     className={styles.abreviature}>{getAbbreviation(Case.clientName)}</div>
				</div>
				<div className={styles.itemContentWrapper}>
					<div className={styles.itemInfo}>
						<div className={styles.name}>{Case.clientName}</div>
						<div className={styles.type}>{Case.lastTitle}</div>
					</div>
					<div className={styles.wrapperTime}>
						<div className={styles.time}>{formatDateTime(Case.lastDate)}</div>
						<div className={styles.stateItem}>
							<div className={styles.wrapperState}><span
								style={{color: 'rgba(0, 0, 0, 0.6)'}}>CLOSED</span><img src={lockIcon} alt="lockIcon"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const caseItem = (Case: GroupByUser) => {
		const timeColor = Case.unreadCount > 0 ? {color: '#33D165'} : {}
		return (
			<div key={Case.clientName} className={styles.item} onClick={() => goToCaseLoadDetail(Case)}>
				<div className={styles.wrapperAbreviature}>
					<div style={{backgroundColor: '#6CA3DF'}}
					     className={styles.abreviature}>{getAbbreviation(Case.clientName)}</div>
				</div>
				<div className={styles.itemContentWrapper}>
					<div className={styles.itemInfo}>
						<div className={styles.name}>{Case.clientName}</div>
						<div className={styles.type}>{Case.lastTitle}</div>
					</div>
					<div className={styles.wrapperTime}>
						<div style={timeColor}
						     className={styles.time}>{formatDateTime(Case.lastDate)}</div>
						<div className={styles.stateItem}>
							{Case.unreadCount > 0 && <div className={styles.wrapperState}>
								<div className={styles.countMessages}>{1}</div>
							</div>}

						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderCases = () => {
		if (caseloadTab === "non_signed") {
			return clients.map(client => {
				return renderNonSigned(client)
			})
		}
		if (caseloadTab === "closed") {
			return caseload.filter(el => el.clientName.includes(props.search)).map((Case): JSX.Element | null => {
					const client = clients.find(cl => cl.uid === Case.uid);
					return (client?.is_active === false) ? renderClosedCase(Case) : null
				}
			).toList().toArray()
		}
		return caseload.filter(el => el.clientName.includes(props.search)).map((Case): JSX.Element | null => {
				const client = clients.find(cl => cl.uid === Case.uid);
				return (client?.is_active === true) ? caseItem(Case) : null;
			}
		).toList().toArray()
	}


	return (
		<>
			{renderCases()}
		</>
	)
}


export default CaseLoadItems;
