import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const KeyIcon = ({color,style}: Props) => {
	return (
		<div style={style}>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Icon / Non Signed Up">
					<path id="icon/communication/vpn_key_24px"  fill={color} fill-rule="evenodd" clipRule="evenodd" d="M5.76912 6.1211C8.89912 5.5011 11.6991 7.3111 12.6491 10.0011H20.9991C22.0991 10.0011 22.9991 10.9011 22.9991 12.0011C22.9991 13.1011 22.0991 14.0011 20.9991 14.0011V16.0011C20.9991 17.1011 20.0991 18.0011 18.9991 18.0011C17.8991 18.0011 16.9991 17.1011 16.9991 16.0011V14.0011H12.6491C11.8291 16.3311 9.60912 18.0011 6.99912 18.0011C3.25912 18.0011 0.319116 14.5711 1.13912 10.7011C1.61912 8.4111 3.47912 6.5811 5.76912 6.1211ZM4.99912 12.0011C4.99912 13.1011 5.89912 14.0011 6.99912 14.0011C8.09912 14.0011 8.99912 13.1011 8.99912 12.0011C8.99912 10.9011 8.09912 10.0011 6.99912 10.0011C5.89912 10.0011 4.99912 10.9011 4.99912 12.0011Z" />
				</g>
			</svg>

		</div>

)
};


