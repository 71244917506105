import React, { useEffect, useState } from 'react';
import styles from './css/LogInPage.module.css'
import { unlockIcon, organizationIcon, mailIcon, logoIcon, xIcon } from '../../assets';
import { IonPage } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { useStore } from "effector-react";
import { fetchAuthEffect, isAuthStore, sidStore, userStore } from "../../api/auth";
import { saveDataToStorage } from "../../api/storage";
import { DbKeys } from "../../helpers/variants";

const LogInPage = () => {

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [org, setOrg] = useState("")

	const next = async () => {
		await fetchAuthEffect({email, password, org});
	};

	useEffect(() => {
		return fetchAuthEffect.doneData.watch(data => {
			if (data.sid) {
				saveDataToStorage("web_sid", data.sid).then();
			}
			if (data.user) {
				saveDataToStorage(DbKeys.defaultUser, JSON.stringify(data.user)).then();
			}
		})
	}, [])

	return (
		<IonPage className={styles.wrapper}>
			<div className={styles.title}>
				Onze App
			</div>
			<div className={styles.logo}>
				<img src={logoIcon}/>
			</div>
			<div className={styles.form}>
				<div className={styles.itemForm}>
					<input type='Email' placeholder='Email' value={email}
					       onChange={event => setEmail(event.target.value)}/>
					<img src={mailIcon}/>
				</div>
				<div className={styles.itemForm}>
					<input type='password' placeholder='Password' value={password}
					       onChange={event => setPassword(event.target.value)}/>
					<img src={unlockIcon}/>
				</div>
				{/*<div className={styles.itemForm}>
					<input style={{border: '1px solid red', color: 'red'}} type='password' placeholder='Password'/>
					<img src={xIcon}/>
				</div>*/}
				<div className={styles.itemForm}>
					<input type='text' placeholder='Organization' value={org}
					       onChange={event => setOrg(event.target.value)}/>
					<img src={organizationIcon}/>
				</div>
				<button onClick={next} className={styles.button}>Next</button>
				{/*<button onClick={() => next()} style={{opacity: 0.4, pointerEvents: "none"}}
				        className={styles.button}>Next
				</button>*/}
				{/*<div className={styles.forgotPass}>Forgot password? We’ll send reset link on your email.</div>

				<div className={styles.itemForm}>
					<input type='Email' placeholder='Email'/>
					<img src={mailIcon}/>
				</div>
				<button onClick={() => next()} className={styles.button}>Reset password</button>*/}
			</div>
		</IonPage>
	);
}


export default LogInPage;
