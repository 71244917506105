import { createEffect, createEvent, createStore, fromObservable, guard } from "effector";
import { API_ENDPOINT } from "./index";
import axios from "axios";
import { interval } from "rxjs";
import { isAuthStore } from "./auth";
import { APP_VARIANT, AppVariant } from "../config";

const newsFetchInterval = fromObservable(interval(10000))

interface News {
	uid: string;
	title: string;
	short: string;
	published_at: string;
	image_src: string | null;
	category: {
		uid: string;
		name: string;
		icon: string;
		is_default: boolean;
	};
	author: {
		name: string;
		avatar: string;
	};
}

interface NewsDetail extends News {
	content: string;
}

export const fetchNewsEffect = createEffect<void, { news: News[] }>({
	handler: async () => {
		const req = await axios.get(`${API_ENDPOINT}/client/news`);
		return req.data
	}
})

if (APP_VARIANT === AppVariant.Client) {
	guard(newsFetchInterval, {filter: isAuthStore, target: fetchNewsEffect})
}

export const newsStore = createStore<News[]>([])
	.on(fetchNewsEffect.doneData, (store, data) => data.news);


export const restoreNewsDetailEvent = createEvent();

export const fetchNewsDetailEffect = createEffect<string, { news: NewsDetail }>({
	handler: async (id: string) => {
		const req = await axios.get(`${API_ENDPOINT}/client/news/${id}`);
		return req.data
	}
})

export const activeNewsStore = createStore<NewsDetail | null>(null)
	.on(fetchNewsDetailEffect.doneData, (store, data) => data.news)
	.reset(restoreNewsDetailEvent);
