import React, { useEffect } from 'react';
import styles from './css/vragenList.module.css'
import { useHistory } from "react-router";
import { askNewIcon, checkIcon, managerIcon } from "../../assets";
import ArchiveIcon from "../SVGIcons/ArchiveIcon";
import GeneralIcon from "./SVGIcons/GeneralIcon";
import { useStore } from "effector-react";
import {
	activeQuestionUidStore, ClientQuestion,
	clientQuestionsStore,
	fetchClientQuestionsEffect,
	setActiveQuestionUidEvent
} from "../../api/questions";
import moment from "moment";
import { $sidActivated, isAuthStore } from "../../api/auth";
import { $selectedTab } from "../../api/tabs";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { CircleAvatar } from "../CircleAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "../../helpers/format";

interface Props {
	setPath: any,
	setId: any,
	setIdItem: any,
	idItem: string,
	setScrollChat: any
}

const VragenList = (props: Props) => {

	const history = useHistory();

	const questions = useStore(clientQuestionsStore)
	const activeUid = useStore(activeQuestionUidStore)
	const selectedTab = useStore($selectedTab)
	const sidActivated = useStore($sidActivated);

	useEffect(() => {
		sidActivated && fetchClientQuestionsEffect().then();
	}, [sidActivated])

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	const goToDetail = async (uid: string) => {
		history.push(`/vragen/detail/${uid}`);
		setActiveQuestionUidEvent(uid);
		props.setScrollChat(true)
	};

	const getActivePrimaryColor = (uid: string) => {
		return activeUid === uid ? 'white' : '#6CA3DF'
	}

	const getActiveSecondaryColor = (uid: string) => {
		return activeUid === uid ? '#6CA3DF' : 'white'
	}

	const getArchivedPrimaryColor = (uid: string) => {
		return activeUid === uid ? 'rgba(0, 0, 0, 0.4)' : 'white'
	}

	const getArchivedSecondaryColor = (uid: string) => {
		return activeUid === uid ? 'white' : 'rgba(0, 0, 0, 0.4)';
	}

	const questionFilter = (q: ClientQuestion) => {
		if (selectedTab === "active") {
			return q.result === null;
		}
		if (selectedTab === "archived") {
			return q.result !== null;
		}
	}

	const getActiveQuestionBackgroundColor = (uid: string) => {
		return activeUid === uid ? {backgroundColor: 'rgb(108, 163, 223)'} : {};
	}

	const getArchivedQuestionBackgroundColor = (uid: string) => {
		return activeUid === uid ? {backgroundColor: 'rgba(0, 0, 0, 0.4)'} : {backgroundColor: "white"};
	}

	const getBackgroundColor = (active: boolean, uid: string) => {
		return active ? getActiveQuestionBackgroundColor(uid) : getArchivedQuestionBackgroundColor(uid)
	}

	const getPrimaryIconBgColor = (active: boolean, uid: string) => {
		return active ? getActivePrimaryColor(uid) : getArchivedSecondaryColor(uid)
	}

	const getSecondaryIconBgColor = (active: boolean, uid: string) => {
		return active ? getActiveSecondaryColor(uid) : getArchivedPrimaryColor(uid)
	}

	const renderQuestions = (): JSX.Element[] => {
		return questions.filter(questionFilter).map(question => {
			const bgStyle = getBackgroundColor(question.result === null, question.uid)
			const styleWhite = activeUid === question.uid ? {color: 'white'} : {}
			const timeStyle = activeUid === question.uid ? {color: 'white'} : question.unread_count ? {color: '#33D165'} : {}
			const iconName = question.category?.icon || "faComment";
			// @ts-ignore
			const icon = faIcons[iconName];
			const avatar = <CircleAvatar
				icon={<FontAwesomeIcon icon={icon} size={"lg"}
				                       color={getSecondaryIconBgColor(question.result === null, question.uid)}/>}
				color={getPrimaryIconBgColor(question.result === null, question.uid)}/>;
			return (
				<div key={question.uid} className={styles.item} style={bgStyle}
				     onClick={() => goToDetail(question.uid)}>
					<div className={styles.wrapperMainIcon}>
						{avatar}
					</div>
					<div className={styles.itemContentWrapper}>
						<div className={styles.itemInfo}>
							<div className={styles.titleItem} style={styleWhite}>{question.title.length < 20 ? question.title : `${question.title.slice(0,20)}...`}</div>
							<div className={styles.message} style={styleWhite}>{question.last_message.length < 20 ? question.last_message : `${question.last_message.slice(0,20)}...`}
							</div>
						</div>
						<div className={styles.wrapperTime}>
							<div className={styles.time}
							     style={timeStyle}>{formatDateTime(question.changed_at)}</div>
							<div className={styles.stateItem}>
								{question.unread_count > 0 && <div className={styles.wrapperState}>
									<div className={styles.countMessages}>{question.unread_count}</div>
								</div>}
								{question.result !== null ? <div className={styles.wrapperState}><span
									style={{color: `${question.uid == props.idItem ? 'white': 'rgba(0, 0, 0, 0.6)'}`}}>archived</span><ArchiveIcon color={`${question.uid == props.idItem ? 'white': 'rgba(0, 0, 0, 0.6)'}`} /></div> : null}
							</div>
						</div>
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{renderQuestions()}
		</>
	)
}


export default VragenList;
