import React, { useEffect, useState } from 'react';
import styles from '../css/incommingItems.module.css'
import { circleIcon } from "../../assets";
import { useHistory } from "react-router";
import Diagramm from "../SVGIcons/Diagramm";
import BissnessIcon from "../client/SVGIcons/BissnessIcon";
import NonTypeIcon from "../client/SVGIcons/NonTypeIcon";
import GeneralIcon from "../client/SVGIcons/GeneralIcon";
import {
	activeQuestionUidStore,
	fetchManagerQuestionsEffect, ManagerQuestion,
	managerQuestionsStore,
	setActiveQuestionUidEvent
} from "../../api/questions";
import { useStore } from "effector-react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { CircleAvatar } from "../CircleAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ManagerChatItem } from "../ManagerChatItem";
import moment from "moment";
import ArchiveIcon from "../SVGIcons/ArchiveIcon";
import { sortBy, orderBy } from "lodash";
import { formatDateTime } from "../../helpers/format";

interface Props {
	setPath: any,
	path: string,
	idItem: string,
	setIdItem: any,
	search: string,
	setSearch: any,
	setScrollChat: any
}

const IncommingItems = (props: Props) => {
	const history = useHistory();
	const questions = useStore(managerQuestionsStore);
	const activeUid = useStore(activeQuestionUidStore)

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	const goTo = (id: string) => {
		let thisPath = window.location.pathname;
		props.setIdItem(`${id}`)
		setActiveQuestionUidEvent(id)
		history.push(`/incomming/${id}`);
		props.setPath(thisPath)
		props.setScrollChat(true)
	};

	useEffect(() => {
		fetchManagerQuestionsEffect().then()
	}, []);

	const renderQuestions = () => {
		return orderBy(questions,["result"], ["desc"]).filter(el => props.search != '' ? el.title.toLowerCase().includes(props.search) : el).map(question => {
			if (props.idItem === question.uid) {
				var active = true
			} else {
				var active = false
			}
			if (props.idItem === '') {
				props.setIdItem(`${question.uid}`)
			}

			const dateColor = question.unread_count > 0 ? "#33D165" : "#999999";
			const nonType = (status: any) => question?.category?.identifier === 'nonType' ?
				status != null ? 'rgba(0, 0, 0, 0.4)' : '#66B980'
				:
				status != null ? 'rgba(0, 0, 0, 0.4)' : '#6CA3DF';

			const checkArchive = (iconName: string,active: boolean, status: any) => {
				if (status != null) {
					return active ? iconName ==='faComment' ? '#75ca90':'rgb(153, 153, 153)' : 'white'
				} else {
					return active ? iconName ==='faComment' ? '#75ca90':'#6CA3DF' : 'white'
				}
			}
			const checkArchiveText = (active: boolean, status: any) => {
				if (status != null)
					return 'rgba(0, 0, 0, 0.4)'
				else
					return '#222222'
			}
			const renderIcons = (active: boolean, type: string | null) => {
				if (type != null) {
					switch (type) {
						case "general":
							return <GeneralIcon
								stroke={`${active ? checkArchive(iconName,active, question.result) : checkArchive(iconName,active, question.result)}`}/>
						case "money":
							return <BissnessIcon
								stroke={`${active ? checkArchive(iconName,active, question.result) : checkArchive(iconName,active, question.result)}`}/>
						default:
							return <NonTypeIcon
								stroke={`${active ? checkArchive(iconName,active, question.result) : checkArchive(iconName,active, question.result)}`}/>
					}
				} else {
					return <NonTypeIcon stroke={`${active ? checkArchive(iconName,active, question.result) : 'white'}`}/>
				}
			}
			const getActivePrimaryColor = (iconName: string,uid: string) => {
				return activeUid === uid ? 'white' : iconName ==='faComment' ? '#75ca90': '#6CA3DF'
			}

			const getActiveSecondaryColor = (iconName: string,uid: string) => {
				return activeUid === uid ? iconName ==='faComment' ? '#75ca90': '#6CA3DF' : 'white'
			}

			const getArchivedPrimaryColor = (iconName: string,uid: string) => {
				return activeUid === uid ? iconName ==='faComment' ? '#75ca90': 'rgba(0, 0, 0, 0.4)' : 'white'
			}

			const getArchivedSecondaryColor = (uid: string) => {
				return activeUid === uid ? 'white' : 'rgba(0, 0, 0, 0.4)';
			}
			const getPrimaryIconBgColor = (iconName:string,active: boolean, uid: string) => {
				return active ? getActivePrimaryColor(iconName,uid) : getArchivedSecondaryColor(uid)
			}

			const getSecondaryIconBgColor = (iconName:string,active: boolean, uid: string) => {
				return active ? getActiveSecondaryColor(iconName,uid) : getArchivedPrimaryColor(iconName,uid)
			}
			const iconName = question.category?.icon || "faComment";
			console.log('iconName',iconName)
			console.log('iconName',question.category)
			// @ts-ignore
			const icon = faIcons[iconName];
			const avatar = <CircleAvatar
				icon={<FontAwesomeIcon icon={icon || 'faComment'} size={"1x"}
				                       color={getSecondaryIconBgColor(iconName,question.result === null, question.uid)}/>}
				color={getPrimaryIconBgColor(iconName,question.result === null, question.uid)}/>;
			return (
				<div key={question.uid} className={`${styles.item} ${false ? styles.fixedItem : null}`}
				     style={{backgroundColor: `${checkArchive(iconName,active, question.result)}`}}
				     onClick={() => goTo(question.uid)}>
					<div className={styles.wrapperMain}>
						<div className={styles.wrapperMainIcon}>
							{avatar}
						</div>
					</div>
					<div className={styles.itemContentWrapper}>
						<div className={styles.itemInfo}>
							<div className={styles.titleItem}
							     style={{color: `${active ? 'white' : '#222222'}`}}>{question.title.length < 20 ? question.title : `${question.title.slice(0, 20)}...`}</div>
							<div className={styles.name}
							     style={{color: `${active ? 'white' : '#222222'}`}}>{question.client.name}</div>
							<div className={styles.message}
							     style={{color: `${active ? 'white' : 'rgba(0, 0, 0, 0.4)'}`}}>{question.last_message.length < 25 ? question.last_message : `${question.last_message.slice(0, 25)}...`}
							</div>
						</div>
						<div className={styles.wrapperTime}>
							{question.changed_at && <div className={styles.time}
							                             style={{color: `${active ? 'white' : dateColor}`}}>{formatDateTime(question.changed_at)}</div>}
							<div className={styles.stateItem}>
								{question.unread_count != 0 && <div className={styles.wrapperState}>
									<div className={styles.countMessages}>{question.unread_count}</div>
								</div>}
								{question.result !== null ? <div className={styles.wrapperStateArchived}><span
									style={{color: `${question.uid == props.idItem ? 'white' : 'rgba(0, 0, 0, 0.6)'}`}}>archived</span><ArchiveIcon
									color={`${question.uid == props.idItem ? 'white' : 'rgba(0, 0, 0, 0.6)'}`}/>
								</div> : null}
							</div>
						</div>
					</div>
				</div>
			)
		})
	};

	return (
		<>
			{renderQuestions()}
		</>
	);
}


export default IncommingItems;
