import styles from "./css/MiniAvatar.module.css";
import { getAbbreviation } from "../helpers/format";
import React from "react";

interface Props {
	name?: string;
}

export const MiniAvatar = ({name}: Props) => {
	return (
		<div className={styles.wrapperHeader}>
			<div className={styles.avatarCircle}>
				<div className={styles.circleFont}>{getAbbreviation(name ?? "")}</div>
			</div>
		</div>
	)
}
