import React from "react";

export interface Props {
	color: string;
}

export const NewsIcon = ({color}: Props) => {
	return (
		<svg width="28" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6 25.5H22.5C23.2956 25.5 24.0587 25.1839 24.6213 24.6213C25.1839 24.0587 25.5 23.2956 25.5 22.5V3H9V6"  stroke={color?color:"#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9 22.5C9 23.2956 8.68393 24.0587 8.12132 24.6213C7.55871 25.1839 6.79565 25.5 6 25.5C5.20435 25.5 4.44129 25.1839 3.87868 24.6213C3.31607 24.0587 3 23.2956 3 22.5V6H9V22.5Z"  stroke={color?color:"#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15 15H12M22.5 6H12H22.5ZM22.5 18H12H22.5ZM22.5 21H12H22.5ZM18 9H22.5V15H18V9ZM15 9H12.75H15ZM15 12H12H15Z"  stroke={color?color:"#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

)
};
