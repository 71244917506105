import React from 'react';

const ChevronsRight = (props) => {

    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 19L20 14L15 9M8 19L13 14L8 9" stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default ChevronsRight;
