import React from 'react';

const ChevronsIcon = (props) => {

    return (
        <>
            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 17.4148L14 22.4148L19 17.4148M19 11.4148L14 6.41479L9 11.4148" stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default ChevronsIcon;
