import React, { useEffect, useState } from 'react';
import styles from './mainPage.module.css'
import { IonPage, IonRouterOutlet } from "@ionic/react";

import { Route, Switch } from "react-router-dom";
import LeftPath from "../../components/manager/LeftPathComponent";
import RightPathComponent from "../../components/manager/RightPathComponent";
import RightPathInboxComponent from "../../components/manager/RightPathInboxComponent";
import NewsItem from "../../components/client/NewItem";
import ChatWithManager from "../../components/client/ChatWithManager";
import SettingsRightPath from "../../components/client/SettingsRightPath";
import { useHistory } from "react-router";
import CloseClientCase from "../../components/manager/CloseClientCase";
import { useStore } from "effector-react";
import { fetchStatusEffect, isAuthStore, sidStore, userStore } from "../../api/auth";
import CaseLoadItems from "../../components/manager/CaseLoadItems";
import {fetchManagerQuestionsEffect, setActiveQuestionUidEvent} from "../../api/questions";
import {caseloadStore, setActiveCaseloadEvent, setActiveCaseloadUidEvent} from "../../api/caseload";
import {$clients, fetchClients} from "../../api/clients";
import {$caseLoadTab} from "../../api/tabs";
import {fetchNewsDetailEffect} from "../../api/news";

export  const MainPage = ()  => {
	const history = useHistory();
	const [search, setSearch] = useState('')
	const [group, setGroup] = useState({})

	const user = useStore(userStore);

	const caseload = useStore(caseloadStore)

	useEffect(() => {
		fetchManagerQuestionsEffect().then();
		fetchClients().then();
	}, [])

	const [devices, setDevices] = useState(54)
	const [tubsSettings, setTubsSettings] = useState('devices')
	const [scrollChat, setScrollChat] = useState(true)

	const [path, setPath] = useState(window.location.pathname);
	const [idChatItem, setIdChatItem] = useState('');
	const [idChat, setIdChat] = useState('');
	const [newsId, setNewsId] = useState('');





	useEffect(() => {
		var splits = path.split('/', 5);
		if (splits[1] === 'caseload') {
			if (splits[3]) {

				setIdChat(splits[3]);
				const activeCase = caseload.get(idChat);
				activeCase && setGroup(activeCase)

				activeCase && setActiveCaseloadEvent(activeCase);
				setActiveCaseloadUidEvent(splits[3]);
				if (splits[4]) {
					setActiveQuestionUidEvent(splits[4]);
					setIdChatItem(splits[4]);
				}
			}
		} else if (splits[1] === 'incomming') {
			if (splits[2]) {
				setActiveQuestionUidEvent(splits[2])
				setIdChatItem(splits[2]);
			}
		} else if (splits[1] === 'vragen') {
			if (splits[3]) {
				if(splits[3] !== "new") {
					setActiveQuestionUidEvent(splits[3]);
				}
				setIdChatItem(splits[3]);

			}
		} else if (splits[1] === 'news') {
			if (splits[2]) {
				setNewsId(splits[2]);
			}
		}
		history.push(window.location.pathname);
	}, [path, idChat,idChatItem])

	const clearId = () => {
		setIdChatItem('')
		setIdChat('')
		setNewsId('')
		setActiveQuestionUidEvent(null);
		setActiveCaseloadUidEvent(null)
	}


	useEffect(() => {
		var thisPath = window.location.pathname;
		switch (user?.type) {
			case "client":
				if (thisPath === '/') {
					setPath('/vragen')
					return history.push('/vragen');
				} else {
					setPath(thisPath)
					return history.push(thisPath);
				}
			case "employee":
				if (thisPath === '/') {
					setPath('/incomming')
					return history.push('/incomming');
				} else {
					setPath(thisPath)
					return history.push(thisPath);
				}
		}
	}, [])

	const renderManager = () => {
		return (
			<>
				<IonRouterOutlet>
					<Route path="/incomming" exact={true}/>
					<Route path="/caseload" exact={true}/>
					<Route path="/settings" exact={true}/>
				</IonRouterOutlet>
				<IonPage>
					<div className={styles.mainWrapper}>
						<div className={styles.wrapper}>
							<div className={`${styles.leftPath} ${styles.animate}`}>
								<LeftPath
									clearId={clearId}
									setScrollChat={setScrollChat}
									group={group}
									setGroup={setGroup}
									search={search} setSearch={setSearch}
									tubsSettings={tubsSettings}
									setTubsSettings={setTubsSettings}
									setDevices={setDevices}
									idItem={idChatItem}
									idChatItem={idChatItem}
									setIdItem={setIdChatItem}
									id={idChat}
									setId={setIdChat}
									path={path}
									setPath={setPath}/>
							</div>
							<div className={styles.rightPath}>
								{path === `/caseload/detail/${idChat}/${idChatItem}` &&
								<RightPathInboxComponent
									scrollChat={scrollChat}
									setScrollChat={setScrollChat}
									search={search} setSearch={setSearch}
									idChatItem={idChatItem}
									path={path} setPath={setPath}
								/>}
								{path === `/caseload/detail/${idChat}` &&
								<CloseClientCase
									tubsSettings={tubsSettings}
									devices={devices}
									idChatItem={idChatItem}
									path={path} setPath={setPath}
								/>}
								{idChatItem !== '' && path === `/incomming/${idChatItem}` &&
								<RightPathInboxComponent
									scrollChat={scrollChat}
									setScrollChat={setScrollChat}
									search={search} setSearch={setSearch}
									idChatItem={idChatItem}
									path={path} setPath={setPath}
								/>}
								{path === `/settings` &&
								<SettingsRightPath tubsSettings={tubsSettings} devices={devices}
								                   idChatItem={idChatItem} path={path} setPath={setPath}/>}
							</div>
						</div>
					</div>
				</IonPage>
			</>
		)
	}

	const renderClient = () => {

		return (
			<>
				<IonRouterOutlet>
					<Route path="/vragen" exact={true}/>
					<Route path="/news" exact={true}/>
					<Route path="/settings" exact={true}/>
				</IonRouterOutlet>
				<IonPage>
					<div className={styles.mainWrapper}>
						<div className={styles.wrapper}>
							<div className={`${styles.leftPath} ${styles.animate}`}>
								<LeftPath
									clearId={clearId}
									setScrollChat={setScrollChat}
									group={group}
									setGroup={setGroup}
									search={search} setSearch={setSearch}
									setNewsId={setNewsId}
									tubsSettings={tubsSettings}
									setTubsSettings={setTubsSettings}
									setDevices={setDevices}
									idItem={idChatItem}
									idChatItem={idChatItem}
									setIdItem={setIdChatItem}
									id={idChat} setId={setIdChat}
									path={path} setPath={setPath}/>
							</div>
							<div className={styles.rightPath}>
								{path === `/vragen/detail/${idChatItem}` && <ChatWithManager scrollChat={scrollChat} setScrollChat={setScrollChat}/>}
								{newsId !== '' && path === `/news/${newsId}` &&
								<NewsItem newsId={newsId} path={path} setPath={setPath}/>}
								{path === `/settings` &&
								<SettingsRightPath tubsSettings={tubsSettings} devices={devices}
								                   idChatItem={idChatItem} path={path} setPath={setPath}/>}
							</div>
						</div>
					</div>
				</IonPage>
			</>
		)
	}

	if (user?.type === "employee") {
		return renderManager()
	} else {
		return renderClient()
	}
}
