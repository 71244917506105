import React, {useEffect, useState} from 'react';
import styles from './modal.module.css'
import BissnessIcon from "../client/SVGIcons/BissnessIcon";
import NonTypeIcon from "../client/SVGIcons/NonTypeIcon";

interface Props {
    done: string,
    cancel: string,
    question: string,
    secondText?: string,
    doneFunc: any,
    cancelFunc: any
}

const ModalAlert = (props:Props) => {

    return (
        <>
           <div className={styles.modalWrapper}>
               <div className={styles.mainContentWrapper}>
                    <div className={styles.mainPath}>
                        <div className={styles.wrapperAlert}>
                            <div className={styles.question}>{props.question}</div>
                            <div className={styles.secondText}>{props.secondText}</div>
                        </div>
                    </div>
                    <div className={styles.footerPath}>
                        <div onClick={props.cancelFunc} className={styles.cancel}>{props.cancel}</div>
                        <div onClick={props.doneFunc} className={styles.alertDone}>{props.done}</div>
                    </div>
               </div>
           </div>
        </>
    );
}


export default ModalAlert;
