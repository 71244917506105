import React from "react";

export interface Props {
	color: string;
	style?: any;
	width?: string;
	height?: string
}

export const CloseCaseIcon = ({color, style, width = "24", height = "21"}: Props) => {
	return (
		<div style={style}>
			<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.71422 11.1429V7.7143C7.71422 6.57766 8.16575 5.48757 8.96947 4.68385C9.7732 3.88012 10.8633 3.42859 11.9999 3.42859C13.1366 3.42859 14.2267 3.88012 15.0304 4.68385C15.8341 5.48757 16.2856 6.57766 16.2856 7.7143V11.1429M5.99993 11.1429H17.9999C18.9467 11.1429 19.7142 11.9104 19.7142 12.8572V18.8572C19.7142 19.8039 18.9467 20.5714 17.9999 20.5714H5.99993C5.05316 20.5714 4.28564 19.8039 4.28564 18.8572V12.8572C4.28564 11.9104 5.05316 11.1429 5.99993 11.1429Z" stroke={color} stroke-opacity="0.8" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>

	)
};
