import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const CategoryIcon = ({color,style}: Props) => {
	return (
		<div style={style}>

			<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.71429 14.5715L12 18.8572L16.2857 14.5715M16.2857 9.4286L12 5.14288L7.71429 9.4286" stroke={color} stroke-opacity="0.6" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>

		</div>

)
};


