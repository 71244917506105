import React, { useEffect } from 'react';
import {IonContent, IonHeader, IonList, IonPage, IonText, IonTitle, IonToolbar, isPlatform} from '@ionic/react';
import styles from './css/News.module.css';
import { NewsItem } from "../../components/NewsItem";
import { fetchNewsDetailEffect, fetchNewsEffect, newsStore } from "../../api/news";
import { useStore } from "effector-react";
import moment from "moment";
import { useHistory } from "react-router";

const News: React.FC = () => {

	const history = useHistory();
	const news = useStore(newsStore);

	useEffect(() => {
		fetchNewsEffect().then()
	}, []);

	const goToNews = async (uid: string) => {
		fetchNewsDetailEffect(uid).then();
		history.push("/news/detail")
	}

	const renderNews = () => {
		return news.map(news => <NewsItem name={news.category.name} text={news.title} image={news.image_src}
		                                  key={news.uid} onClick={() => goToNews(news.uid)}
		                                  data={moment(news.published_at).format("DD-MM-YYYY")}/>)
	}

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7",paddingTop: `${isPlatform('ios') ? '40px' : 40}`}} collapse="condense" mode="ios">
				<IonToolbar color="#F8F7F7" style={{paddingRight: 10}} >
					<div className={styles.data}>
						<IonText>Saturday, maRch 28</IonText>
					</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7">
					<IonTitle size="large">Top nieuws</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-content-news'>
				<IonList>
					{renderNews()}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default News;
