import React from "react";


const Pin = ({styles, pin = "", count}: { styles: any, pin: string , count: number}) => {

	const renderCircles = () => {
		const arr = [];
		for (let i = 0; i < count; i++) {
			const color = pin[i] ? '#007AFF' : '#007aff6e'
			arr.push(<div key={i} style={{backgroundColor: color}} className={styles.pin}/>)
		}
		return arr;
	}

	return (
		<div className={styles.wrapperPin}>
			{renderCircles()}
		</div>
	)
};
export default Pin
