import React from 'react';

const Diagramm = (props) => {

    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke={props.color? props.color : "white"} d="M20.2104 15.3048C19.5742 16.8093 18.5792 18.135 17.3123 19.1661C16.0454 20.1972 14.5452 20.9022 12.9428 21.2196C11.3405 21.5369 9.68483 21.4569 8.12055 20.9866C6.55627 20.5162 5.13103 19.6699 3.96942 18.5215C2.80782 17.373 1.94522 15.9575 1.45704 14.3987C0.968859 12.8399 0.869965 11.1853 1.169 9.57943C1.46804 7.97358 2.1559 6.46542 3.17245 5.18682C4.189 3.90823 5.50329 2.89811 7.0004 2.24479M21.0004 11.4148C21.0004 10.1016 20.7417 8.80122 20.2392 7.58796C19.7367 6.3747 19.0001 5.27231 18.0715 4.34373C17.1429 3.41514 16.0405 2.67855 14.8272 2.176C13.614 1.67345 12.3136 1.41479 11.0004 1.41479V11.4148H21.0004Z"  strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default Diagramm;
