import React from 'react';

const ClientsIcon = (props) => {

    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5249 7.05834C12.8624 5.82084 13.7249 4.28334 15.8999 4.28334C15.8999 4.28334 21.8999 2.37084 21.8999 8.48334C21.8999 13.6208 23.0624 14.1833 24.2999 15.4208C22.7265 15.7905 21.1161 15.9792 19.4999 15.9833V16.5833C19.4999 17.1833 19.3874 18.3833 22.3874 18.9833C25.3874 19.5833 25.4999 21.9833 25.4999 21.9833H17.6249" stroke={props.color?props.color:"#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.5375 21.6833C13.5375 21.2708 13.5375 20.1833 13.5375 19.5833V19.2458C14.3014 18.5384 14.8478 17.6278 15.1125 16.6208C16.0125 16.6208 16.2375 14.7083 16.2375 14.4458C16.2375 14.1833 16.1625 13.0958 15.75 12.9833C17.25 7.73333 12.75 5.48333 8.85 8.18333C7.2 8.18333 6.675 10.5833 7.275 12.9833C6.8625 12.9833 6.7875 14.1083 6.7875 14.4083C6.7875 14.7083 7.0125 16.5833 7.9125 16.5833C8.2125 17.6708 9.0375 18.5708 9.75 19.2083V19.5833C9.75 20.1833 9.525 21.3083 6.4875 21.6833C3.45 22.0583 3 24.9833 3 24.9833H19.5C19.5 24.9833 19.5375 22.0958 16.5375 21.6833Z" stroke={props.color?props.color:"#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default ClientsIcon;
