import React, { useEffect, useState } from 'react';

import styles from '../css/CloseClientCase.module.css'
import LogOutIcon from "../client/SVGIcons/LogOutIcon";
import ConnectIcon from "../client/SVGIcons/ConnectIcon";
import { IonToggle } from "@ionic/react";
import ModalAlert from "../modal/ModalAlert";
import { useStore } from "effector-react";
import { activeCaseloadStore } from "../../api/caseload";
import { $clients, requestChangeClientStatus } from "../../api/clients";
import { useHistory } from "react-router";
import { fetchClientQuestionsEffect } from "../../api/questions";

interface Props {
	setPath: any,
	path: string,
	idChatItem: string,
	devices: number,
	tubsSettings: string
}

const CloseClientCase = (props: Props) => {

	const history = useHistory()

	const Case = useStore(activeCaseloadStore)
	const clients = useStore($clients);

	const closeText = 'Your client will loose acces to chats.';
	const openText = "Reopen client"

	const [closeCaseModal, setCloseCaseModal] = useState(false)
	const [isActive, setActive] = useState(true);

	useEffect(() => {
		const client = clients.find(cl => cl.uid === Case?.uid);
		if (client?.is_active === true) {
			setActive(true)
		}
		if (client?.is_active === false) {
			setActive(false)
		}
	}, [Case, clients])

	useEffect(() => {
		let thisPath = window.location.pathname;
		return props.setPath(thisPath);
	})

	const onDoneHandler = () => {
		Case && requestChangeClientStatus({uid: Case?.uid, status: isActive ? "blocked" : "active"});
		setCloseCaseModal(false);
		fetchClientQuestionsEffect().then()
		history.push("/caseload")
	}

	return (<>
			{closeCaseModal && <ModalAlert
				question={isActive ? 'Close case?' : 'Open case'}
				secondText={isActive ? closeText : openText}
				cancel={'Cancel'} done={'Yes'}
				doneFunc={onDoneHandler}
				cancelFunc={() => setCloseCaseModal(false)}
			/>}
			<div className={styles.mainWrapper} onClick={() => setCloseCaseModal(true)}>
				<div className={styles.title}>Actions with client case</div>
				<div className={styles.but}>{isActive ? "Close client case" : "Open client case"}</div>
			</div>
		</>
	);
}


export default CloseClientCase;
