import { createEffect, createStore } from "effector";
import axios from "axios";
import { API_ENDPOINT } from "./index";

export interface Category {
	name: string;
	identifier: string;
	icon: string;
}


export const fetchCategories = createEffect<void, { categories: Category[] }>()
	.use(async () => {
		const req = await axios.get(`${API_ENDPOINT}/employee/questions/categories`);
		return req.data
	})

export const $categories = createStore<Category[]>([])
	.on(fetchCategories.doneData, (_, data) => data.categories)
