import React, {useState} from 'react';

import HeaderLeftPath from "./HeaderLeftPath";
import FooterLeftPath from "./FooterLeftPath";
import CaseLoadItems from "./CaseLoadItems";
import IncommingItems from "./IncommingItems";
import CaseLoadItemsDetail from "./CaseLoadItemsDetail";
import styles from '../css/LeftPath.module.css'
import VragenList from "../client/VragenList";
import News from "../client/News";
import Settings from "../client/Settings";
import { useStore } from "effector-react";
import { userStore } from "../../api/auth";

interface Props {
    setPath: any,
    path: string,
    id: string,
    setId: any,
    idItem: string,
    setIdItem: any,
    setDevices: any,
    setTubsSettings: any,
    tubsSettings: string,
    setNewsId?: any,
    idChatItem: string,
    setSearch: any,
    search: string,
    setGroup: any,
    group: any,
    setScrollChat: any,
    clearId: any
}

const LeftPath = (props:Props) => {

    const user = useStore(userStore)

    const leftItem = () => {
        if (user?.type === "employee") {
            return (
                <>
                    {props.path === '/caseload' && <CaseLoadItems setGroup={props.setGroup} search={props.search} setSearch={props.setSearch} id={props.id} setId={props.setId} path={props.path} setPath={props.setPath} />}
                    {props.path.indexOf(`/caseload/detail/${props.id}`) === 0 && <CaseLoadItemsDetail setScrollChat={props.setScrollChat} setGroup={props.setGroup} search={props.search} setSearch={props.setSearch} idItem={props.idItem} setIdItem={props.setIdItem} id={props.id} setId={props.setId} path={props.path} setPath={props.setPath} />}
                    {props.path.indexOf(`/incomming`) === 0 && <IncommingItems setScrollChat={props.setScrollChat} search={props.search} setSearch={props.setSearch} idItem={props.idChatItem} setIdItem={props.setIdItem} path={props.path} setPath={props.setPath} />}
                    {props.path.indexOf(`/settings`) === 0 && <Settings
                        tubsSettings={props.tubsSettings}
                        setTubsSettings={props.setTubsSettings}
                        setDevices={props.setDevices}
                        idItem={props.idItem} setIdItem={props.setIdItem} path={props.path} setPath={props.setPath} />}
                </>
            )
        } else if (user?.type === 'client') {
            return (
                <>
                    {props.path.indexOf(`/vragen`) === 0 && <VragenList setScrollChat={props.setScrollChat}  idItem={props.idItem} setIdItem={props.setIdItem} setId={props.setId} setPath={props.setPath} />}
                    {props.path.indexOf(`/news`) === 0 && <News setNewsId={props.setNewsId} path={props.path} setPath={props.setPath} />}
                    {props.path.indexOf(`/settings`) === 0 && <Settings
                        tubsSettings={props.tubsSettings}
                        setTubsSettings={props.setTubsSettings}
                        setDevices={props.setDevices}
                        idItem={props.idItem} setIdItem={props.setIdItem} path={props.path} setPath={props.setPath} />}
                </>);
        }
    }

    return (
        <>
            <div className={styles.headerLeftPath}>
                <HeaderLeftPath group={props.group} id={props.id} setId={props.setId} search={props.search} setSearch={props.setSearch} setPath={props.setPath} path={props.path} />
            </div>
            <div className={`${user?.type === 'client'? styles.contentLeftPathClient : styles.contentLeftPathManager} ${props.path.indexOf(`/caseload`) === 0 && styles.caseload} ${props.path.indexOf(`/news`) === 0 && styles.news} ${props.path.indexOf(`/settings`) === 0 && styles.news}`} >
                {leftItem()}
            </div>
            <div className={styles.footerLeftPath}>
               <FooterLeftPath path={props.path} clearId={props.clearId} setPath={props.setPath} />
            </div>
        </>
    );

}


export default LeftPath;
