import { IonAvatar, IonItem, IonLabel, IonText } from "@ionic/react";
import { archiveIcon, doneIcon, lockIcon, managerIcon, NonSignedUpIcon } from "../assets";
import React, { CSSProperties } from "react";
import moment from "moment";
import { ClientQuestion } from "../api/questions";
import ArchiveIcon from "./client/SVGIcons/ArchiveIcon";
import { CircleAvatar } from "./CircleAvatar";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "../helpers/format";

export interface Props {
	question: ClientQuestion;
	onClick?: () => void
}

export const ClientChatItem = ({question, onClick}: Props) => {

	const Count = (num: number) => {
		const style = {...styles.unreadMsgCircle, backgroundColor: "#33D165"};
		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#000000a4"}}>
				<div style={styles.statusContainer}>
					<div style={style}>
						<IonText>{num}</IonText>
					</div>
				</div>
			</div>
		)
	};

	const doneMark = (question: ClientQuestion) => {
		return null;
		return (
			<span style={{height: "100%", marginRight: 5}}>
			<img src={doneIcon}/>
		</span>
		)
	};

	const renderLockStatus = () => {
		return (
			<div style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#000000a4"}}>
				<span style={{fontSize: 13}}>ARCHIVED</span>
				<span style={{marginLeft: 7}}><ArchiveIcon/></span>
			</div>
		)
	}

	const renderStatus = () => {
		if (question.unread_count) return Count(question.unread_count);
		if (question.result !== null) return renderLockStatus()
	};
	console.log('question.category?.icon',question.category?.icon)
	const iconName = question.category?.icon || "faComment";
	// @ts-ignore
	const icon = faIcons[iconName];
	const avatar = <CircleAvatar
		icon={<FontAwesomeIcon icon={icon} size={"lg"} color="white"/>}
		color={question.result === null ? iconName === 'faComment'? '#75ca90' : "#6CA3DF" : "#999999"}/>;

	const timeColor = question.unread_count > 0 ? "#33D165" : "#999999"
	return (
			<div style={styles.item} onClick={onClick}>
				<div style={{
					minHeight:"60px",
					minWidth: '60px',
					backgroundColor:`${question.result === null ? iconName === 'faComment'? '#75ca90' : "#6CA3DF" : "#999999"}`,
					borderRadius: '50%',
					display:"flex",flexFlow:"column",
					alignItems: "center",justifyContent: "center"}}>
					{avatar}
				</div>
				<div style={{
					minHeight: '82px',
					marginLeft: '10px',
					padding: '10px 10px 10px 0',
					display: "flex",
					width: '100%',
					height: "100%",
					justifyContent: "space-between",
					flexDirection: 'row',
					borderBottom: '0.33px solid rgba(0, 0, 0, 0.2)'
				}}>
					<div style={{display: 'flex',flexFlow: "column"}}>
						<div style={styles.itemTitle}>{question.title.length < 25 ? question.title : `${question.title.slice(0,20)}...`}</div>
						<span style={styles.lastMsg}>{question.last_message.length < 25 ? question.last_message : `${question.last_message.slice(0,20)}...`}</span>
					</div>
					<div style={{display: 'flex',flexFlow: "column",alignItems: "flex-end"}}>
						<IonText
							style={{...styles.time, color: timeColor,marginBottom: '10px'}}>{formatDateTime(question.changed_at)}</IonText>

							{renderStatus()}
					</div>
				</div>
			</div>
	)
};

const rowStyle = {
	display: "flex",
	flex: 1,
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center"
} as React.CSSProperties

const styles: { [key: string]: React.CSSProperties } = {
	item: {
		marginLeft: '10px',
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center"
	},
	dataContainer: {
		display: "flex",
		flexDirection: "column"
	},
	itemTitle: {
		fontFamily: 'Medium',
		fontWeight: 500,
		fontSize: '17px',
		lineHeight: '22px',
		letterSpacing: '0.02em',
		color: "rgba(0, 0, 0, 0.85)"
	},
	lastMsg: {
		fontSize: 15,
		color: "rgba(0, 0, 0, 0.4)",
		whiteSpace: "pre-line",
		textOverflow: "clip"
	},
	leftSideArea: {
		display: "flex",
		flex: 1,
		height: 60,
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "flex-end"
	},
	time: {
		fontSize: 13
	},
	unreadMsgCircle: {
		width: 24,
		height: 24,
		color: "white",
		borderRadius: 24,
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 15
	},
	tagText: {
		fontSize: 15,
		color: "white"
	},
	statusContainer: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 5
	}
};
