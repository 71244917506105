import React, { useEffect, useState } from 'react';
import styles from './css/CaseLoad.module.css'
import {
	IonCheckbox,
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar, isPlatform
} from '@ionic/react';
import { CaseItem } from "../../components/CaseItem";
import { FadeModalSettings } from "../../components/FadeModalSettings";
import { LockIcon } from "../../components/icons/LockIcon";
import { KeyIcon } from "../../components/icons/KeyIcon";
import { useStore } from "effector-react";
import {
	fetchManagerQuestionsEffect
} from "../../api/questions";
import { caseloadStore, GroupByUser, setActiveCaseloadEvent, setActiveCaseloadUidEvent } from "../../api/caseload";
import { useHistory } from "react-router";
import { $clients, fetchClients } from "../../api/clients";
import { searchIcon } from "../../assets";

type CaseloadTab = "active" | "closed" | "non_signed"

const CaseLoad: React.FC = () => {
	const history = useHistory();

	const [filterOn, setFilterOn] = useState(false)
	const [activeTab, setActiveTub] = useState<CaseloadTab>("active")
	const [search, setSearch] = useState("")

	const caseload = useStore(caseloadStore)
	const clients = useStore($clients);

	useEffect(() => {
		fetchManagerQuestionsEffect().then();
		fetchClients().then();
	}, [])

	const titleStyle: React.CSSProperties = {
		fontFamily: 'Medium',
		fontWeight: 500,
		fontSize: '17px',
		lineHeight: '22px',
		textAlign: 'center',
		letterSpacing: '0.02em',
		color: '#222222',
	}

	const filterByTab = (el: GroupByUser) => {
		if (activeTab === "active" && !el.closed) {
			return el;
		}
		if (activeTab === "closed" && el.closed) {
			return el;
		}
	}

	const goToCaseLoadDetail = (group: GroupByUser) => {
		console.log(123);
		setActiveCaseloadEvent(group);
		setActiveCaseloadUidEvent(group.uid);
		history.push("/caseload-detail");
	};

	const renderCases = () => {
		if (activeTab === "non_signed") {
			return clients.map(client => {
				return <CaseItem key={client.uid} name={client.name} nonSigned={true}/>
			})
		}
		if (activeTab === "closed") {
			return caseload.filter(el => el.clientName.includes(search)).map((_case): JSX.Element | null => {
					const client = clients.find(cl => cl.uid === _case.uid);
					return (client?.is_active === false) ?
						<CaseItem key={_case.clientName} count={_case.unreadCount} name={_case.clientName}
						          text={_case.lastTitle} block={true}
						          time={_case.lastDate} onClick={() => goToCaseLoadDetail(_case)}/> : null
				}
			).toList().toArray()
		}
		return caseload.filter(el => el.clientName.includes(search)).filter(filterByTab).map((_case): JSX.Element | null => {
				const client = clients.find(cl => cl.uid === _case.uid);
				return (client?.is_active === true) ?
					<CaseItem key={_case.clientName} count={_case.unreadCount} name={_case.clientName}
					          text={_case.lastTitle}
					          time={_case.lastDate} onClick={() => goToCaseLoadDetail(_case)}/> : null;
			}
		).toList().toArray()
	}

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	}

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{marginTop: `${isPlatform('ios') ? '-10px' : '0px'}`,display: "flex", height:`${isPlatform('ios') ? '90px' : '50px'}` , justifyContent: "flex-start"}}>
					<div style={titleStyle}>Caseload</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7">
					<div style={{position: "relative"}}>
						<input placeholder='Search in questions' style={{
							width: 'calc(100% - 10px)',
							margin: '0 5px',
							height: 36,
							background: 'rgba(142, 142, 147, 0.12)',
							borderRadius: '10px',
							border: 'none',
							paddingLeft: 30
						}} type="text" onChange={onSearchChange}/>
						<img style={{position: "absolute", top: '10px', left: "17px"}} src={searchIcon} alt=""/>
					</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7">
					<div className={styles.wrapperTabs}>
						<div onClick={() => setActiveTub("active")}
						     className={`${styles.itemTab} ${activeTab === "active" && styles.itemTabActive}`}>Active
						</div>
						<div onClick={() => setActiveTub("closed")}
						     className={`${styles.itemArchive} ${activeTab === "closed" && styles.itemArchiveActive}`}>Closed
						</div>
						<div onClick={() => setActiveTub("non_signed")}
						     className={`${styles.itemTabClose} ${activeTab === "non_signed" && styles.itemCloseActive}`}>non
							signet up
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{filterOn &&
				<FadeModalSettings value={filterOn} setValue={setFilterOn}>
					<div style={{
						justifyContent: "space-between",
						display: "flex",
						alignItems: "center",
						padding: '20px 28px 19px',
						borderBottom: '1px solid #D6D6D2',
						backgroundColor: '#F8F7F7'
					}}>
						<span style={{display: "flex", alignItems: "center", color: "black"}}><LockIcon
							style={{marginRight: '10px'}} color={'#000000a6'}/>Show Completed</span><IonCheckbox
						slot="end" checked={false}/>
					</div>
					<div style={{
						justifyContent: "space-between",
						display: "flex",
						alignItems: "center",
						padding: '20px 28px 19px',
						borderBottom: '1px solid #D6D6D2',
						backgroundColor: '#F8F7F7'
					}}>
						<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><KeyIcon
							style={{marginRight: '10px'}} color={'#007AFF'}/>Show Non Signed Up</span><IonCheckbox
						slot="end" checked={true}/>
					</div>
				</FadeModalSettings>
				}
				<div>
					{renderCases()}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default CaseLoad;
