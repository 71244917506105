import { IonAvatar, IonLabel, IonText } from "@ionic/react";
import { lockIcon } from "../assets";
import React from "react";
import styles from './css/CaseDetailItem.module.css';
import ArchiveIcon from "./SVGIcons/ArchiveIcon";
import {formatDateTime} from "../helpers/format";

export interface Props {
	avatar?: any;
	title: string;
	text?: string,
	iconName?: string,
	time: string;
	count?: number;
	lock?: boolean;
	block?: boolean;
	onClick?: () => void
	result: any
}

export const CaseDetailItem = ({avatar,result, text, title, time, count, lock, block, onClick}: Props) => {

	const renderCount = (count?: number) => {
		if (count === 0 || count === undefined) return null;
		return <div className={styles.count}>{count}</div>
	}

	return (
		<div className={`${block && styles.block}`} style={{textDecoration: "none"}} onClick={onClick}>
			<div className={`${block && styles.block} ${styles.item}`}>
				{avatar}
				<IonLabel className={styles.dataContainer}>
					<div className={styles.leftSideArea}>
						<h2 className={styles.itemTitle}>{title && title.length < 20 ? title : `${title && title.slice(0,20)}...`}</h2>
						<IonText className={styles.time} style={{color: `${count && '#33D165'}`}}>{time}</IonText>
					</div>
					<div className={styles.wrapperText}>
						<p className={styles.lastMsg}>{text && text.length < 20 ? text : `${text && text.slice(0,20)}...`}</p>
						<div className={styles.wrapperIcon}>
							{result !== null ? <div className={styles.wrapperState}><span
								style={{color: `${result ? 'rgba(0, 0, 0, 0.6)':  'white'}`}}>archived</span><ArchiveIcon color={`${result ? 'rgba(0, 0, 0, 0.6)': 'white'}`} /></div> : null}
							{lock && <img className={styles.lock} src={lockIcon} alt="lockIcon"/>}
							{result !== null && !lock ? null : renderCount(count)}
						</div>
					</div>
				</IonLabel>
			</div>
		</div>
	)
};

