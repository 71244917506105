import React from 'react';

const MessagesIcon = (props) => {
    return (
        <>
            <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8502 12.4375C24.5502 13.0375 26.5002 15.025 26.5002 17.35C26.4815 18.0623 26.2992 18.7608 25.9676 19.3915C25.636 20.0222 25.1639 20.5683 24.5877 20.9875L25.7502 23.875L21.8877 22.2625C21.3092 22.3911 20.7179 22.454 20.1252 22.45C17.0502 22.45 14.4627 20.6875 13.8627 18.3625" stroke={props.color?props.color:"#007AFF"} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.74994 20.5L10.5249 18.1375C11.3375 18.3175 12.1677 18.4056 12.9999 18.4C17.9874 18.4 21.9999 15.175 21.9999 11.2C21.9999 7.225 17.9874 4 12.9999 4C8.01244 4 3.99994 7.225 3.99994 11.2C3.99994 13.225 5.04994 15.0625 6.73744 16.375L4.74994 20.5Z" stroke={props.color?props.color:"#007AFF"} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default MessagesIcon;
