import React from 'react';
import styles from '../css/footer.module.css'
import {clientIcon, massagesIcon, searchIcon, settingsIcon} from "../../assets";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import MessagesIcon from "../SVGIcons/MessagesIcon";
import ClientsIcon from "../SVGIcons/ClientsIcon";
import SettingsIcon from "../SVGIcons/SettingsIcon";
import NewsIcon from "../client/SVGIcons/NewsIcon";
import { useStore } from "effector-react";
import { userStore } from "../../api/auth";
import { unreadCountStore } from "../../api/questions";
import {setActiveCaseloadUidEvent} from "../../api/caseload";

interface Props {
    setPath: any,
    path: string,
    clearId: any
}

const FooterLeftPath = (props:Props) => {

    const history = useHistory();
    const user = useStore(userStore);
    const unreadCount = useStore(unreadCountStore)

    const goTo = (path:string) => {
        props.clearId();
        history.push(path, {animate: false});
        let thisPath = window.location.pathname;
        props.setPath(thisPath)
    };
    if (user?.type === "employee") {
        return (
            <>
                <Link style={{textDecoration: 'none'}} to={'/incomming'} onClick={() => goTo('/incomming')}>
                    <div className={styles.wrapperLink}>
                        {unreadCount > 0 && <div className={styles.countMessagesLink}>{unreadCount}</div>}
                        <MessagesIcon color={props.path.indexOf('/incomming') === 0 ?'#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/incomming') === 0 && styles.active}`}>Inbox</div>
                    </div>
                </Link>
                <Link style={{textDecoration: 'none'}} to={'/caseload'} onClick={() => goTo('/caseload')}>
                    <div className={styles.wrapperLink}>
                        <ClientsIcon color={props.path.indexOf('/caseload') === 0 ?'#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/caseload') === 0 && styles.active}`}>Caseload</div>
                    </div>
                </Link>
                <Link style={{textDecoration: 'none'}} to={"/settings"} onClick={() => goTo('/settings')}>
                    <div className={styles.wrapperLink}>
                        <SettingsIcon color={props.path.indexOf('/settings') === 0 ?'#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/settings') === 0 && styles.active}`}>Instellingen</div>
                    </div>
                </Link>
            </>
        );
    } else {
        return (
            <>
                <Link style={{textDecoration: 'none'}} to={'/vragen'} onClick={() => goTo('/vragen')}>
                    <div className={styles.wrapperLink}>
                        {unreadCount > 0 && <div className={styles.countMessagesLink}>{unreadCount}</div>}
                        <MessagesIcon color={props.path.indexOf('/vragen') === 0?'#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/vragen') === 0  && styles.active}`}>Vragen</div>
                    </div>
                </Link>
                <Link style={{textDecoration: 'none'}} to={'/news'} onClick={() => goTo('/news')}>
                    <div className={styles.wrapperLink}>
                        <NewsIcon color={props.path.indexOf('/news') === 0 ? '#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/news') === 0 && styles.active}`}>News</div>
                    </div>
                </Link>
                <Link style={{textDecoration: 'none'}} to={"/settings"} onClick={() => goTo('/settings')}>
                    <div className={styles.wrapperLink}>
                        <SettingsIcon color={props.path.indexOf('/settings') === 0 ?'#007AFF':'#9B9B9B'} />
                        <div className={`${styles.link} ${props.path.indexOf('/settings') === 0 && styles.active}`}>Instellingen</div>
                    </div>
                </Link>
            </>
        );
    }


}


export default FooterLeftPath;
