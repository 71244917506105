import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const GeneralIcon = ({color,style}: Props) => {
	return (
		<div style={style}>
			<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 5.69666H5V12.6967H12V5.69666Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M23 5.69666H16V12.6967H23V5.69666Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M23 16.6967H16V23.6967H23V16.6967Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M12 16.6967H5V23.6967H12V16.6967Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>

)
};


