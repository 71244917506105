import React, { useEffect, useState } from 'react';
import { blackAvatarIcon, moreIcon, wommenIcon } from "../../assets";
import styles from "./css/PinLogIn.module.css"
import KeyBoard from "../../components/KeyBoard"
import Pin from "../../components/Pin"
import {
	IonAlert,
	IonAvatar,
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar
} from "@ionic/react";
import { useStore } from "effector-react";
import { phoneStore, setAuthStateEvent, setSidEvent, sidStore, userStore } from "../../api/auth";
import { saveDataToStorage } from "../../api/storage";
import { getAbbreviation } from "../../helpers/format";
import { DbKeys } from "../../helpers/variants";

function NewPin() {

	const [pin, setPin] = useState("");
	const [prevPin, setPrevPin] = useState("")
	const [alert, setAlert] = useState(false);

	const user = useStore(userStore);
	const sid = useStore(sidStore);

	const [label, setLabel] = useState("Maak uw passcode");
	const [step, setStep] = useState("first")

	const onCodeChanged = (code: string) => {
		setPin(code);
	}

	useEffect(() => {
		setLabel(step === "first" ? "Maak uw passcode" : "Repeat uw passcode")
	}, [step])

	const storeToDb = async (sid: string) => {
		await saveDataToStorage(`${DbKeys._prefix}_${pin}`, sid).then();
		await saveDataToStorage(DbKeys.registered, "yes").then();
		await saveDataToStorage(DbKeys.defaultUser, JSON.stringify(user)).then();
		setSidEvent(sid);
		setAuthStateEvent(true);
	}

	useEffect(() => {
		if (step === "first" && pin.length === 4) {
			setStep("second");
			setPrevPin(pin);
			setPin("");
		}
		if (step === "second" && pin.length === 4 && sid) {
			if (prevPin === pin) {
				storeToDb(sid).then();
			} else {
				setAlert(true);
			}
			setPrevPin("");
			setPin("");
			setStep("first")
		}
	}, [pin])

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar style={{display: "flex", flex: 1, alignItems: "center", height: 54}}>
					<IonButtons slot="start">
						<IonBackButton text="Back" color="primary"/>
					</IonButtons>
					<div className={styles.wrapperHeader}>
						<div className={styles.userName}>{user?.name}</div>
						<div className={styles.avatarCircle}>
							<div className={styles.circleFont}>{getAbbreviation(user?.name ?? "")}</div>
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonAlert
					isOpen={alert}
					onDidDismiss={() => setAlert(false)}
					header={'Error'}
					message={'Code PIN codes do not match'}
					buttons={['OK']}
				/>
				<div className={styles.container}>
					<div className={styles.wrapperTitle}>
						<div className={styles.title}>{label}</div>
					</div>
					<Pin styles={styles} pin={pin} count={4}/>
					<KeyBoard styles={styles} pin={pin} onCodeChanged={onCodeChanged}/>
				</div>
			</IonContent>
		</IonPage>
	);
}


export default NewPin;
