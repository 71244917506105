import { deleteIcon } from "../assets";
import React, { useEffect, useState } from "react";

interface Props {
	pin: string;
	styles: any
	onCodeChanged: (code: string) => void
}

const KeyBoard = (props: Props) => {

	const {styles, onCodeChanged, pin} = props

	const keyPress = (num: string) => {
		if (pin.length === 4) return;
		onCodeChanged(pin + num);
	}

	const deleteLast = () => {
		onCodeChanged(pin.substr(0, pin.length - 1));
	}

	useEffect(() => {
		onCodeChanged(pin);
	}, [pin])

	return (
		<div className={styles.wrapperKeyboard}>
			<div className={styles.row}>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("1")}>1</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("3")}>2</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("3")}>3</div>
			</div>
			<div className={styles.row}>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("4")}>4</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("5")}>5</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("6")}>6</div>
			</div>
			<div className={styles.row}>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("7")}>7</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("8")}>8</div>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("9")}>9</div>
			</div>
			<div className={styles.row}>
				<div className={styles.buttonKeyboardTrans}/>
				<div className={styles.buttonKeyboard} onClick={() => keyPress("0")}>0</div>
				<div className={styles.buttonDelete} onClick={deleteLast}><img src={deleteIcon} alt="deleteIcon"/></div>
			</div>
		</div>
	)
};
export default KeyBoard
