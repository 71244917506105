import React, { useEffect, useRef, useState } from 'react';
import styles from './css/settingsLeftPath.module.css'
import { avatarIcon, newsIcon } from "../../assets";
import { useHistory } from "react-router";
import SettingsConnectIcon from "./SVGIcons/SettingsConnectIcon";
import SettingsNotificationsIcon from "./SVGIcons/SettingsNotificationsIcon";
import SettingsPasswordIcon from "./SVGIcons/SettingsPasswordIcon";
import ArrowRightIcon from "./SVGIcons/ArrowRightIcon";
import { useStore } from "effector-react";
import { $email, fetchStatusEffect, userStore } from "../../api/auth";
import { uploadAvatar } from "../../api/user";


interface Props {
	setPath: any,
	path: string,
	idItem: string,
	setIdItem: any,
	setDevices: any,
	setTubsSettings: any,
	tubsSettings: string
}

type FileReaderTarget = { result: string }

const Settings = (props: Props) => {
	const history = useHistory();

	const devices = document?.getElementById('Devices')?.clientHeight
	const user = useStore(userStore);
	const email = useStore($email);

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	const upload = useRef<HTMLInputElement | null>();

	const activeSettings = (offset: number, tubs: string) => {
		props.setDevices(offset)
		props.setTubsSettings(tubs)
	}

	useEffect(() => {
		return uploadAvatar.fail.watch(() => {
			alert("Upload avatar error")
		})
	}, [])

	const fileSelected = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const fileReader = new FileReader();
		fileReader.onload = async (event: ProgressEvent) => {
			const result = (event.target as FileReaderTarget | null)?.result
			result && await uploadAvatar(result);
			await fetchStatusEffect();
		};
		if (e.target.files && e.target.files[0]) {
			fileReader.readAsDataURL(e.target.files[0]);
		}
	};

	return (
		<>

			<div className={styles.wrapperInfo}>
				<div onClick={() => upload.current?.click()}>
					<img src={user?.avatar ?? avatarIcon} alt="avatarIcon"
					     style={{width: 60, height: 60, borderRadius: 30}}/>
				</div>

				<div className={styles.info}>
					<div className={styles.name}>{user?.name}</div>
					<div className={styles.phone}>+31 {user?.phone}</div>
					<div className={styles.mail}>{email}</div>
				</div>
			</div>

			<div>
				<div onClick={() => activeSettings(54, 'devices')} className={styles.wrapperItem}
				     style={{backgroundColor: `${props.tubsSettings === 'devices' ? '#007AFF' : 'white'}`}}>
					<div className={styles.wrapperItemName}>
						<SettingsConnectIcon fill={`${props.tubsSettings === 'devices' ? 'white' : '#6CA3DF'}`}
						                     stroke={`${props.tubsSettings === 'devices' ? '#6CA3DF' : 'white'}`}/>
						<span style={{
							transition: '400ms',
							color: `${props.tubsSettings === 'devices' ? 'white' : 'black'}`
						}}>Connected Devices</span>
					</div>
					<ArrowRightIcon stroke={`${props.tubsSettings === 'devices' ? 'white' : '#9B9B9B'}`}/>
				</div>
				<input className="fileInput" style={{opacity: 0}}
				       accept={"image/*"}
					// @ts-ignore
					   ref={upload}
					   type="file"
					   onChange={fileSelected}/>
				{/*<div  onClick={() => activeSettings(-103,'notifications')} className={styles.wrapperItem} style={{backgroundColor: `${props.tubsSettings === 'notifications'?'#007AFF':'white'}`}}>
                    <div className={styles.wrapperItemName}>
                        <SettingsNotificationsIcon fill={`${props.tubsSettings === 'notifications'?'white':'#6CA3DF'}`} stroke={`${props.tubsSettings === 'notifications'?'#6CA3DF':'white'}`}/>
                        <span style={{transition: '400ms',color: `${props.tubsSettings === 'notifications'?'white':'black'}`}}>Notifications</span>
                    </div>
                    <ArrowRightIcon stroke={`${props.tubsSettings === 'notifications'?'white':'#9B9B9B'}`}/>
                </div>
                <div  onClick={() => activeSettings(-212,'password')} className={styles.wrapperItem} style={{backgroundColor: `${props.tubsSettings === 'password'?'#007AFF':'white'}`}}>
                    <div className={styles.wrapperItemName}>
                        <SettingsPasswordIcon fill={`${props.tubsSettings === 'password'?'white':'#6CA3DF'}`} stroke={`${props.tubsSettings === 'password'?'#6CA3DF':'white'}`}/>
                        <span style={{transition: '400ms',color: `${props.tubsSettings === 'password'?'white':'black'}`}}>Password</span>
                    </div>
                    <ArrowRightIcon stroke={`${props.tubsSettings === 'password'?'white':'#9B9B9B'}`}/>
                </div>*/}

			</div>
		</>
	);
}


export default Settings;
