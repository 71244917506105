export const backArrowIcon  = require("./assets/backArrow.svg");
export const settingsIcon = require("./assets/settings.svg");
export const askNewIcon = require("./assets/image/askNewMain.svg");
export const managerIcon = require("./assets/manager.svg");
export const bitmapIcon = require("./assets/Bitmap.png");
export const LoginIcon = require("./assets/login.png");
export const wommenIcon = require("./assets/Corners.svg");
export const deleteIcon = require("./assets/Icon.svg");
export const doneIcon = require("./assets/done.svg");
export const countryIcon = require("./assets/country.png");
export const сlientsIcon = require("./assets/Clients.svg");
export const filterIcon = require("./assets/filter_list.svg");
export const lockIcon = require("./assets/image/lock.svg");
export const keyIcon = require("./assets/key.svg");
export const moreIcon = require("./assets/more.svg");
export const blackAvatarIcon = require("./assets/blackAvatar.svg");
export const arrowIcon = require("./assets/arrow.svg");
export const arrowTopIcon = require("./assets/arrowTop.svg");
export const userImageIcon = require("./assets/userImage.png");
export const SystemIcon = require("./assets/SystemIcon.svg");
export const passwordIcon = require("./assets/passwordIcon.svg");
export const natificationsIcon = require("./assets/natificationsIcon.svg");
export const greenLink = require("./assets/greenLink.svg");
export const disconnect = require("./assets/disconnect.svg");
export const TaskIcon = require("./assets/image/Task.svg");
export const avatarIcon = require("./assets/image/avatar.svg");
export const caseloaddetail = require("./assets/image/caseloaddetail.svg");
export const goBackIcon = require("./assets/image/Arrow.svg");
export const newsIMG = require("./assets/image/Images.png");
export const mailIcon  = require("./assets/image/Mail.svg");
export const organizationIcon  = require("./assets/image/Organization.svg");
export const unlockIcon = require("./assets/image/Unlock.svg");
export const logoIcon = require("./assets/image/logo.png");
export const searchIcon = require("./assets/image/Search.svg");
export const circleIcon = require("./assets/image/сircle.svg");
export const massagesIcon = require("./assets/image/Messages.svg");
export const clientIcon = require("./assets/image/Clients.svg");
export const goBackWhiteIcon = require("./assets/image/goBackWhiteIcon.svg");
export const chatIcon = require("./assets/image/Vector.svg");
export const editIcon = require("./assets/image/edit.svg");
export const gridIcon = require("./assets/image/grid.svg");
export const chevronsIcon = require("./assets/image/chevrons.svg");
export const chevronsrightIcon = require("./assets/image/chevronsright.svg");
export const archiveIcon = require("./assets/image/archive.svg");
export const newsIcon = require("./assets/image/Corners.png");
export const NonSignedUpIcon = require("./assets/image/NonSignedUp.svg");
export const xIcon = require("./assets/image/x.svg");
export const checkIcon = require("./assets/image/check.svg");
export const profileIcon = require("./assets/image/user.svg");
export const sendButtonIcon = require("./assets/image/SendButton.svg");
export const preloader = require("./assets/6.gif");
