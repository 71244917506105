import React, { useEffect, useState } from 'react';
import styles from '../css/header.module.css'
import {askNewIcon, profileIcon, searchIcon} from "../../assets";
import { filterIcon, goBackIcon } from "../../assets";
import { useHistory } from "react-router";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import {
	$caseLoadDetailTab,
	$caseLoadTab,
	$selectedTab,
	selectTab,
	setCaseloadDetailTab,
	setCaseLoadTab
} from "../../api/tabs";
import { setActiveQuestionUidEvent } from "../../api/questions";
import {activeCaseloadStore, caseloadStore} from "../../api/caseload";

interface Props {
	path: string,
	setPath: any,
	setSearch?: any,
	search?: string,
	id: string,
	setId: any,
	group: any
}

const HeaderLeftPath = (props: Props) => {

	const [detail, setDetail] = useState(false)
	const [detailVragen, setDetailVragen] = useState(false)
	const activeGroup = useStore(activeCaseloadStore)
	const [incomming, setIncomming] = useState(false)
	const [news, setNews] = useState(false)

	const selectedTab = useStore($selectedTab);
	const caseloadTab = useStore($caseLoadTab);
	const caseLoadDetailTab = useStore($caseLoadDetailTab)

	const search = (ev: string) => {
		props.setSearch(ev.toLowerCase())
	}

	useEffect(() => {
		props.path.indexOf(`/news`) === 0 ? setNews(true) : setNews(false)
		props.path.indexOf('/vragen/detail') === 0 ? setDetailVragen(true) : setDetailVragen(false)

		props.path.indexOf(`/incomming`) === 0 ? setIncomming(true) : setIncomming(false)
		props.path.indexOf('/caseload/detail') === 0 ? setDetail(true) : setDetail(false)
	})


	const history = useHistory();

	const goBack = async () => {
		history.push('/caseload')
		let thisPath = window.location.pathname;
		await props.setPath(thisPath)
	};
	const goCloseCase = async () => {
		history.push(`/caseload/detail/${props.id}`)
		let thisPath = window.location.pathname;
		await props.setPath(thisPath)
	};
	const goVragen = async () => {
		history.push('/vragen')
		let thisPath = window.location.pathname;
		await props.setPath(thisPath)
	};

	const goNewQuestion = () => {
		history.push(`/vragen/detail/new`);
		let thisPath = window.location.pathname;
		setActiveQuestionUidEvent(null);
		props.setPath(thisPath)
	}

	const Incomming = () => {
		return <>
			<div className={styles.title}>Inbox</div>
			<div className={styles.info}>
				<div className={styles.input}>
					<img className={styles.search} src={searchIcon} alt="searchIcon"/>
					<input placeholder='search' onChange={event => search(event.target.value)} type="text"/>
				</div>
			</div>
		</>
	}

	const Caseload = () => {
		return <>
			<div className={styles.title}>Caseload</div>
			<div className={styles.info}>
				<div className={styles.input}>
					<img className={styles.search} src={searchIcon} alt="searchIcon"/>
					<input placeholder='search' onChange={event => search(event.target.value)} type="text"/>
				</div>
				<div className={styles.wrapperTabs}>
					<div className={`${styles.itemTabs} ${caseloadTab === "active" && styles.itemTabsActive}`}
					     onClick={() => setCaseLoadTab("active")}>active
					</div>
					<div className={`${styles.itemTabsClosed} ${caseloadTab === "closed" && styles.itemTabsClosedActive}`}
					     onClick={() => setCaseLoadTab("closed")}>closed
					</div>
					<div className={`${styles.itemTabsNonSigUp} ${caseloadTab === "non_signed" && styles.itemTabsNonSigUpActive}`}
					     onClick={() => setCaseLoadTab("non_signed")}>non
						signed up
					</div>
				</div>
			</div>
		</>
	}
	const CaseloadDetail = () => {
		return <>
			<div className={styles.goBack} onClick={() => goBack()}><img src={goBackIcon} alt="goBackIcon"/><span
				className={styles.back}>Back</span></div>
			<div className={styles.title}>{activeGroup?.gender != null ? activeGroup?.gender === 'male' ? 'Mr.' : 'Ms.' : null}{props.group?.clientName}</div>
			<div className={styles.profile} onClick={() => goCloseCase()}><span
				className={styles.back}>Profile</span><img src={profileIcon} alt="goBackIcon"/></div>
			<div className={styles.info}>
				<div className={styles.input}>
					<img className={styles.search} src={searchIcon} alt="searchIcon"/>
					<input placeholder='search' onChange={event => search(event.target.value)} type="text"/>
				</div>
				<div className={styles.wrapperTabs}>
					<div className={`${styles.itemTabs} ${caseLoadDetailTab === "active" && styles.itemTabsActive}`}
					     onClick={() => setCaseloadDetailTab("active")}>Active questions
					</div>
					<div className={`${styles.itemTabsArchive} ${caseLoadDetailTab === "archived" && styles.itemTabsArchiveActive}`}
					     onClick={() => setCaseloadDetailTab("archived")}>archived
					</div>
				</div>
			</div>
		</>
	}

	const News = () => {
		return <>
			<div style={{borderBottom: '0.5px solid #C8C7CC'}} className={styles.title}>Top nieuws</div>
		</>
	}
	const Settings = () => {
		return <>
			<div style={{borderBottom: '0.5px solid #C8C7CC'}} className={styles.title}>Settings</div>
		</>
	}
	const Vragen = () => {
		return <>
			<div className={styles.title}>Vragen <div className={styles.nieuw} onClick={goNewQuestion}>
				<span>nieuw</span><img src={askNewIcon}
				                       alt="askNewIcon"/>
			</div></div>
			<div className={styles.info}>
				<div className={styles.wrapperTabs}>
					<div className={`${styles.itemTabs} ${selectedTab === "active" && styles.itemTabsActive}`}
					     onClick={() => selectTab("active")}>Active questions
					</div>
					<div className={`${styles.itemTabsArchive} ${selectedTab === "archived" && styles.itemTabsArchiveActive}`}
					     onClick={() => selectTab("archived")}>archived
					</div>
				</div>
			</div>
		</>
	}

	const VragenDetail = () => {
		return <>
			<div className={styles.goBack} onClick={() => goVragen()}><img src={goBackIcon} alt="goBackIcon"/><span
				className={styles.back}>Vragen</span></div>
			<div className={styles.title}>Mr. John Smit</div>
			<div className={styles.info}>
				<div className={styles.wrapperTabs}>
					<div className={`${styles.itemTabs} ${styles.itemTabsActive}`}>Active questions</div>
					<div className={`${styles.itemTabs}`}>arhived</div>
				</div>
			</div>
		</>
	}

	if (props.path.indexOf('/vragen') === 0) {
		selectedTab === "" && selectTab("active");
	}

	return (<>
			{incomming && Incomming()}
			{props.path === '/caseload' && Caseload()}
			{detail && CaseloadDetail()}
			{news && News()}
			{props.path.indexOf('/vragen') === 0 && Vragen()}
			{props.path === '/settings' && Settings()}
		</>
	);
}


export default HeaderLeftPath;
