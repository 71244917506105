import { combine, createEffect, createEvent, createStore, forward, fromObservable, guard } from "effector";
import axios from "axios";
import { List, Map, Set } from "immutable";
import { API_ENDPOINT, DEBUG, GET_CLIENT_MESSAGES, GET_MANAGER_MESSAGES } from "./index";
import { saveDataToStorage } from "./storage";
import { interval } from "rxjs";
import { fetchClientQuestionsEffect, fetchManagerQuestionsEffect, questionsFetchInterval } from "./questions";
import { DbKeys } from "../helpers/variants";
import { APP_VARIANT, AppVariant } from "../config";
import { isAuthStore, userStore } from "./auth";

export const messagesFetchInterval = fromObservable(interval(5000))

export interface Message {
	uid: string;
	is_my: boolean;
	sender: { name: string } | null;
	created_at: string;
	is_read: boolean;
	text: string;
}

export interface fetchMessagesResponse {
	uid: string;
	title: string;
	category: {
		identifier: string;
		name: string;
		icon: string
	} | null,
	messages: Message[]
}

export interface SendMessageData {
	questionId: string;
	message: string;
	uuid: string;
}

export interface SendMessageDataResponse {
	message: Message
}

export const fetchClientMessagesEffect = createEffect<string, fetchMessagesResponse>({
	handler: async (id: string) => {
		const req = await axios.get(`${GET_CLIENT_MESSAGES}/${id}`);
		return req.data
	}
});

export const fetchManagerMessagesEffect = createEffect<string, fetchMessagesResponse>()
	.use(async (id: string) => {
		const req = await axios.get(`${GET_MANAGER_MESSAGES}/${id}`);
		return req.data
	})

export const sendClientMessageAction = createEffect<SendMessageData, SendMessageDataResponse>({
	handler: async ({questionId, message, uuid}) => {
		const req = await axios.post(`${API_ENDPOINT}/client/questions/${questionId}/message`, {message, uuid});
		return req.data
	}
});

export const sendManagerMessageAction = createEffect<SendMessageData, SendMessageDataResponse>({
	handler: async ({questionId, message, uuid}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${questionId}/message`, {message, uuid});
		return req.data
	}
});

export const addMessage = createEvent<{ questionId: string | null, message: Message }>();

export const messagesStore = createStore<Map<string | null, List<Message>>>(Map(), {name: "messagesStore"})
	.on(fetchClientMessagesEffect.doneData, (store, data) => {
		return store.set(data.uid, List(data.messages));
	})
	.on(fetchManagerMessagesEffect.doneData, (store, data) => {
		return store.set(data.uid, List(data.messages));
	})
	.on(addMessage, (store, data) => {
		const list = store.get(data.questionId) || List();
		return store.set(data.questionId, list.push(data.message))
	})


export const setDeliveredMessagesEvent = createEvent<string[]>();

export const deliveredMessagesStore = createStore<Set<string>>(Set())
	.on(sendClientMessageAction.doneData, (store, data) => {
		const newStore = store.add(data.message.uid);
		saveDataToStorage(DbKeys.deliveredMessages, newStore.toJS()).then()
		return newStore;
	})
	.on(setDeliveredMessagesEvent, (store, data) => Set(data))
	.on(fetchClientMessagesEffect.doneData, (store, data) => {
		const newStore = store.union(data.messages.filter(msg => msg.is_my).map(msg => msg.uid));
		saveDataToStorage(DbKeys.deliveredMessages, newStore.toJS()).then();
		return newStore;
	})
	.on(fetchManagerMessagesEffect.doneData, (store, data) => {
		const newStore = store.union(data.messages.filter(msg => msg.is_my).map(msg => msg.uid));
		saveDataToStorage(DbKeys.deliveredMessages, newStore.toJS()).then();
		return newStore;
	})


//----------------------------------------------------------------------------------------------------------------------

forward({from: fetchManagerMessagesEffect.doneData, to: fetchManagerQuestionsEffect});


