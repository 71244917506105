import React from 'react';

const NewsIcon = (props) => {

    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.99994 25.5H22.4999C23.2956 25.5 24.0586 25.1839 24.6213 24.6213C25.1839 24.0587 25.4999 23.2956 25.4999 22.5V3H8.99994V6" stroke={props.color? props.color : "#9B9B9B"} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.99994 22.5C8.99994 23.2956 8.68387 24.0587 8.12126 24.6213C7.55865 25.1839 6.79559 25.5 5.99994 25.5C5.20429 25.5 4.44123 25.1839 3.87862 24.6213C3.31601 24.0587 2.99994 23.2956 2.99994 22.5V6H8.99994V22.5Z" stroke={props.color? props.color : "#9B9B9B"}  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.9999 15H11.9999M22.4999 6H11.9999H22.4999ZM22.4999 18H11.9999H22.4999ZM22.4999 21H11.9999H22.4999ZM17.9999 9H22.4999V15H17.9999V9ZM14.9999 9H12.7499H14.9999ZM14.9999 12H11.9999H14.9999Z" stroke={props.color? props.color : "#9B9B9B"}  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </>
    );
}


export default NewsIcon;
