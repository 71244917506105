import React, { useEffect, useState } from 'react';
import {
	IonBackButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonHeader, IonList,
	IonPage,
	IonToolbar
} from '@ionic/react';
import './css/style.css';
import { CaseDetailItem } from "../../components/CaseDetailItem";
import { FadeModalSettings } from "../../components/FadeModalSettings";
import { LockIcon } from "../../components/icons/LockIcon";
import { useStore } from "effector-react";
import { activeCaseloadStore, activeCaseloadUidStore, caseloadStore, GroupByUser } from "../../api/caseload";
import moment from "moment";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { CircleAvatar } from "../../components/CircleAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	fetchClientQuestionsEffect,
	fetchManagerQuestionsEffect,
	ManagerQuestion,
	setActiveQuestionUidEvent
} from "../../api/questions";
import { useHistory } from "react-router";
import { MiniAvatar } from "../../components/MiniAvatar";
import styles from "./css/CaseLoad.module.css";
import { moreIcon, searchIcon } from "../../assets";
import ModalAlert from "../../components/modal/ModalAlert";
import { $clients, fetchClients, requestChangeClientStatus } from "../../api/clients";
import { formatDateTime } from "../../helpers/format";
import {ArchiveIcon} from "../../components/icons/ArchiveIcon";
import {CloseCaseIcon} from "../../components/icons/CloseCase";

const CaseLoadDetail: React.FC = () => {

	const closeText = 'Your client will loose acces to chats.';
	const openText = "Reopen client"

	const history = useHistory();
	const [search, setSearch] = useState("");

	const [filterOn, setFilterOn] = useState(false)
	const [settingOn, setSettingOn] = useState(false);
	const [selectedTab, setSelectedTab] = useState("active")
	const [closeCaseAlert, setCloseCaseAlert] = useState(false);
	const [isActive, setActive] = useState(true);

	const [activeCaseload, setActiveCaseload] = useState<GroupByUser | null>(null);

	const caseload = useStore(caseloadStore);
	const activeCaseloadUid = useStore(activeCaseloadUidStore);
	const Case = useStore(activeCaseloadStore)
	const clients = useStore($clients);

	const client = clients.find(cl => cl.uid === Case?.uid);

	useEffect(() => {
		const client = clients.find(cl => cl.uid === Case?.uid);
		if (client?.is_active === true) {
			setActive(true)
		}
		if (client?.is_active === false) {
			setActive(false)
		}
	}, [Case, clients])

	useEffect(() => {
		if (activeCaseloadUid !== null) {
			const group = caseload.get(activeCaseloadUid);
			group && setActiveCaseload(group);
		}
	}, [activeCaseloadUid, caseload])

	const goToChat = (question: ManagerQuestion) => {
		setActiveQuestionUidEvent(question.uid);
		history.push("/caseload-chat");
	}

	useEffect(() => {
		fetchManagerQuestionsEffect().then()
	}, [])

	const questionFilter = (question: ManagerQuestion) => {
		if (selectedTab === "active") {
			return question.result === null
		}
		if (selectedTab === "archived") {
			return question.result !== null
		}
	}

	const renderQuestions = () => {
		return activeCaseload?.questions.filter(questionFilter).filter(el => el.title.toLowerCase().includes(search.toLowerCase())).map(question => {
			const iconName = question.category?.icon || "faComment";
			// @ts-ignore
			const icon = faIcons[iconName];
			const timeColor = question.result === null ? iconName === 'faComment'? '#75ca90' : "#6CA3DF" : "#999999"
			const avatar =<div style={{
				minHeight:"60px",
				minWidth: '60px',
				backgroundColor:`${question.result === null ?iconName === 'faComment'? '#75ca90' : "#6CA3DF" : "#999999"}`,
				borderRadius: '50%',
				display:"flex",flexFlow:"column",
				alignItems: "center",justifyContent: "center"}}>
				<CircleAvatar
					icon={<FontAwesomeIcon icon={icon} size={"lg"} color="white"/>} color={timeColor}/>
			</div>
			return <CaseDetailItem result={question.result} key={question.uid} iconName={iconName} count={question.unread_count} title={question.title}
			                       text={question.last_message} avatar={avatar} onClick={() => goToChat(question)}
			                       time={formatDateTime(question.changed_at)}/>
		})
	}

	const onDoneHandler = async () => {
		Case && await requestChangeClientStatus({uid: Case?.uid, status: isActive ? "blocked" : "active"});
		setCloseCaseAlert(false);

		await fetchClients();
		await fetchManagerQuestionsEffect().then()
		history.goBack();
	}
	const searchChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	}
	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{display: "flex", flex: 1, alignItems: "center"}}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary"/>
					</IonButtons>
					<div style={{marginLeft: '30px', display: "flex", alignItems: "center"}}>
						<MiniAvatar name={activeCaseload?.clientName}/>
						<span>{activeCaseload?.clientName}</span>
					</div>
					<IonButtons slot="end">
						<img onClick={() => setSettingOn(!settingOn)} src={moreIcon} alt="moreIcon"/>
					</IonButtons>
				</IonToolbar>
				<div style={{position: "relative"}}>
					<input placeholder='Search in questions' className={styles.input} type="text"
					       onChange={searchChangeText}/>
					<img style={{position: "absolute", top: '18px', left: "24px"}} src={searchIcon} alt=""/>
				</div>
				<IonToolbar color="#F8F7F7">
					<div className={styles.wrapperTabs}>
						<div onClick={() => setSelectedTab("active")}
						     className={`${styles.itemTab} ${selectedTab === "active" && styles.itemTabActive}`}>Active
							questions
						</div>
						<div onClick={() => setSelectedTab("archived")}
						     className={`${styles.itemArchive} ${selectedTab === "archived" && styles.itemArchiveActive}`}>archived
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-content-in'>
				{closeCaseAlert &&
				<ModalAlert
					question={isActive ? 'Close case?' : 'Open case'}
					secondText={isActive ? closeText : openText}
					cancel={'Cancel'} done={'Yes'}
					doneFunc={onDoneHandler}
					cancelFunc={() => setCloseCaseAlert(false)}
				/>}
				{filterOn &&
				<FadeModalSettings value={filterOn} setValue={setFilterOn}>
					<div style={{
						justifyContent: "space-between",
						display: "flex",
						alignItems: "center",
						padding: '20px 28px 19px',
						borderBottom: '1px solid #D6D6D2',
						backgroundColor: '#F8F7F7'
					}}>
						<span style={{display: "flex", alignItems: "center", color: "black"}}><LockIcon
							style={{marginRight: '10px'}} color={'#000000a6'}/>Show  Completed</span><IonCheckbox
						slot="end" checked={false}/>
					</div>
				</FadeModalSettings>
				}
				{settingOn &&
				<FadeModalSettings value={settingOn} setValue={setSettingOn}>
					<div style={{
						justifyContent: "space-between",
						display: "flex",
						alignItems: "center",
						padding: '20px 28px 19px',
						borderBottom: '1px solid #D6D6D2',
						backgroundColor: '#F8F7F7'
					}} onClick={() => {
						setSettingOn(false);
						setCloseCaseAlert(true);
					}}>
						<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><LockIcon
							style={{marginRight: '10px'}} color={'#007AFF'}/>{client?.is_active ? "Close Case" : "Open Case"}</span>
					</div>
				</FadeModalSettings>
				}
				{client?.is_active === false && <div slot="fixed" className={styles.lock}>
					<div className={styles.lockChat}>
						<CloseCaseIcon color="#000000a6" width="64" height="64"/>
						<div style={{fontSize: 17, color: "#626463", marginTop: 5}}>Closed</div>
					</div>
				</div>}
					{renderQuestions()}
			</IonContent>
		</IonPage>
	);
};

export default CaseLoadDetail;
