import { createEffect, createStore, forward } from "effector";
import axios from "axios";
import { API_ENDPOINT } from "./index";

export interface Employee {
	uid: string;
	name: string;
}

export const fetchEmployees = createEffect<void, { employees: Employee[] }>()
	.use(async () => {
		const req = await axios.get(`${API_ENDPOINT}/employee/employees`);
		return req.data
	})

export const requestChangeEmployee = createEffect<{ uidQuestion: string, uidEmployee: string, message: string }, {}>()
	.use(async ({uidQuestion, uidEmployee, message}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${uidQuestion}/redirect`, {
			employee: uidEmployee,
			message
		});
		return req.data
	})

export const $employees = createStore<Employee[]>([])
	.on(fetchEmployees.doneData, (store, data) => data.employees)

forward({from: requestChangeEmployee.done, to: fetchEmployees})
