import React, { useState } from "react";
import styles from './css/FadeForwardModal.module.css'
import { LockIcon } from "./icons/LockIcon";
import { IonCheckbox } from "@ionic/react";
import { blackAvatarIcon } from "../assets";
import { Employee } from "../api/employees";

export interface Props {
	employees: Employee[]
	onClose?: () => void;
	onForward?: (uid: string, message: string) => void
}

export const FadeForwardModal = ({employees, onClose, onForward}: Props) => {

	const [message, setMessage] = useState("");
	const [selected, setSelected] = useState<string | null>(null)

	const onForwardClick = () => {
		if (!selected) return;
		if (!message) return;
		onForward?.(selected, message);
		setSelected(null);
		setMessage("");
		onClose?.();
	}

	const renderEmployees = () => {
		return employees.map(employee => {
			return (
				<div key={employee.uid} style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '8px 16px 19px'
				}}>
					<span style={{display: "flex", alignItems: "center", color: "black"}}><img
						style={{marginRight: '15px'}} src={blackAvatarIcon}
						alt="blackAvatarIcon"/>{employee.name}</span><IonCheckbox
					onClick={() => setSelected(employee.uid)}
					slot="end" checked={selected === employee.uid}/>
				</div>
			)
		})
	}

	const onChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value)
	}

	return (
		<div className={styles.mainWrapper}>
			<div className={styles.wrapperTitle}>
				<div onClick={onClose} className={styles.cancel}>Cancel</div>
				Forward to
			</div>
			{/*<div className={styles.search}><input placeholder='search' type="text" style={{height: 50, paddingLeft: 10}}/></div>*/}
			<div style={{borderBottom: '1px solid #dfdfe2'}}>
				{renderEmployees()}
			</div>
			<div className={styles.input}><input placeholder='Type a message' type="text" value={message}
			                                     style={{height: 50, paddingLeft: 10}}
			                                     onChange={onChangeMessage}/></div>
			<div className={styles.button} onClick={onForwardClick}>
				Forward
			</div>
		</div>
	)
};


