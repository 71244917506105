import React, { useEffect, useState } from 'react';

import styles from './css/SettingsRightPath.module.css'
import LogOutIcon from "./SVGIcons/LogOutIcon";
import ConnectIcon from "./SVGIcons/ConnectIcon";
import { IonToggle } from "@ionic/react";
import ModalAlert from "../modal/ModalAlert";
import { activateSid, setAuthStateEvent, setSidEvent } from "../../api/auth";
import { deleteDataFromStorage } from "../../api/storage";
import { DbKeys } from "../../helpers/variants";
import {useHistory} from "react-router";

interface Props {
	setPath: any,
	path: string,
	idChatItem: string,
	devices: number,
	tubsSettings: string
}

const SettingsRightPath = (props: Props) => {

	useEffect(() => {
		let thisPath = window.location.pathname;
		return props.setPath(thisPath);
	})
	const history = useHistory();

	const logOut = async () => {
		setAuthStateEvent(false);
		setSidEvent(null);
		activateSid(false);
		await deleteDataFromStorage(DbKeys.defaultUser);
		await deleteDataFromStorage("web_sid");
		history.push(``);
	}

	return (
		<div className={styles.wrapper} style={{background: '#EFEFF3', overflow: "hidden"}}>
			<div className={styles.header}>
				<div>
					<div className={styles.wrapperContent} style={{marginTop: `${props.devices}px`}}>
						<div id='Devices' style={{transition: '400ms', opacity: `${props.devices === 54 ? 1 : 0.5}`}}>
							<div className={styles.titleSet}>Your Devices</div>
							<div className={styles.wrapperItems}>
								<div className={styles.item}>
									<div className={styles.wrapperItem}>
										<div className={styles.wrapperIcon} onClick={logOut}>
											<LogOutIcon/>
											<span>Logout</span>
										</div>
										<span className={styles.brow}>Current Sesion Google Chrome</span>
									</div>
								</div>
								{/*<div className={styles.item}>
                                    <div className={styles.wrapperItem}>
                                        <div className={styles.wrapperIcon}>
                                            <ConnectIcon />
                                            <span>Disconnect Mobile App</span>
                                        </div>
                                        <span className={styles.brow}>Android  1</span>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.wrapperItem}>
                                        <div className={styles.wrapperIconAdd}>
                                            <ConnectIcon color={'green'} />
                                            <span>Disconnect Mobile App</span>
                                        </div>
                                        <span className={styles.brow}>Send activation SMS</span>
                                    </div>
                                </div>*/}
							</div>
						</div>
						{/*<div id='Notifications' style={{transition: '400ms',opacity: `${props.devices===-103? 1 : 0.5}`}}>
                            <div className={styles.titleSet}>Notifications</div>
                            <div className={styles.wrapperItems}>
                                <div className={styles.item}>
                                    <div className={styles.wrapperItem}>
                                        <div className={styles.wrapperIconNotifications}>
                                            <span>Notifications</span>
                                        </div>
                                        <span className={styles.brow}><IonToggle value="sausage" mode={'ios'} color="success"  /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='Security' style={{transition: '400ms',opacity: `${props.devices===-212? 1 : 0.5}`}}>
                            <div className={styles.pasWrapper}>
                                <div className={styles.titleSet}>Password</div>
                                <button>DONE</button>
                            </div>
                            <div className={styles.wrapperItems}>
                                <div className={styles.item}>
                                    <div className={styles.wrapperItem}>
                                        <input placeholder={`Enter new  password`} type="password"/>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.wrapperItem}>
                                        <input placeholder={`Re-enter new password`} type="password"/>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
					</div>
				</div>
			</div>
		</div>
	);
}


export default SettingsRightPath;
