import React, { useEffect } from 'react';

import styles from './css/newItem.module.css'

import Diagramm from "../SVGIcons/Diagramm";
import { useStore } from "effector-react";
import { activeNewsStore } from "../../api/news";

interface Props {
	setPath: any,
	path: string,
	newsId: string
}

const NewItem = (props: Props) => {

	const activeNews = useStore(activeNewsStore);

	useEffect(() => {
		let thisPath = window.location.pathname;
		return props.setPath(thisPath)
	})

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.controlBarAll} style={{backgroundColor: 'white'}}>
					<div className={styles.title}>
						<div className={styles.chatIcon}><Diagramm/></div>
						{activeNews?.title}</div>
				</div>
				<div className={styles.wrapperContent} dangerouslySetInnerHTML={{__html: activeNews?.content ?? ""}}>

				</div>
			</div>
		</div>
	);
}


export default NewItem;
