import React, { useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonLabel, IonModal, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './assets/fonts.css'
import News from './pages/news/News';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import ClientChats from "./pages/clientChats";
import NewsDetail from "./pages/news/NewsDetail";
import FirstScreenLogIn from "./pages/LogIn/FirstScreenLogIn";
import SecondScreenLogIn from "./pages/LogIn/SecondScreenLogIn";
import { ClientChat } from "./pages/clientChat";
import CaseLoad from "./pages/caseLoad/CaseLoad";
import CaseLoadDetail from "./pages/caseLoad/CaseLoadDetail";
import { APP_VARIANT, AppVariant } from "./config";
import IncomingList from "./pages/IncomingList";
import { IncomingChat } from "./pages/incomingChat";
import { useStore } from "effector-react";
import axios from "axios";
import {
	activateSid,
	fetchStatusEffect,
	isAuthStore,
	isRegisteredStore,
	setAuthStateEvent,
	setRegisteredEvent,
	setSidEvent,
	setUserEvent,
	sidStore,
	User,
	userStore
} from "./api/auth";
import Settings from "./pages/settings/Settings";
import { getStringFromStorage, getTypeFromStorage } from "./api/storage";
import EnterPin from "./pages/LogIn/EnterPin";
import NewPin from "./pages/LogIn/NewPin";
import { setDeliveredMessagesEvent } from "./api/messages";
import { InboxIcon } from "./components/icons/InboxIcon";
import { ClientIcon } from "./components/icons/ClientsIcon";
import { SettingsIcon } from "./components/icons/SettingsIcon";
import { unreadCountStore } from "./api/questions";
import { NewsIcon } from "./components/icons/NewsIcon";
import { DbKeys } from "./helpers/variants";

import { AppState, Plugins , StatusBarStyle} from '@capacitor/core';

import { MainPage } from "./pages/mainPage/MainPage";
import LogInPage from "./pages/LogIn/LogInWeb";
import moment from "moment";

import { $globalPreloader } from "./api";
import {preloader} from "./assets";

const {App: AppCap} = Plugins;

const { StatusBar } = Plugins;
interface ManagerTabProps {
	path: string;
	setPath: any;
	unread: number
}

const RenderManagerTabs = ({setPath, unread = 0}: ManagerTabProps) => {
	const checkUrl = () => {
		let url = window.location.pathname;
		setPath(url)
	}
	const path = window.location.pathname;
	return (
		<IonTabs>
			<IonRouterOutlet>
				<Route path="/chats" component={IncomingList} exact={true}/>
				<Route path="/caseload" component={CaseLoad} exact={true}/>
				<Route path="/settings" component={Settings} exact={true}/>
				<Route path="/" render={() => <Redirect to="/chats"/>} exact={true}/>
			</IonRouterOutlet>
			<IonTabBar slot="bottom" >
				<IonTabButton tab="tab1" href="/chats" onClick={() => checkUrl()}>
					<div style={{position: 'relative'}}><InboxIcon
						color={`${path.indexOf('/chat') ? '#9B9B9B' : '#007AFF'}`}/>
						{unread > 0 &&
						<span style={{
							color: 'white',
							position: 'absolute',
							top: 0,
							right: -8,
							display: "flex",
							justifyContent: "center",
							height: '16px',
							width: '16px',
							backgroundColor: '#FA4C41',
							borderRadius: '50%'
						}}>{unread}</span>}
					</div>
					<IonLabel>Inbox</IonLabel>
				</IonTabButton>
				<IonTabButton tab="tab2" href="/caseload" onClick={() => checkUrl()}>
					<div><ClientIcon
						color={`${path.indexOf('/caseload') ? '#9B9B9B' : '#007AFF'}`}/></div>
					<IonLabel>Caseload</IonLabel>
				</IonTabButton>
				<IonTabButton tab="tab4" href="/settings" onClick={() => checkUrl()}>
					<div><SettingsIcon
						color={`${path.indexOf('/settings') ? '#9B9B9B' : '#007AFF'}`}/></div>
					<IonLabel>Instellingen</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
};

interface ClientTabProps {
	path: string;
	setPath: any;
	unread: number
}


const RenderClientTabs = ({setPath, unread = 0}: ClientTabProps) => {

	const checkUrl = () => {
		let url = window.location.pathname;
		setPath?.(url)
	}
	const path = window.location.pathname;
	return (
		<IonTabs>
			<IonRouterOutlet>
				<Route path="/chats" component={ClientChats} exact={true}/>
				<Route path="/news" component={News} exact={true}/>
				<Route path="/settings" component={Settings} exact={true}/>
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton tab="tab1" href="/chats" onClick={() => checkUrl()}>
					<div style={{marginTop: '2px', position: 'relative'}}>
						<InboxIcon
							color={`${path.indexOf('/chat') ? '#9B9B9B' : '#007AFF'}`}/>
						{unread > 0 &&
						<span style={{
							color: 'white',
							position: 'absolute',
							top: 0,
							right: -8,
							display: "flex",
							justifyContent: "center",
							height: '16px',
							width: '16px',
							backgroundColor: '#FA4C41',
							borderRadius: '50%'
						}}>{unread}</span>}
					</div>
					<IonLabel>Vragen</IonLabel>
				</IonTabButton>
				<IonTabButton tab="tab2" href="/news" onClick={() => checkUrl()}>
					<div style={{marginTop: '2px'}}><NewsIcon
						color={`${path.indexOf('/news') ? '#9B9B9B' : '#007AFF'}`}/></div>
					<IonLabel>Nieuws</IonLabel>
				</IonTabButton>
				<IonTabButton tab="tab4" href="/settings">
					<div style={{marginTop: '2px'}} onClick={() => checkUrl()}><SettingsIcon
						color={`${path.indexOf('/settings') ? '#9B9B9B' : '#007AFF'}`}/></div>
					<IonLabel>Instellingen</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
}


const ManagerRoutes = ({path = "", setPath, unread = 0}: ClientTabProps) => {

	return (
		<IonRouterOutlet>
			<Route path="/caseload-detail" component={CaseLoadDetail} exact={true}/>
			<Route path="/chat" component={IncomingChat} exact={true}/>
			<Route path="/caseload-chat" component={IncomingChat} exact={true}/>
			<Route path="/pin" render={() => <Redirect to="/chats"/>} exact={true}/>
			<Route path={["/chats", "/caseload", "/settings"]}
			       component={() => <RenderManagerTabs path={path} setPath={setPath} unread={unread}/>} exact={true}/>
			<Route path="/" render={() => <Redirect to="/chats"/>} exact={true}/>
		</IonRouterOutlet>
	)
};

const ClientRoutes = ({path = "", setPath, unread = 0}: ClientTabProps) => {

	return (
		<IonRouterOutlet>
			<Route path="/news/detail" component={NewsDetail} exact={true}/>
			<Route path="/chat" component={ClientChat} exact={true}/>
			<Route path="/pin" render={() => <Redirect to="/chats"/>} exact={true}/>
			<Route path={["/chats", "/news", "/settings"]}
			       component={() => <RenderClientTabs path={path} setPath={setPath} unread={unread}/>} exact={true}/>
			<Route path="/" render={() => <Redirect to="/chats"/>} exact={true}/>
		</IonRouterOutlet>
	)
};


const registrationRoutes = () => {
	return (
		<IonRouterOutlet>
			<Route path="/login" component={FirstScreenLogIn} exact={true}/>
			<Route path="/code" component={SecondScreenLogIn} exact={true}/>
			<Route path="/pin" component={NewPin} exact={true}/>
			<Route path="/" render={() => <Redirect to="/login"/>} exact={true}/>
		</IonRouterOutlet>
	)
}

const loginRoutes = () => {
	return (
		<IonRouterOutlet>
			<Route path="/" component={EnterPin} exact={true}/>
			<Redirect to="/"/>
		</IonRouterOutlet>
	)
}

const initDataFromDb = async () => {
	if (APP_VARIANT === AppVariant.Web) {
		const test1 = await getStringFromStorage(DbKeys.defaultUser);
		const test2 = await getStringFromStorage("web_sid");
		if (test1 === "undefined" || test2 === "undefined") {
			localStorage.clear()
		}
	}
	const registered = await getStringFromStorage(DbKeys.registered);
	registered === "yes" && setRegisteredEvent(true);
	const user = await getTypeFromStorage<User>(DbKeys.defaultUser);
	const deliveredMessages = await getTypeFromStorage<string[]>(DbKeys.deliveredMessages) || [];
	if (APP_VARIANT === AppVariant.Web) {
		const webSid = await getStringFromStorage("web_sid");
		webSid && setSidEvent(webSid);
		webSid && setAuthStateEvent(true)
	}

	setDeliveredMessagesEvent(deliveredMessages);
	user && setUserEvent(user);
}

let lastState = moment().toISOString();


const AppContainer = () => {

	const history = useHistory();

	const checkUrl = () => {
		let url = window.location.pathname;
		setPath(url)
	}

	useEffect(() => {
		StatusBar.setBackgroundColor({color:"#F8F7F7"})
		StatusBar.setStyle({
			style: StatusBarStyle.Light
		});
	}, [])

	useEffect(() => {
		checkUrl()
	})

	const sid = useStore(sidStore);
	const isAuth = useStore(isAuthStore);
	const isRegistered = useStore(isRegisteredStore);
	const user = useStore(userStore);

	const unreadCount = useStore(unreadCountStore);

	const [path, setPath] = useState(window.location.pathname)
	const [init, setInit] = useState(false);

	useEffect(() => {
		axios.defaults.headers["X-SID"] = sid;
		activateSid(sid !== null)
	}, [sid])

	useEffect(() => {
		initDataFromDb().then(() => setInit(true))
	}, [])

	useEffect(() => {
		if (APP_VARIANT === AppVariant.Web) {
			sid && fetchStatusEffect().then();
		}
	}, [sid])

	useEffect(() => {
		if (APP_VARIANT !== AppVariant.Web) {
			AppCap.addListener('appStateChange', (state: AppState) => {
				if (!state.isActive) {
					lastState = moment().toISOString()
				} else {
					const a = moment(lastState);
					const b = moment(new Date());
					const second = Math.abs(a.diff(b, 'second'));
					if (second > 900) {
						setSidEvent(null);
						setAuthStateEvent(false);
						history.replace("/")
					}
				}
			});
		}
	}, [])

	if (!init) return null;
	if (APP_VARIANT !== AppVariant.Web) {
		if (!isAuth && !isRegistered) return registrationRoutes();
		if (!isAuth && isRegistered) return loginRoutes()
	} else {
		if (!isAuth) {
			return <LogInPage/>
		}
	}

	if (!isAuth || !user) return null;

	switch (APP_VARIANT) {
		case AppVariant.Client:
			return <ClientRoutes path={path} setPath={setPath} unread={unreadCount}/>;
		case AppVariant.Manager:
			return <ManagerRoutes path={path} setPath={setPath} unread={unreadCount}/>;
		case AppVariant.Web:
			return <MainPage/>
	}
	return null;

};

const App: React.FC = () => {

	const preloaderState = useStore($globalPreloader);

	return (
		<IonApp>
		{preloaderState && <div style={{zIndex: 1000,backgroundColor: 'rgba(0, 0, 0, 0.1)',position:"fixed",top: 0,left: 0,right: 0,bottom: 0,display: "flex",flexFlow: "column",
			justifyContent: "center",alignItems: "center",height: '100vh'}}>
			<div style={{height: '100px',width: '100px',backgroundColor: 'white',borderRadius: '15px',display:"flex",alignItems: "center",justifyContent: "center"}}>
				<img src={preloader} alt="preloader"/>
			</div>
		</div>}
		 	<IonReactRouter>
				<AppContainer/>
		 	</IonReactRouter>
		</IonApp>
	);
};

export default App;
