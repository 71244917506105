import React, { useEffect, useState } from 'react';
import styles from './css/ChatWithManager.module.css'
import { useHistory, useParams } from "react-router";
import Diagramm from "../SVGIcons/Diagramm";
import { avatarIcon, managerIcon } from "../../assets";
import { useStore } from "effector-react";
import { userStore } from "../../api/auth";
import {
	activeQuestionUidStore,
	ClientQuestion,
	clientQuestionsStore,
	createQuestionEffect, fetchClientQuestionsEffect,
	Question, setActiveQuestionUidEvent
} from "../../api/questions";
import {
	addMessage,
	fetchClientMessagesEffect,
	fetchManagerMessagesEffect,
	Message, messagesFetchInterval,
	messagesStore, sendClientMessageAction
} from "../../api/messages";
import { List } from "immutable";
import { formatDateTime, getAbbreviation } from "../../helpers/format";
import moment from "moment";
import { v4 } from "uuid";
import { IonInput } from "@ionic/react";
import { InputChangeEventDetail } from "@ionic/core";
import ArchiveIcon from "../SVGIcons/ArchiveIcon";

interface Props {
	scrollChat: boolean
	setScrollChat: any
}

const ChatWithManager = (props: Props) => {

	const history = useHistory();
	const params = useParams<{ uid: string }>();

	const user = useStore(userStore);
	const activeQuestionUid = useStore(activeQuestionUidStore);
	const allMessages = useStore(messagesStore);
	const clientQuestions = useStore(clientQuestionsStore);

	const [messages, setMessages] = useState<List<Message>>(List());
	const [oldMessages, setOldMessages] = useState<List<Message>>();
	const [message, setMessage] = useState("");

	const [question, setQuestion] = useState<ClientQuestion | null>()

	const myRef = React.useRef();

	useEffect(() => {
		// @ts-ignore
		const divHeight = myRef.current.clientHeight
		// @ts-ignore
		const scrollHeight = myRef.current.scrollHeight

		// if (props.scrollChat && divHeight < scrollHeight) {
		// 	// @ts-ignore
		// 	myRef.current.scrollTop = 999999;
		// }
	})




	useEffect(() => {
		activeQuestionUid && fetchClientMessagesEffect(activeQuestionUid)
	}, [activeQuestionUid])

	useEffect(() => {
		const find = clientQuestions.find(q => q.uid === activeQuestionUid)
		find && setQuestion(find);
	}, [clientQuestionsStore, activeQuestionUid])

	useEffect(() => {
		setOldMessages(messages)
		if (activeQuestionUid) {
			setMessages(allMessages.get(activeQuestionUid) || List())
		} else {
			setMessages(List())
		}

	}, [allMessages, activeQuestionUid]);

	useEffect(() => {
		if (messages.size !== oldMessages?.size) {
			// @ts-ignore
			myRef.current.scrollTop = 999999;
		} else {

		}
	},[messages])

	useEffect(() => {
		return messagesFetchInterval.watch(() => {
			activeQuestionUid && fetchClientMessagesEffect(activeQuestionUid).then()
		})
	}, [activeQuestionUid])

	const renderMessages = () => {
		return messages.map(m => {
			return m.is_my ? renderClientMessage(m) : renderManagerMessage(m)
		}).toArray()

	}

	useEffect(() => {
		return createQuestionEffect.doneData.watch(data => {
			setActiveQuestionUidEvent(data.question.uid);
			history.push(`/vragen/detail/${data.question.uid}`);
		})
	}, [])

	const sendHandler = async () => {
		if (message.length === 0) return;
		const uuid = v4();
		const newMessage = {
			created_at: moment().toISOString(),
			is_my: true,
			is_read: false,
			sender: null,
			text: message,
			uid: v4()
		}
		if (activeQuestionUid) {
			const _message = message;
			setMessage("");
			addMessage({message: newMessage, questionId: activeQuestionUid});
			await sendClientMessageAction({questionId: activeQuestionUid, message: _message, uuid});
			await fetchClientMessagesEffect(activeQuestionUid);
			setMessage("");
		}
		if (activeQuestionUid === null && message.length > 0) {
			const _message = message;
			setMessage("");
			addMessage({message: newMessage, questionId: null});
			await createQuestionEffect({title: "NEW QUESTION", message: {content: _message, uuid: uuid}});
		}
		await fetchClientQuestionsEffect()
	}

	const keyDown = async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
		if (event.keyCode === 13 && message.length > 0) {
			await sendHandler();
			// @ts-ignore
			myRef.current.scrollTop = 999999;
		}
	};

	const onChange = (event: CustomEvent<InputChangeEventDetail>) => {
		setMessage(event.detail.value || "")
	};
	const archive = (archive: any) => {
		return archive != null ? 'rgba(0, 0, 0, 0.4)' : '#66B980'
	}
	const renderClientMessage = (m: Message) => {
		return (
			<div key={m.uid} className={styles.itemMe}>
				<div className={styles.wrapperManagerName}>
					<div className={styles.abreviature}>{getAbbreviation(user?.name ?? "")}</div>
					<span>{user?.name}</span></div>
				<div className={styles.itemChat}>
					<span>{m.text}</span>
					<span className={styles.time}>{formatDateTime(m.created_at, true)}</span>
				</div>
			</div>
		)
	}

	const renderManagerMessage = (m: Message) => {
		return (
			<div key={m.uid} className={styles.itemManager}>
				<div className={styles.wrapperManagerName}><img src={question?.employee.avatar ?? managerIcon}
				                                                alt="avatarIcon"/><span>{m.sender?.name}</span>
				</div>
				<div className={styles.itemChat}>
					<span>{m.text}</span>
					<span className={styles.time}>{formatDateTime(m.created_at, true)}</span>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.wrapper}>
			{question?.result != null && <div className={styles.lock}>
				<div className={styles.wrapperLock}>
					<ArchiveIcon/>
					<span>Archived</span>
				</div>
			</div>}
			<div className={styles.header}>
				<div className={styles.controlBarAll} style={{backgroundColor: `${archive(question?.result)}`}}>
					<div className={styles.title}>
						{question?.title || "NEW QUESTION"}
					</div>
				</div>
			</div>
			<div className={styles.wrapperChat}>
				{
					// @ts-ignore
					<div className={styles.chat} ref={myRef}>
						{renderMessages()}
					</div>
				}
			</div>
			<div className={styles.footer}>
				<IonInput placeholder="Type a message" onKeyDown={keyDown} value={message} onIonChange={onChange}/>
			</div>
		</div>
	);
}


export default ChatWithManager;
