import { getAbbreviation } from "../helpers/format";
import React from "react";

const styles: { [key: string]: React.CSSProperties } = {
	avatarCircle: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		minWidth: 56,
		minHeight: 56,
		borderRadius: 28,
	},
	circleFont: {
		fontSize: 24,
		fontWeight: 500,
		color: "white",
		textTransform: "uppercase"

	}
}

interface Props {
	name?: string;
	icon?: JSX.Element;
	color?: string;
}

export const CircleAvatar = ({name, icon, color = "#B9B9B9"}: Props) => {
	const avatarCircleStyle = {...styles.avatarCircle, backgroundColor: color}
	return (
		<div style={avatarCircleStyle}>
			{icon}
			{name && <div style={styles.circleFont}>{getAbbreviation(name)}</div>}
		</div>
	)
}
