import React from 'react';

const ArchiveIcon = (props) => {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M23 10V23H5V10M12 14H16M3 5H25V10H3V5Z" stroke="#999999" strokeLinecap="round"
			      strokeLinejoin="round"/>
		</svg>
	);
}


export default ArchiveIcon;
