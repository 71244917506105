import React, { useEffect, useState } from 'react';
import styles from "./css/PinLogIn.module.css"
import KeyBoard from "../../components/KeyBoard"
import Pin from "../../components/Pin"
import { IonAlert, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { useStore } from "effector-react";
import { fetchStatusEffect, phoneStore, setAuthStateEvent, setSidEvent, sidStore, userStore } from "../../api/auth";
import { getStringFromStorage } from "../../api/storage";
import { getAbbreviation } from "../../helpers/format";
import { DbKeys } from "../../helpers/variants";

function EnterPin() {

	const [pin, setPin] = useState("");
	const [alert, setAlert] = useState(false)

	const user = useStore(userStore);
	const phone = useStore(phoneStore)

	const onCodeChanged = (code: string) => {
		setPin(code);
	}

	useEffect(() => {
		if (pin.length === 4) {
			getStringFromStorage(`${DbKeys._prefix}_${pin}`).then(value => {
				if (value) {
					setPin("");
					setSidEvent(value);
					setAuthStateEvent(true);
					fetchStatusEffect().then()
				} else {
					setAlert(true);
					setPin("");
				}
			})
		}
	}, [pin])

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar style={{display: "flex", flex: 1, alignItems: "center"}}>
					<div className={styles.wrapperHeader}>
						<div className={styles.wrapperHeader}>
							{/*<div className={styles.loginAs}>Login as</div>*/}
							<div>
								<div className={styles.userName}>{user?.name}</div>
								<div className={styles.organization}>Organization Name</div>
							</div>
							{/*<div className={styles.avatarCircle}>*/}
							{/*	<div className={styles.circleFont}>{getAbbreviation(user?.name ?? "")}</div>*/}
							{/*</div>*/}
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonAlert
					isOpen={alert}
					onDidDismiss={() => setAlert(false)}
					header={'Error'}
					message={'Wrong pin'}
					buttons={['OK']}
				/>
				<div className={styles.container}>
					<div className={styles.wrapperTitle}>
						<div className={styles.title}>Voer een <span style={{color: '#42d551'}}>pincode</span> in
						</div>
					</div>
					<Pin styles={styles} pin={pin} count={4}/>
					<KeyBoard styles={styles} pin={pin} onCodeChanged={onCodeChanged}/>
					{/*<div className={styles.vergetCode}>Vergeet de code?</div>*/}
				</div>
			</IonContent>
		</IonPage>
	);
}


export default EnterPin;
