import React, {RefObject, useEffect, useRef, useState} from 'react';

import styles from '../css/RightPathInbox.module.css'
import {
	archiveIcon, arrowTopIcon, avatarIcon,
	chatIcon,
	chevronsIcon,
	chevronsrightIcon,
	editIcon,
	goBackIcon,
	goBackWhiteIcon,
	gridIcon, managerIcon
} from "../../assets";
import { useHistory } from "react-router";
import ArchiveIcon from "../SVGIcons/ArchiveIcon";
import EditIcon from "../SVGIcons/EditIcon";
import ChevronsRight from "../SVGIcons/ChevronsRight";
import GridIcon from "../SVGIcons/GridIcon";
import ChevronsIcon from "../SVGIcons/ChevronsIcon";
import Diagramm from "../SVGIcons/Diagramm";
import ModalSelectedType from "../modal/ModalSelectedType";
import ModalAlert from "../modal/ModalAlert";
import ModalForward from "../modal/ModalForward";
import { useStore } from "effector-react";
import { userStore } from "../../api/auth";
import {
	activeQuestionUidStore, changeQuestionTitle,
	ClientQuestion,
	clientQuestionsStore, closeQuestion,
	createQuestionEffect,
	fetchClientQuestionsEffect,
	fetchManagerQuestionsEffect,
	ManagerQuestion,
	managerQuestionsStore, reopenQuestion,
	setActiveQuestionUidEvent
} from "../../api/questions";
import {
	addMessage,
	fetchClientMessagesEffect,
	fetchManagerMessagesEffect,
	Message, messagesFetchInterval,
	messagesStore, sendClientMessageAction, sendManagerMessageAction
} from "../../api/messages";
import { List } from "immutable";
import { formatDateTime, getAbbreviation } from "../../helpers/format";
import moment from "moment";
import { v4 } from "uuid";
import { InputChangeEventDetail } from "@ionic/core";
import { IonInput } from "@ionic/react";
import { fetchCategories } from "../../api/categories";

interface Props {
	setPath: any,
	path: string,
	idChatItem: string,
	setSearch?: any,
	search?: string,
	scrollChat: boolean,
	setScrollChat: any
}

const RightPathInboxComponent = (props: Props) => {
	const history = useHistory();


	const user = useStore(userStore);
	const activeQuestionUid = useStore(activeQuestionUidStore);
	const allMessages = useStore(messagesStore);
	const managerQuestions = useStore(managerQuestionsStore);
	const questions = useStore(managerQuestionsStore);

	const [messages, setMessages] = useState<List<Message>>(List());
	const [oldMessages, setOldMessages] = useState<List<Message>>();

	const [focus, setFocus] = useState(true);
	const [message, setMessage] = useState("");

	const [rename, setRename] = useState(false)
	const [renameVal, setRenameVal] = useState('')
	const [forward, setForward] = useState(false)
	const [selectedType, setSelectedType] = useState(false)
	const [unarchive, setUnarchive] = useState(false)
	const [question, setQuestion] = useState<ManagerQuestion | null>()

	const myRef = React.useRef();
	const Rename = React.useRef();

	useEffect(() => {
		// @ts-ignore
		const divHeight = myRef.current.clientHeight
		// @ts-ignore
		const scrollHeight = myRef.current.scrollHeight
		if (props.scrollChat && divHeight < scrollHeight) {
			// @ts-ignore
			myRef.current.scrollTop = 999999;
			props.setScrollChat(false)
		}
	})

	useEffect(() => {
		question && renameVal === "" && setRenameVal(question?.title)
		let thisPath = window.location.pathname;
		return props.setPath(thisPath)
	}, [question])

	useEffect(() => {
		activeQuestionUid && fetchManagerMessagesEffect(activeQuestionUid)
	}, [activeQuestionUid])

	useEffect(() => {
		return messagesFetchInterval.watch(() => {
			activeQuestionUid && fetchManagerMessagesEffect(activeQuestionUid).then()
		})
	}, [activeQuestionUid])

	useEffect(() => {
		const find = managerQuestions.find(q => q.uid === activeQuestionUid);
		find && setQuestion(find);
	}, [managerQuestions])

	useEffect(() => {
		setOldMessages(messages)

		if (activeQuestionUid) {
			setMessages(allMessages.get(activeQuestionUid) || List())
		} else {
			setMessages(List())
		}

	}, [allMessages, activeQuestionUid]);
	useEffect(() => {
		if (messages.size !== oldMessages?.size) {
			// @ts-ignore
			myRef.current.scrollTop = 999999;
		} else {

		}
	},[messages])
	useEffect(() => {
		fetchCategories().then()
	}, [])


	const renderMessages = () => {
		return messages.map(m => {
			return m.is_my
				?
				renderManagerMessage(m)
				:
				renderClientMessage(m)

		}).toArray()
	}

	const archiveOrUnarchiveChatHandler = async () => {
		setUnarchive(false)
		if (question?.result === null) {
			activeQuestionUid && await closeQuestion({uid: activeQuestionUid, result: "archived"});
		} else {
			activeQuestionUid && await reopenQuestion({uid: activeQuestionUid});
		}
		await fetchManagerQuestionsEffect();
	}

	const onChange = (event: CustomEvent<InputChangeEventDetail>) => {
		setMessage(event.detail.value || "")
	};

	const sendHandler = async () => {
		if (message.length === 0 || !activeQuestionUid || question?.result !== null) return;
		const uuid = v4();
		const newMessage = {
			created_at: moment().toISOString(),
			is_my: true,
			is_read: false,
			sender: null,
			text: message,
			uid: v4()
		}
		addMessage({message: newMessage, questionId: activeQuestionUid})
		await sendManagerMessageAction({questionId: activeQuestionUid, message, uuid});
		await fetchManagerMessagesEffect(activeQuestionUid);
		setMessage("");

		await fetchManagerQuestionsEffect()
	}

	const keyDown = async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
		if (event.keyCode === 13 && question?.uid && message.length > 0) {


			await sendHandler();
			// @ts-ignore
			myRef.current.scrollTop = 999999;
		}
	};

	const renderClientMessage = (m: Message) => {
		return (
			<div key={m.uid} className={styles.itemManager}>
				<div className={styles.wrapperManagerName}>
					{m.sender?.name ? <div className={styles.abreviature}>{getAbbreviation(m.sender?.name)}</div> : null}
					<span>{m.sender?.name}</span>
				</div>
				<div className={styles.itemChat}>
					<span>{m.text}</span>
					<span className={styles.time}>{formatDateTime(m.created_at, true)}</span>
				</div>
			</div>
		)
	}

	const renderManagerMessage = (m: Message) => {
		return (
			<div key={m.uid} className={styles.itemManager}>
				<div className={styles.wrapperManagerName}><img src={user?.avatar ?? managerIcon}
				                                                alt="avatarIcon"/><span>{m.sender?.name}</span>
					<span>{user?.name}</span>
				</div>
				<div className={styles.itemChat}>
					<span>{m.text}</span>
					<span className={styles.time}>{formatDateTime(m.created_at, true)}</span>
				</div>
			</div>
		)
	}

	const cancelRename = () => {
		setRename(false);
		question && setRenameVal(question?.title)
	}

	const doneRename = async () => {
		setRename(false);
		activeQuestionUid && await changeQuestionTitle({uid: activeQuestionUid, title: renameVal});
		await fetchManagerQuestionsEffect();
	}
	const archive = (archive: any) => {
		return archive != null ? 'rgba(0, 0, 0, 0.4)' : '#66B980'
	}


	const focusName = async () => {
		await setRename(!rename)
		// @ts-ignore
		await Rename.current.focus()
	}
	return (
		<div className={styles.wrapper}>
			{question?.result != null && <div className={styles.lock}>
				<div className={styles.wrapperLock}>
					<ArchiveIcon/>
					<span>Archived</span>
				</div>
			</div>}
			{selectedType && <ModalSelectedType
				doneFunc={() => setSelectedType(!selectedType)}
				cancelFunc={() => setSelectedType(!selectedType)}
			/>}
			{unarchive && <ModalAlert
				question={question?.result === null ? 'Archive question?' : 'Unarchive question?'}
				secondText={'Would you like to ..?'}
				cancel={'Cancel'} done={'Yes'}
				doneFunc={archiveOrUnarchiveChatHandler}
				cancelFunc={() => setUnarchive(false)}
			/>}
			{forward && <ModalForward
				onClose={() => setForward(false)}
			/>}
			<div className={styles.header}>
				<div className={styles.controlBarAll}
				     style={{backgroundColor: `${rename ? 'rgb(47, 175, 87)' : archive(question?.result)}`}}>
					<div className={styles.title} style={{justifyContent: `${rename ? 'space-between' : 'center'}`}}>
						{rename && <div className={styles.cancel} onClick={cancelRename}>Cancel</div>}
						{
							// @ts-ignore
							<input ref={Rename} value={renameVal} onChange={(event) => {
							setRenameVal(event.target.value)
						}} disabled={!rename} type="text"/>}
						{rename && <div className={styles.done} onClick={doneRename}>Done</div>}
					</div>
				</div>
				<div className={styles.wrapperControkBar}>
					{question?.result === null ? <>
							<div className={styles.controlBarChat}>
								<div className={styles.wrapperEl} onClick={() => setUnarchive(!unarchive)}><ArchiveIcon/><span>Archive chat</span></div>
								<div className={styles.wrapperEl} onClick={() => focusName()}><EditIcon/><span>Rename</span></div>
								<div className={styles.wrapperEl} onClick={() => {setForward(!forward)}}><ChevronsRight/><span>Forward</span></div>
							</div>
							<div className={styles.controlBarChat}>
								{/*<div className={styles.wrapperEl}><GridIcon/><span>Move to GENERAL</span></div>*/}
								<div className={styles.wrapperEl} onClick={() => setSelectedType(!selectedType)}><ChevronsIcon/><span>Change Category</span></div>
							</div>
						</> :
						<>
							<div className={styles.controlBarChat}>
								<div className={styles.wrapperEl} onClick={() => setUnarchive(!unarchive)}>
									<ArchiveIcon/><span>Unarchive chat</span>
								</div>
							</div>
							<div className={styles.controlBarChat}>

							</div>
						</>
					}

				</div>

			</div>
			<div className={styles.wrapperChat}>
				{
					// @ts-ignore
				<div className={styles.chat} ref={myRef}>
					{renderMessages()}
				</div>
				}
			</div>
			<div className={styles.footer}>
				<IonInput
					autofocus={focus}
					placeholder={'Type a message...'}
					type="text"
					onKeyDown={keyDown}
					value={message}
					onIonChange={onChange}
				/>
				{/*<input*/}
				{/*	placeholder={'Type a message...'}*/}
				{/*/>*/}
				<div className={styles.goMess} onClick={() => console.log('go')}>
					<img src={arrowTopIcon}/>
				</div>
			</div>
		</div>
	);
}


export default RightPathInboxComponent;


// // @ts-ignore
// const Chat = React.useRef();
//
// // @ts-ignore
// useEffect(() => focusChat())
//
// const focusChat = () => {
// 	if (Chat) {
// 		// @ts-ignore
// 		Chat.current.focus()
// 	}
// }
// <input
// 	// @ts-ignore
// 	ref={Chat}
// 	autoFocus={focus}
// 	placeholder={'Type a message...'}
// 	type="text"
// 	// @ts-ignore
// 	onKeyDown={keyDown}
// 	value={message}
// 	// @ts-ignore
// 	onChange={onChange}
// />
