import moment from "moment";

export const getAbbreviation = (str: string) => {
	const trimmed = str.trim();
	if (trimmed.indexOf(" ") !== -1) {
		const [first, second] = trimmed.split(" ");
		return first[0].toUpperCase() + second[0].toUpperCase()
	}
	if (trimmed.indexOf(".") !== -1) {
		const [first, second] = trimmed.split(".");
		return first[0].toUpperCase() + second[0].toUpperCase()
	}
	if (trimmed.length > 1) {
		return trimmed[0].toUpperCase() + trimmed[1].toUpperCase()
	}
	if (trimmed.length === 1) {
		return trimmed[0].toUpperCase()
	}
	return str;
}

export const formatDateTime = (time: string, q = false) => {
	const a = moment(time);
	const b = moment(new Date());
	const day = Math.abs(a.diff(b, 'days'))
	return (day >= 1) ? q ? a.format("DD-MM-YYYY HH:MM") : a.format("DD-MM-YYYY") : a.format("HH:MM")
}
