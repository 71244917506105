import React from 'react';

const ArrowRightIcon = (props) => {

    return (
        <>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 6.97402L1 13" stroke={props.stroke? props.stroke : "white"} strokeWidth="2"/>
            </svg>
        </>
    );
}


export default ArrowRightIcon;
