import React from "react";
import styles from './css/FadeModalSetting.module.css'

export interface Props {
	value: boolean
	setValue: any;
	children: any;
	secondValue?: boolean;
	secondSetValue?: any;
	data?: { text: string, icon: string, value: boolean }[];
}

export const FadeModalSettings = ({setValue,value,children,secondValue,secondSetValue,data}: Props) => {
	return (
		<div className={styles.mainWrapper}>
			<div className={styles.secondWrapper}>
				<div className={styles.borderWrapper}>
					<div className={styles.itemsWrapper}>
						{children}
					</div>
					<div className={styles.buttonWrapper}>
						<div
							onClick={() => {
								setValue(!value);
								secondValue && secondSetValue(!secondValue)
							}}
							className={styles.button}>cancel</div>
					</div>
				</div>
			</div>
		</div>
	)
};


