import React from 'react';

const SettingsConnectIcon = (props) => {

    return (
        <>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="7" fill={props.fill? props.fill : "white"}/>
                <path stroke={props.stroke? props.stroke : "white"} d="M13.5311 15.8676C13.8379 16.2777 14.2293 16.6171 14.6787 16.8626C15.1281 17.1081 15.6251 17.2541 16.1359 17.2907C16.6467 17.3273 17.1594 17.2536 17.6393 17.0746C18.1191 16.8956 18.5548 16.6155 18.9169 16.2534L21.0597 14.1105C21.7103 13.4369 22.0703 12.5348 22.0621 11.5983C22.054 10.6619 21.6784 9.76617 21.0162 9.104C20.354 8.44183 19.4583 8.06623 18.5219 8.05809C17.5854 8.04995 16.6833 8.40993 16.0097 9.0605L14.7811 10.2819M16.3883 14.4391C16.0815 14.029 15.6902 13.6897 15.2408 13.4441C14.7913 13.1986 14.2944 13.0526 13.7835 13.016C13.2727 12.9794 12.76 13.0531 12.2802 13.2321C11.8004 13.4111 11.3646 13.6912 11.0026 14.0534L8.85972 16.1962C8.20916 16.8698 7.84918 17.7719 7.85731 18.7084C7.86545 19.6448 8.24105 20.5405 8.90323 21.2027C9.5654 21.8649 10.4612 22.2405 11.3976 22.2486C12.334 22.2568 13.2361 21.8968 13.9097 21.2462L15.1311 20.0248"  strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default SettingsConnectIcon;
