import React from 'react';

const LogOutIcon = (props) => {

    return (
        <>
            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 23.4147H7C6.46957 23.4147 5.96086 23.204 5.58579 22.8289C5.21071 22.4539 5 21.9452 5 21.4147V7.41473C5 6.8843 5.21071 6.37559 5.58579 6.00052C5.96086 5.62545 6.46957 5.41473 7 5.41473H11M18 19.4147L23 14.4147M23 14.4147L18 9.41473M23 14.4147H11" stroke={props.stroke? props.stroke : "#FA4C41"} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default LogOutIcon;
