import { createEffect, createEvent, createStore, guard } from "effector-logger";
import axios from "axios";
import { API_ENDPOINT, LOGIN } from "./index";
import { fromObservable } from "effector";
import { interval } from "rxjs";

export interface User {
	type: "client" | "employee";
	name: string;
	phone: string;
	is_admin: boolean;
	avatar: string | null;
}

interface AuthResponse {
	success: boolean;
	user: User
	sid: string;

}

interface AuthRequestParam {
	email: string;
	password: string;
	org: string
}

interface FetchStatusActionResponse {
	is_authorized: boolean;
	user: User
}

export const fetchAuthEffect = createEffect<AuthRequestParam, AuthResponse>({
	handler: async ({email, password, org}) => {
		const data = {email, password, organization: org};
		const res = await axios.post<AuthResponse>(LOGIN, data);
		return res.data
	}
});

export const fetchStatusEffect = createEffect<void, FetchStatusActionResponse>({
	handler: async () => {
		const req = await axios.get<FetchStatusActionResponse>(`${API_ENDPOINT}/auth/state`);
		return req.data
	}
});

export const setAuthStateEvent = createEvent<boolean>("setAuthStateEvent")

export const isAuthStore = createStore<boolean>(false, {name: "isAuthStore"})
	.on(fetchAuthEffect.doneData, (data, res) => res.success)
	.on(fetchStatusEffect.doneData, (store, data) => !data.is_authorized ? data.is_authorized : store)
	.on(setAuthStateEvent, (store, data) => data)

export const setUserEvent = createEvent<User>();

export const userStore = createStore<User | null>(null)
	.on(fetchAuthEffect.doneData, (store, data) => data.user ? data.user : store)
	.on(fetchStatusEffect.doneData, (store, data) => data.user ? data.user : store)
	.on(setUserEvent, (store, data) => data)

export const setSidEvent = createEvent<string | null>()

export const sidStore = createStore<string | null>(null, {name: "sidStore"})
	.on(fetchAuthEffect.doneData, (store, data) => data.sid ? data.sid : null)
	.on(setSidEvent, (store, data) => data)
	.on(fetchStatusEffect.doneData, (store, data) => data.is_authorized ? store : null)

export const activateSid = createEvent<boolean>();
export const $sidActivated = createStore<boolean>(false)
	.on(activateSid, (_, val) => val)


export interface SendPhoneActionResponse {
	uid: string | null;
	resend?: number;
	lifetime?: number
}

export const generateCodeByPhoneEffect = createEffect<string, SendPhoneActionResponse>({
	handler: async (phone: string) => {
		const req = await axios.post<SendPhoneActionResponse>(`${API_ENDPOINT}/auth/mobile/phone`, {phone});
		return req.data
	}
})

export const phoneStore = createStore<string | null>(null)
	.on(generateCodeByPhoneEffect, (store, data) => data)

export const uidAuthStore = createStore<string | null>(null)
	.on(generateCodeByPhoneEffect.doneData, (store, data) => data.uid)


export const verifyCodeEffect = createEffect<{ uid: string; code: string }, AuthResponse>({
	handler: async ({uid, code}) => {
		const req = await axios.post<AuthResponse>(`${API_ENDPOINT}/auth/mobile/code`, {uid, code});
		return req.data
	}
})

userStore.on(verifyCodeEffect.doneData, (store, data) => data.success ? data.user : store);
sidStore.on(verifyCodeEffect.doneData, (store, data) => data.sid);

export const setRegisteredEvent = createEvent<boolean>();

export const isRegisteredStore = createStore<boolean>(false)
	.on(setRegisteredEvent, (store, data) => data)

export const setEmail = createEvent<string>()
export const $email = createStore<string | null>(null)
	.on(fetchAuthEffect, (store, data) => data.email)
	.on(setEmail, (_, value) => value)

export const fetchStatusInterval = fromObservable(interval(10000));

guard(fetchStatusInterval, {filter: sidStore.map(val => val !== null), target: fetchStatusEffect})

export interface Employee {
	uid: string;
	name: string;
	avatar: string | null
}

export interface FetchAttachedManagerResultData {
	employee: Employee | null;
}

export const fetchAttachedManager = createEffect<void, FetchAttachedManagerResultData>().use(async () => {
	const req = await axios.get<FetchAttachedManagerResultData>(`${API_ENDPOINT}/client/employee`);
	return req.data
})

export const $attachedManager = createStore<Employee | null>(null)
	.on(fetchAttachedManager.doneData, (_, data) => data.employee)

guard({
	source: fetchAuthEffect.doneData,
	filter: userStore.map(val => val?.type === "client"),
	target: fetchAttachedManager
})

guard({
	source: fetchStatusEffect.doneData,
	filter: userStore.map(val => val?.type === "client"),
	target: fetchAttachedManager
})
