import React, { useEffect } from 'react';
import styles from './css/newsItems.module.css'
import { newsIcon } from "../../assets";
import { useHistory } from "react-router";
import { fetchNewsDetailEffect, fetchNewsEffect, newsStore } from "../../api/news";
import { useStore } from "effector-react";
import moment from "moment";

interface Props {
	setPath: any,
	path: string,
	setNewsId: any
}

const News = (props: Props) => {
	const history = useHistory();

	const news = useStore(newsStore);

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	useEffect(() => {
		fetchNewsEffect().then()
	}, []);

	const goTo = (id: string) => {
		let thisPath = window.location.pathname;
		props.setNewsId(id)
		history.push(`/news/${id}`);
		fetchNewsDetailEffect(id).then();
		props.setPath(thisPath)
	};

	const renderNews = () => {
		return news.map(obj => {
			return (
				<div className={styles.item} onClick={() => goTo(obj.uid)}>
					<div className={styles.iconTitle}><img src={newsIcon} alt=""/></div>
					<div className={styles.itemContentWrapper}>
						<div className={styles.itemInfo}>
							<div className={styles.titleItem}>{obj.category.name}</div>
							<div className={styles.name}>{obj.short}</div>
						</div>
						<div className={styles.wrapperTime}>
							<div className={styles.time}>{moment(obj.published_at).format("DD:MM:YYYY")}</div>
						</div>
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{renderNews()}
		</>
	);
}


export default News;
