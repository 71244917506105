import React, { useEffect, useRef, useState } from 'react';
import { LoginIcon } from "../../assets";
import {
	IonAlert,
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonInput,
	IonPage, IonSpinner,
	IonToolbar
} from "@ionic/react";
import Pin from "../../components/Pin";
import styles from "./css/SecondScreenLogIn.module.css";
import "./css/style.css"
import { InputChangeEventDetail } from "@ionic/core";
import { phoneStore, verifyCodeEffect, uidAuthStore, generateCodeByPhoneEffect } from "../../api/auth";
import { useStore } from "effector-react";
import { useHistory } from "react-router";

const SecondScreenLogIn: React.FC = () => {

	const history = useHistory();
	const [code, setCode] = useState("");
	const [loader, setLoader] = useState(false);
	const [alertWrongCode, setAlertWrongCode] = useState(false);
	const [alertSendTimeout, setAlertSendTimeout] = useState(false);

	const uidAuth = useStore(uidAuthStore);
	const phone = useStore(phoneStore);

	const onCodeChanged = (event: CustomEvent<InputChangeEventDetail>) => {
		setCode(event.detail?.value ?? "");
	}

	useEffect(() => {
		if (code.length === 6 && uidAuth) {
			verifyCodeEffect({uid: uidAuth, code}).then();
			setCode("");
		}
	}, [code])

	useEffect(() => {
		return verifyCodeEffect.doneData.watch(data => {
			if (data.success) {
				history.push("/pin");
			} else {
				setAlertWrongCode(true)
			}
		})
	}, [])

	useEffect(() => {
		return generateCodeByPhoneEffect.doneData.watch(data => {
			if (data.uid === null) {
				setAlertSendTimeout(true)
			}
		})
	}, []);

	useEffect(() => {
		return verifyCodeEffect.pending.watch(status => {
			setLoader(status)
		})
	}, [])

	useEffect(() => {
		return generateCodeByPhoneEffect.pending.watch(status => {
			setLoader(status)
		})
	}, [])

	const sendNewCode = async () => {
		phone && await generateCodeByPhoneEffect(phone)
	}

	const inputRef = (ref: HTMLIonInputElement | null) => {
		ref?.getInputElement().then(el => el.focus())
	}

	return (
		<IonPage>
			<IonHeader mode="ios">
				<IonToolbar color="#F8F7F7">
					<IonButtons slot="start">
						<IonBackButton text="Terug" color="primary"/>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonAlert
					isOpen={alertWrongCode}
					onDidDismiss={() => setAlertWrongCode(false)}
					header={'Error'}
					message={'Wrong Code'}
					buttons={['OK']}
				/>
				<IonAlert
					isOpen={alertSendTimeout}
					onDidDismiss={() => setAlertWrongCode(false)}
					header={'Error'}
					message={'Code sending timeout, please try again later'}
					buttons={['OK']}
				/>
				<div className={styles.mainWrapper}>
					<img className={styles.LoginIcon} src={LoginIcon} alt="login"/>
					<div className={styles.titleText}>Contact App</div>
				</div>
				<div className={styles.boldText}>
					Voer de code in om uw telefoon te verifiëren
				</div>
				<div className={styles.normalText}>
					<div>We hebben je een sms gestuurd met
						een codenaar het nummer
					</div>
					<span>+31 212 284 18</span>
				</div>
				<IonInput style={{opacity: 0, position: "absolute"}} inputmode="tel" type="tel"
				          onIonChange={onCodeChanged} value={code} maxlength={6} ref={inputRef}/>
				<div className={styles.wrapperPin}>
					<Pin styles={styles} pin={code} count={6}/>
				</div>
				<div className={styles.sendCode} onClick={sendNewCode}>Send a new code</div>
				<div className={styles.timer}>Available in <span>3</span> seconds</div>
				{loader && <div className={styles.preloaderContainer}>
					<IonSpinner/>
				</div>}
			</IonContent>
		</IonPage>
	);
};

export default SecondScreenLogIn;

