import { createEvent, createStore } from "effector";
import {
	changeQuestionCategory,
	changeQuestionTitle,
	closeQuestion,
	createQuestionEffect,
	reopenQuestion
} from "./questions";
import { requestChangeClientStatus } from "./clients";
import { requestChangeEmployee } from "./employees";

export const DEBUG = false;

export const API_ENDPOINT = "http://83.96.200.33/api";
export const LOGIN = API_ENDPOINT + "/auth/login";
export const GET_CLIENT_MESSAGES = API_ENDPOINT + "/client/questions";
export const GET_MANAGER_MESSAGES = API_ENDPOINT + "/employee/questions";

export const setPreloader = createEvent<boolean>();
export const $globalPreloader = createStore<boolean>(false)
	.on(setPreloader, (store, value) => value)
	.on(createQuestionEffect.pending, (_, state) => state)
	.on(requestChangeClientStatus.pending, (_, state) => state)
	.on(closeQuestion.pending, (_, state) => state)
	.on(reopenQuestion.pending, (_, state) => state)
	.on(changeQuestionTitle.pending, (_, state) => state)
	.on(changeQuestionCategory.pending, (_, state) => state)
	.on(requestChangeEmployee.pending, (_, state) => state)
