import React from "react";

export interface Props {
	color: string;
}

export const InboxIcon = ({color}: Props) => {
	return (

		<svg width="28" height="26" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path stroke={color?color:"#9B9B9B"} d="M21.8503 12.4375C24.5503 13.0375 26.5003 15.025 26.5003 17.35C26.4815 18.0623 26.2993 18.7608 25.9677 19.3915C25.6361 20.0222 25.164 20.5683 24.5878 20.9875L25.7503 23.875L21.8878 22.2625C21.3092 22.3911 20.718 22.454 20.1253 22.45C17.0503 22.45 14.4628 20.6875 13.8628 18.3625"  strokeLinecap="round" strokeLinejoin="round"/>
			<path stroke={color?color:"#9B9B9B"} d="M4.75 20.5L10.525 18.1375C11.3376 18.3175 12.1678 18.4056 13 18.4C17.9875 18.4 22 15.175 22 11.2C22 7.225 17.9875 4 13 4C8.0125 4 4 7.225 4 11.2C4 13.225 5.05 15.0625 6.7375 16.375L4.75 20.5Z"  strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

)
};


