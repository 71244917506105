import React, { useEffect, useState } from 'react';
import styles from './modal.module.css'
import BissnessIcon from "../client/SVGIcons/BissnessIcon";
import NonTypeIcon from "../client/SVGIcons/NonTypeIcon";
import { sendButtonIcon } from "../../assets";
import { $employees, fetchEmployees, requestChangeEmployee } from "../../api/employees";
import { useStore } from "effector-react";
import { activeQuestionUidStore, setActiveQuestionUidEvent } from "../../api/questions";
import { getAbbreviation } from "../../helpers/format";

interface Props {
	onClose: () => void
}

const ModalForward = (props: Props) => {

	const activeQuestionUid = useStore(activeQuestionUidStore)
	const employees = useStore($employees);

	useEffect(() => {
		fetchEmployees().then()
	}, [])

	const forwardQuestionHandler = (uidEmployee: string, message: string) => {
		activeQuestionUid && requestChangeEmployee({uidQuestion: activeQuestionUid, uidEmployee, message}).then();
	}

	const [message, setMessage] = useState("");
	const [selected, setSelected] = useState<string | null>(null)

	const onForwardClick = async () => {
		if (!selected) return;
		if (!message) return;
		await forwardQuestionHandler(selected, message);
		setSelected(null);
		setMessage("");
		setActiveQuestionUidEvent(null);
		props.onClose()
	}

	const onMessageChange = ( event : React.ChangeEvent<HTMLTextAreaElement>) => {
		setMessage(event.target.value);
	}

	const renderManagers = () => {
		return employees.map(em => {
			return (
				<div key={em.uid} className={styles.item}
				     style={selected === em.uid ? {backgroundColor: '#E0EFFF'} : {}}
				     onClick={() => setSelected(em.uid)}>
					<div className={styles.managerName}>{getAbbreviation(em.name)}</div>
					<span>{em.name}</span>
				</div>
			)
		})
	}

	return (
		<>
			<div className={styles.modalWrapper}>
				<div className={styles.mainContentWrapper}>
					<div className={styles.title}>Forward to</div>
					<div className={styles.selctManager}>select Onze manager</div>
					<div className={styles.managers}>
						{renderManagers()}
					</div>
					<div className={styles.textarea}>
						<textarea placeholder={'Deze klant heeft een moeilijke vraag. Help hem.'}
						          value={message} onChange={onMessageChange}/>
						<div className={styles.buttonIMG}><img src={sendButtonIcon} alt="sendButtonIcon"/></div>
					</div>
					<div className={styles.wrapperBut}>
						<div className={styles.cancel} onClick={props.onClose}>Cancel</div>
						<div className={styles.cancel} onClick={onForwardClick}>Forward</div>
					</div>
				</div>
			</div>
		</>
	);
}


export default ModalForward;
