import React from 'react';
import {
	IonAvatar,
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar
} from '@ionic/react';
import styles from './css/NewsDetail.module.css';
import { backArrowIcon, bitmapIcon, managerIcon } from "../../assets";
import { RelatedItem } from "../../components/RelatedItem";
import { useHistory } from "react-router";
import { useStore } from "effector-react";
import { activeNewsStore } from "../../api/news";


const NewsDetail: React.FC = () => {
	const history = useHistory();

	const activeNews = useStore(activeNewsStore);

	const goToNews = () => {
		history.push("/news");
	};

	const renderRelated = () => {
		return null;
		return (
			<div className={styles.wrapperRelated}>
				<div style={{
					display: "flex",
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingRight: '18px'
				}}>
					<div className={styles.related}>Related</div>
					<div onClick={() => goToNews()}>
						<div className={styles.seeAll}>See all <img className={styles.backArrowIcon}
						                                            src={backArrowIcon} alt="arrow"/></div>
					</div>
				</div>
				<RelatedItem name="POLITICS" text="U.K.’s Davis Slams Forecasts About Harmful Brexit"/>
				<RelatedItem name="SPORTS" text="First New Jersey Results Show Sports Bettors Are Better…"/>
				<RelatedItem name="CRYPTOCURRENCIES"
				             text="Treasuries Slide, Dollar Gains as Busy Week Begins: Markets "/>
			</div>
		)
	}

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{display: "flex", flex: 1, alignItems: "center"}}>
					<IonButtons slot="start">
						<IonBackButton text="" color="primary"/>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent color="white">
				<div className={styles.infoWrapper}>
					<div className={styles.type}>
						{activeNews?.category.name}
					</div>
					<div className={styles.title}>
						{activeNews?.title}
					</div>
					{activeNews?.image_src && <img src={activeNews?.image_src} alt="photo"/>}
				</div>
				<div style={{padding: "0 20px 20px 20px"}}
				     dangerouslySetInnerHTML={{__html: activeNews?.content ?? ""}}/>
				{renderRelated()}
			</IonContent>
		</IonPage>
	);
};

export default NewsDetail;
