import { IonAvatar, IonItem, IonLabel, IonText } from "@ionic/react";
import { managerIcon } from "../assets";
import React from "react";
import {Link} from "react-router-dom";

export interface Props {
	image: string | null
	name: string;
	text: string,
	data: string;
	onClick: () => void
}

export const NewsItem = ({text, name, data, image, onClick}: Props) => {
	return (
		<div style={{textDecoration: 'none'}} onClick={onClick}>
			<IonItem style={styles.item}>
				{image && <img style={{width: 56, height: 56,borderRadius: 12}} src={image}/>}
				<IonLabel style={styles.dataContainer}>
					<div style={styles.leftSideArea}>
						<h2 style={styles.itemTitle}>{name}</h2>
						<IonText style={styles.time}>{data}</IonText>
					</div>
					<p style={styles.lastMsg}>{text}</p>
				</IonLabel>
			</IonItem>
		</div>
	)
};

const styles: { [key: string]: React.CSSProperties } = {
	item: {
		height: 80,
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	dataContainer: {
		paddingLeft: 12
	},
	itemTitle: {
		color: "#007AFF",
		fontWeight: 600,
		fontSize: 13,
		lineHeight: '18px',
		letterSpacing: '-0.08px',
		textTransform: 'uppercase'
	},
	lastMsg: {
		fontSize: 15,
		fontWeight: 500,
		height: 40,
		lineHeight: '20px',
		color: '#000000',
		whiteSpace: 'pre-wrap'
	},
	leftSideArea: {
		display: "flex",
		justifyContent: "space-between",
	},
	time: {
		fontSize: 13,
		color: "#007AFF",
		fontWeight: 600,
		lineHeight: '18px'
	},
	unreadMsgCircle: {
		backgroundColor: "#33D165",
		width: 24,
		height: 24,
		color: "white",
		borderRadius: 24,
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 15
	}
};
