import { Plugins, Capacitor } from '@capacitor/core';

const {Storage} = Plugins;

export const saveDataToStorage = async (key: string, value: Object | string) => {
	if (typeof value === "string") return await Storage.set({key, value})
	const _value = JSON.stringify(value)
	await Storage.set({key, value: _value})
}

export const getStringFromStorage = async (key: string): Promise<string | null> => {
	const data = await Storage.get({key})
	return data.value
}

export const getTypeFromStorage = async <T>(key: string): Promise<T | null> => {
	const data = await Storage.get({key})
	if (data.value === null) return null;
	return JSON.parse(data.value) as T;
}

export const deleteDataFromStorage = async (key: string) => {
	await Storage.remove({key})
}

export const clearStorage = async () => {
	await Storage.clear();
}
