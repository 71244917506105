import { createEffect, createStore, forward, fromObservable, guard } from "effector";
import axios from "axios";
import { API_ENDPOINT } from "./index";
import { interval } from "rxjs";
import { isAuthStore } from "./auth";
import { APP_VARIANT, AppVariant } from "../config";

export const clientFetchInterval = fromObservable(interval(10000))

export interface Client {
	uid: string;
	name: string;
	gender: string;
	is_active: boolean;
	last_visit_at: string;
}

export type ClientStatus = "active" | "blocked"

//EFFECTS---------------------------------------------------------------------------------------------------------------

export const fetchClients = createEffect<void, { clients: Client[] }>()
	.use(async () => {
		const req = await axios.get(`${API_ENDPOINT}/employee/clients`);
		return req.data
	})

export const requestChangeClientStatus = createEffect<{ uid: string, status: ClientStatus }, { status: ClientStatus }>()
	.use(async ({status, uid}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/clients/${uid}/status`, {status});
		return req.data;
	})

//STORES----------------------------------------------------------------------------------------------------------------

export const $clients = createStore<Client[]>([])
	.on(fetchClients.doneData, (_, data) => data.clients);

//ADDITIONAL-----------------------------------------------------------------------------------------------------------

if (APP_VARIANT === AppVariant.Manager) {
	guard(clientFetchInterval, {filter: isAuthStore, target: fetchClients});
}
