import React from 'react';

const EditIcon = (props) => {

    return (
        <>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 24H23M18 4L22 8L11 19H7V15L18 4Z" stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </>
    );
}


export default EditIcon;
