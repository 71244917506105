import { createEvent, createStore } from "effector";

export const selectTab = createEvent<string>();
export const $selectedTab = createStore("")
	.on(selectTab, (_, val) => val)


export type CaseloadTab = "active" | "closed" | "non_signed";
export const setCaseLoadTab = createEvent<CaseloadTab>()
export const $caseLoadTab = createStore<CaseloadTab>("active")
	.on(setCaseLoadTab, (store, value) => value)

export type CaseloadDetailTab = "active" | "archived"
export const setCaseloadDetailTab = createEvent<CaseloadDetailTab>();
export const $caseLoadDetailTab = createStore<CaseloadDetailTab>("active")
	.on(setCaseloadDetailTab, (store, value) => value)

export const setClientTab = createEvent<"active" | "archived">();
export const $clientChatTab = createStore<"active" | "archived">("active")
	.on(setClientTab, (store, value) => value)
