import React, { useEffect, useState } from 'react';
import styles from './modal.module.css'
import BissnessIcon from "../client/SVGIcons/BissnessIcon";
import NonTypeIcon from "../client/SVGIcons/NonTypeIcon";
import { $categories, fetchCategories } from "../../api/categories";
import { useStore } from "effector-react";
import {
	activeQuestionUidStore,
	changeQuestionCategory, fetchManagerQuestionsEffect,
	ManagerQuestion,
	managerQuestionsStore
} from "../../api/questions";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { CircleAvatar } from "../CircleAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
	doneFunc: any,
	cancelFunc: any
}

const ModalSelectedType = (props: Props) => {

	const activeQuestionUid = useStore(activeQuestionUidStore);
	const categories = useStore($categories);
	const managerQuestions = useStore(managerQuestionsStore);

	const [selected, setSelected] = useState<string | null>(null);
	const [question, setQuestion] = useState<ManagerQuestion | null>()

	useEffect(() => {
		const find = managerQuestions.find(q => q.uid === activeQuestionUid);
		find && setQuestion(find);
		find?.category && setSelected(find.category?.identifier)
	}, [managerQuestions, activeQuestionUid])

	const changeCategoryHandler = async (category: string) => {
		activeQuestionUid && await changeQuestionCategory({uid: activeQuestionUid, category});
		await fetchManagerQuestionsEffect();
		props.doneFunc()
	}

	useEffect(() => {
		fetchCategories().then()
	}, [])


	const renderCategories = () => {
		return categories.map(cat => {
			const iconName = cat.icon || "faComment";
			// @ts-ignore
			const icon = faIcons[iconName];
			const iconObj = <div className={styles.change}><FontAwesomeIcon icon={icon} size={"lg"}/></div>;
			return (
				<div onClick={() => setSelected(cat.identifier)}
				     className={`${styles.item} ${selected === cat.identifier && styles.itemActive}`}>
					{iconObj}<span>{cat.name}</span>
				</div>
			)
		})
	}

	return (
		<>
			<div className={styles.modalWrapper}>
				<div className={styles.mainContentWrapper}>
					<div className={styles.mainPath}>
						{renderCategories()}
					</div>
					<div className={styles.footerPath}>
						<div onClick={props.cancelFunc} className={styles.cancel}>Cancel</div>
						<div onClick={() => selected && changeCategoryHandler(selected)} className={styles.done}>Done
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


export default ModalSelectedType;
