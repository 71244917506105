import React, { useEffect, useState } from 'react';
import {
	IonContent,
	IonHeader,
	IonList,
	IonPage, IonSpinner,
	IonText,
	IonToolbar
} from '@ionic/react';
import { useHistory } from "react-router";
import { useStore } from "effector-react";
import { ClientChatItem } from "../../components/ClientChatItem";
import {
	fetchClientQuestionsEffect,
	ClientQuestion,
	clientQuestionsStore,
	setActiveQuestionUidEvent
} from "../../api/questions";
import { $attachedManager, isAuthStore } from "../../api/auth";
import { AskNewIcon } from "../../components/icons/AskNew";
import { orderBy } from "lodash";
import { $clientChatTab, setClientTab } from "../../api/tabs";

const ClientChats: React.FC = () => {

	const history = useHistory();
	const questions = useStore(clientQuestionsStore);
	const authState = useStore(isAuthStore);

	const clientChatTab = useStore($clientChatTab)

	const [loader, setLoader] = useState(false);

	const goToQuestion = (question?: ClientQuestion) => {
		setActiveQuestionUidEvent(question?.uid ?? null);
		history.push("/chat", {question});
	};

	useEffect(() => {
		authState && fetchClientQuestionsEffect().then()
	}, [authState]);

	useEffect(() => {
		return fetchClientQuestionsEffect.pending.watch(v => setLoader(v))
	}, [])

	const tabFilter = (q: ClientQuestion) => {
		switch (clientChatTab) {
			case "active": return q.result === null;
			case "archived": return q.result !== null;
		}
	}

	const renderQuestions = () => {
		return orderBy(questions, ["result", "changed_at"], ["asc"]).filter(tabFilter).reverse().map(question => {
			return <ClientChatItem key={question.uid} question={question} onClick={() => goToQuestion(question)}/>
		})
	};

	const renderEmptyBlock = () => {
		if (questions.length > 2) return null;
		return (
			<IonContent>
				{renderLoader()}
				<div style={styles.emptyContainer}>
					<div style={styles.emptyGray} onClick={() => goToQuestion()}>Klik
						<IonText color="primary" style={{marginLeft: 5}}>vraag new</IonText>
						<AskNewIcon color={"#007AFF"} style={{marginLeft: 5, width: 28, height: 28}}/>
					</div>
					<div style={styles.emptyGray}>om een nieuw gesprek te</div>
					<div style={styles.emptyGray}>beginnen</div>
				</div>
			</IonContent>
		)
	};

	const renderQuestionBlock = () => {
		return (
			<IonContent style={{backgroundColor: "#F8F7F7"}}>
				<IonList>
					{renderQuestions()}
				</IonList>
			</IonContent>
		)
	};

	const renderLoader = () => {
		if (!loader) return null;
		return (
			<div style={{position: "absolute", top: "45%", left: "45%"}}>
				<IonSpinner color="primary"/>
			</div>
		)
	}

	const itemTabSelected = (val: string) => clientChatTab === val ? {...styles.itemTab, ...styles.itemTabActive} : styles.itemTab
	const itemTabArchivedSelected = (val: string) => clientChatTab === val ? {...styles.itemTabArchive, ...styles.itemTabArchiveActive} : styles.itemTabArchive

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{paddingRight: 10}}>
					<div style={styles.newAsk} onClick={() => goToQuestion()}>
						<IonText style={styles.blueText}>Vraag nieuw</IonText>
						<AskNewIcon color={"#007AFF"} style={{width: 28, height: 28, marginLeft: 5}}/>
					</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7" style={{display: "flex", flex: 1, justifyContent: "flex-start"}}>
					<div style={styles.title}>Vragen</div>
				</IonToolbar>
				<IonToolbar color="#F8F7F7">
					<div style={styles.wrapperTabs}>
						<div onClick={() => setClientTab("active")}
						     style={itemTabSelected("active")}>Active
						</div>
						<div onClick={() => setClientTab("archived")}
						     style={itemTabArchivedSelected("archived")}>Archived
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			{renderQuestionBlock()}
			{renderEmptyBlock()}
		</IonPage>
	);
};
export default ClientChats;

const styles: { [key: string]: React.CSSProperties } = {
	header: {
		height: 116,
		backgroundColor: "#F8F7F7",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	newAsk: {
		display: 'flex',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end"
	},
	blueText: {
		fontStyle: "normal",
		fontWeight: "bold",
		fontSize: 17,
		color: "#007AFF"
	},
	title: {
		marginLeft: 16,
		fontSize: 34,
		fontWeight: "bold",
		color: "black"
	},
	item: {
		marginTop: 10,
		marginBottom: 10,
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between"
	},
	itemTitle: {
		fontSize: 17,
		color: "#000000"
	},
	lastMsg: {
		fontSize: 15,
	},
	leftSideArea: {},
	date: {
		fontSize: 13,
		color: "#33D165"
	},
	unreadMsgCircle: {
		backgroundColor: "#33D165",
		width: 24,
		height: 24,
		color: "white",
		borderRadius: 24,
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 15
	},
	emptyContainer: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	emptyGray: {
		fontSize: 24,
		fontWeight: 500,
		color: "#B9B9B9",
		display: "flex",
		alignItems: "center"
	},
	wrapperTabs: {
		display: "flex",
		justifyContent: "flex-start",
		padding: "0 20px",
		marginBottom: "-18px"
	},
	itemTab: {
		transition: "400ms",
		textTransform: "uppercase",
		color: "rgba(0, 0, 0, 0.4)",
		borderBottom: "4px solid transparent",
		paddingBottom: "5px",
		marginRight: "35px",
		fontSize: "13px",
		lineHeight: "18px",
		letterSpacing: "-0.08px"
	},
	itemTabActive: {
		color: "#007AFF",
		borderBottom: "4px solid #007AFF"
	},
	itemTabArchive: {
		transition: "400ms",
		textTransform: "uppercase",
		color: "rgba(0, 0, 0, 0.4)",
		borderBottom: "4px solid transparent",
		paddingBottom: "5px",
		marginRight: "35px",
		fontSize: "13px",
		lineHeight: "18px",
		letterSpacing: "-0.08px"
	},
	itemTabArchiveActive: {
		color: "rgba(0, 0, 0, 0.85)",
		borderBottom: "4px solid rgba(0, 0, 0, 0.85)"
	}
};
