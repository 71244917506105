import {
	IonButtons,
	IonHeader,
	IonIcon,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonAvatar, IonContent, IonList, IonInput, IonCheckbox, IonAlert, IonRadio, IonToggle, IonTextarea
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import {
	arrowTopIcon,
	doneIcon,
	lockIcon,
	moreIcon,
} from "../../assets";
import "./style.css"
import { useHistory } from "react-router";
import { FadeModalSettings } from "../../components/FadeModalSettings";
import { RenameIcon } from "../../components/icons/RenameIcon";
import { useStore } from "effector-react";
import {
	addMessage,
	fetchManagerMessagesEffect,
	Message,
	messagesStore, sendManagerMessageAction, messagesFetchInterval
} from "../../api/messages";
import { List } from "immutable";
import {
	activeQuestionUidStore,
	fetchManagerQuestionsEffect,
	ManagerQuestion,
	managerQuestionsStore,
	setActiveQuestionUidEvent,
	closeQuestion,
	changeQuestionTitle,
	changeQuestionCategory,
	reopenQuestion
} from "../../api/questions";
import moment from "moment";
import { v4 } from "uuid";
import { InputChangeEventDetail } from "@ionic/core";
import { MiniAvatar } from "../../components/MiniAvatar";
import { GeneralIcon } from "../../components/icons/GeneralIcon";
import { CategoryIcon } from "../../components/icons/CategoryIcon";
import { $categories, fetchCategories } from "../../api/categories";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $employees, fetchEmployees, requestChangeEmployee } from "../../api/employees";
import { ForwardIcon } from "../../components/icons/ForwardIcon";
import { FadeForwardModal } from "../../components/FadeForwardModal";
import { ArchiveIcon } from "../../components/icons/ArchiveIcon";
import { formatDateTime } from "../../helpers/format";
import { isPlatform } from '@ionic/react';

export const IncomingChat = () => {

	const [settingOn, setSettingOn] = useState(false)
	const [changeCategory, setChangeCategory] = useState(false)
	const [forward, setForward] = useState(false)
	const [lockChat, setLockChat] = useState(false);
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState<List<Message>>(List());
	const [question, setQuestion] = useState<ManagerQuestion | null>(null);
	const [editTitle, setEditTitle] = useState(false);

	const history = useHistory();

	const allMessages = useStore(messagesStore);
	const activeQuestionUid = useStore(activeQuestionUidStore);
	const managerQuestions = useStore(managerQuestionsStore);
	const categories = useStore($categories);
	const employees = useStore($employees);

	const chatRef = useRef<HTMLIonContentElement | null>(null);

	useEffect(() => {
		fetchCategories().then();
		fetchEmployees().then()
	}, [])

	useEffect(() => {
		activeQuestionUid && fetchManagerMessagesEffect(activeQuestionUid).then(() => {
			setTimeout(() => {
				chatRef.current?.scrollToBottom();
			}, 300);
		})
	}, [activeQuestionUid]);

	useEffect(() => {
		activeQuestionUid && setMessages(allMessages.get(activeQuestionUid) || List())
	}, [allMessages]);

	useEffect(() => {
		const find = managerQuestions.find(q => q.uid === activeQuestionUid);
		find && setQuestion(find);
	}, [managerQuestions])

	useEffect(() => {
		return messagesFetchInterval.watch(() => {
			activeQuestionUid && fetchManagerMessagesEffect(activeQuestionUid).then()
		})
	}, [activeQuestionUid])

	useEffect(() => {
		if (question && question?.result !== null) {
			setLockChat(true);
		} else {
			setLockChat(false);
		}
	}, [question])

	useEffect(() => {
		return requestChangeEmployee.done.watch(data => {
			if (data.params.uidQuestion === activeQuestionUid) {
				history.goBack();
				setActiveQuestionUidEvent(null);
			}
		})
	}, [])

	const renderClientMessage = (message: Message) => {
		const messageStyle = {...styles.message, marginBottom: 7};
		const messageTimeStyle = {marginLeft: 10, fontSize: 11, color: "#869188"};
		return (
			<div key={message.uid} style={{marginBottom: 10}}>
				<div style={messageStyle}>
					<div style={{...styles.messageBox, backgroundColor: "rgba(255, 255, 255, 0.92)"}}>
						{message.text}
					</div>
				</div>
				<div style={messageTimeStyle}>{formatDateTime(message.created_at, true)}</div>
			</div>

		)
	};

	const renderOwnMessage = (message: Message) => {
		const messageItemStyle = {
			...styles.message,
			justifyContent: "flex-end",
		};
		return (
			<div style={{display: "flex", flexDirection: "column", marginBottom: 10}} key={message.uid}>
				<div style={messageItemStyle}>
					<div style={{...styles.messageBox, backgroundColor: "#C2DFFE", color: "#222222"}}>
						{message.text}
					</div>
				</div>
				<div style={{alignSelf: "flex-end", marginRight: 16}}>
					<span style={styles.messageTime}>{formatDateTime(message.created_at, true)}</span>
					<img src={doneIcon} style={{marginRight: 5}}/>
				</div>
			</div>
		)
	};

	const renderTopBlock = (text?: string) => {
		const container = {
			display: "flex",
			width: "100%",
			flex: 1,
			top: -1,
			justifyContent: "center",
			alignItems: "center",
			height: 32,
			backgroundColor: question?.category?.name === undefined ? "#66B980" : question?.result === null ? "#6CA3DF" : "#9B9B9B",
		};
		const textStyle = {
			textTransform: 'uppercase',
			fontSize: 15,
			fontWeight: 500,
			color: "white"
		};
		console.log("question", question)
		const iconName = question?.category?.icon || "faComment";
		// @ts-ignore
		const icon = faIcons[iconName];
		const iconCmp = <FontAwesomeIcon icon={icon} size={"lg"} color="white"/>
		return (
			<div style={container} slot="fixed">
				<div style={{position: "absolute", left: 10}}>{iconCmp}</div>
				<IonText style={textStyle}>{text}</IonText>
			</div>
		)
	};

	const renderInput = () => {
		const sendHandler = async () => {
			if (message.length === 0 || !activeQuestionUid || question?.result !== null) return;
			const uuid = v4();
			const newMessage = {
				created_at: moment().toISOString(),
				is_my: true,
				is_read: false,
				sender: null,
				text: message,
				uid: v4()
			}
			addMessage({message: newMessage, questionId: activeQuestionUid})
			await sendManagerMessageAction({questionId: activeQuestionUid, message, uuid});
			await fetchManagerMessagesEffect(activeQuestionUid);
			setMessage("");

			await fetchManagerQuestionsEffect()
		}
		const keyDown = async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
			if (event.keyCode === 13 && question?.uid && message.length > 0) {
				await sendHandler();
			}
		};
		const onChange = (event: CustomEvent<InputChangeEventDetail>) => {
			setMessage(event.detail.value || "")
		};
		const sendButtonStyle = {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: 30,
			height: 30,
			borderRadius: 15,
			backgroundColor: "#007AFF",
			marginRight: 4
		}
		return (
			<div style={styles.inputContainer} slot="fixed">
				<div style={styles.input}>
					<IonInput disabled={lockChat} style={styles.InputSettings} placeholder="Type a message" onKeyDown={keyDown} value={message} onIonChange={onChange}/>
					{message != '' ? <div style={sendButtonStyle} onClick={sendHandler}>
						<img src={arrowTopIcon}/>
					</div> : null}
				</div>
			</div>
		)
	};

	const renderMessages = () => {
		return messages.map(message => {
			return message.is_my ? renderOwnMessage(message) : renderClientMessage(message)
		})
	};

	const renderStatus = () => {
		switch (question?.result) {
			case null:
				return "In progress";
			case "failed":
				return "failed"
			case "success":
				return "success"
		}
	}

	const closeChatHandler = async () => {
		setSettingOn(false);
		if (question?.result === null) {
			activeQuestionUid && await closeQuestion({uid: activeQuestionUid, result: "archived"});
		} else {
			activeQuestionUid && await reopenQuestion({uid: activeQuestionUid});
		}
		await fetchManagerQuestionsEffect();
	}

	const changeTitleHandler = async (title: { 0: string }) => {
		activeQuestionUid && await changeQuestionTitle({uid: activeQuestionUid, title: title['0']});
		setSettingOn(false);
	}

	const changeCategoryHandler = async (category: string) => {
		activeQuestionUid && await changeQuestionCategory({uid: activeQuestionUid, category});
		setSettingOn(false);
		setChangeCategory(false);
	}

	const forwardQuestionHandler = (uidEmployee: string, message: string) => {
		activeQuestionUid && requestChangeEmployee({uidQuestion: activeQuestionUid, uidEmployee, message}).then();
	}
	// NonTypeIcon
	// BissnessIcon
	// GeneralIcon

	const renderCategories = () => {
		return categories.map(category => {
			const iconName = category?.icon || "faComment";
			// @ts-ignore
			const icon = faIcons[iconName];
			return (
				<div key={category.identifier} onClick={() => changeCategoryHandler(category.identifier)} style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					backgroundColor: '#F8F7F7'
				}}>
					<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}>
						<FontAwesomeIcon icon={icon} size={"lg"} color="#007AFF"
						                 style={{marginRight: 20}}/> {category.name}</span>
					<IonRadio value="sausage" mode={'md'} color="success"/>
				</div>
			)
		})
	}

	return (
		<IonPage>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{display: "flex", alignItems: "center"}}>
					<IonButtons slot="start" onClick={() => setActiveQuestionUidEvent(null)}>
						<IonBackButton text="" color="primary"/>
					</IonButtons>
					<div style={{marginLeft: '30px', display: "flex", alignItems: "center"}}>
						<MiniAvatar name={question?.client.name}/>
						<div style={{display: "flex", alignItems: "start", flexFlow: "column"}}>
							<span>{question?.client.name}</span>
							<span style={{
								fontSize: '13px',
								lineHeight: '20px', color: 'rgba(0,0,0,0.6)'
							}}>{renderStatus()}</span>
						</div>
					</div>
					<IonButtons slot="end">
						<img onClick={() => setSettingOn(!settingOn)} src={moreIcon} alt="moreIcon"/>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			{settingOn &&
			<FadeModalSettings value={settingOn} setValue={setSettingOn}>
				<div style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					color: "007AFF",
					backgroundColor: '#F8F7F7'
				}} onClick={closeChatHandler}>
					<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><ArchiveIcon
						style={{marginRight: '10px'}}
						color={'#007AFF'}/>{question && question?.result !== null ? "Unarchived" : "Archived"}</span>
				</div>
				{question && question?.result === null ? <div style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					backgroundColor: '#F8F7F7'
				}} onClick={() => {
					setSettingOn(false)
					setEditTitle(true);
				}}>
					<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><RenameIcon
						style={{marginRight: '10px'}} color={'#007AFF'}/>Rename</span>
				</div> : null}
				{question && question?.result === null ? <div style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					backgroundColor: '#F8F7F7'
				}} onClick={() => changeCategoryHandler("general")}>
					<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><GeneralIcon
						style={{marginRight: '10px'}} color={'#007AFF'}/>Move to GENERAL</span>
				</div> : null}
				{question && question?.result === null ? <div style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					backgroundColor: '#F8F7F7'
				}} onClick={() => {
					fetchEmployees().then();
					setForward(true);
					setSettingOn(false);
				}}>
					<span onClick={() => {
						setSettingOn(false);
						setForward(true)
					}} style={{display: "flex", alignItems: "center", color: "#007AFF"}}><ForwardIcon
						style={{marginRight: '10px'}} color={'#007AFF'}/>Forward</span>
				</div> : null}
				{question && question?.result === null ? <div onClick={() => {
					fetchCategories().then()
					setChangeCategory(!changeCategory);
					setSettingOn(!settingOn)
				}} style={{
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center",
					padding: '20px 28px 19px',
					borderBottom: '1px solid #D6D6D2',
					backgroundColor: '#F8F7F7'
				}}>
					<span style={{display: "flex", alignItems: "center", color: "#007AFF"}}><CategoryIcon
						style={{marginRight: '10px'}} color={'#007AFF'}/>Change Category</span>
				</div> : null}
			</FadeModalSettings>}
			{changeCategory &&
			<FadeModalSettings value={changeCategory} setValue={setChangeCategory} secondValue={settingOn}
			                   secondSetValue={setSettingOn}>
				{renderCategories()}
			</FadeModalSettings>}

			<IonContent className="ion-manager-content-chat" ref={chatRef}>
				{lockChat && <div slot="fixed" style={styles.lock}>
					<div style={styles.lockChat}>
						<ArchiveIcon color="#000000a6" width="48" height="42"/>
						<div style={{fontSize: 17, color: "#626463", marginTop: 5}}>Archived</div>
					</div>
				</div>}
				<IonAlert
					isOpen={editTitle}
					onDidDismiss={() => setEditTitle(false)}
					inputs={[{id: "() => title", value: question?.title ?? ""}]}
					header={'Enter New Title'}
					buttons={[
						{text: "Cancel"},
						{text: "Change", handler: changeTitleHandler}]}
				/>
				{/*{lockChat && <div slot="fixed" style={styles.lock}>*/}
				{/*	<img style={styles.lockChat} src={lockIcon}/>*/}
				{/*</div>}*/}

				{renderTopBlock(question?.title)}
				<IonList style={{paddingTop: 40, paddingBottom: 50}}>
					{renderMessages()}
				</IonList>
				{renderInput()}
			</IonContent>
			{forward && <FadeForwardModal employees={employees} onForward={forwardQuestionHandler} onClose={() => {
				setForward(false)
			}}/>}
		</IonPage>
	);
};

const styles: { [key: string]: React.CSSProperties } = {
	InputSettings: {
		textTransform: "capitalize"
	},
	lockChat: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: '80px'
	},
	lock: {
		display: "flex",
		flexFlow: "column",
		justifyContent: "flex-end",
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		zIndex: 100,
		top: 30,
		left: 0,
		right: 0,
		bottom: 0,
		pointerEvents: "none"
	},
	message: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
	},
	messageBox: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		marginLeft: 10,
		marginRight: 10,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 7,
		paddingBottom: 7,
		borderRadius: 17,
		maxWidth: "90%"
	},
	messageTime: {
		marginRight: 10,
		fontSize: 11,
		color: "#869188"
	},
	infoBlock: {
		height: 64,
		borderRadius: 18,
		boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
		backgroundColor: "white",
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		paddingLeft: 19,
		paddingRight: 19,
	},
	infoTitle: {
		marginTop: 13,
		fontSize: 18,
		color: "#222222",
		fontWeight: "bold"
	},
	category: {
		fontSize: 13,
		color: "#858585"
	},
	statusText: {
		fontSize: 17,
		fontWeight: "bold",
		display: "flex",
		alignItems: "center"
	},
	inputContainer: {
		height: 52,
		width: "100%",
		bottom: 0,
		backgroundColor: "#FBFAFA",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderTop: "1px solid #C9C8C8",
		paddingLeft: 16,
		paddingRight: 16
	},
	input: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		paddingLeft: 16,
		height: 36,
		border: "1px solid #EBEBEB",
		borderRadius: 16,
	}
};
