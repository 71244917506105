import React, { useEffect, useState } from 'react';
import styles from '../css/caseLoadItemsDetail.module.css'
import { useHistory } from "react-router";
import ArchiveIcon from "../SVGIcons/ArchiveIcon";
import BissnessIcon from "../client/SVGIcons/BissnessIcon";
import GeneralIcon from "../client/SVGIcons/GeneralIcon";
import { activeCaseloadUidStore, caseloadStore, GroupByUser } from "../../api/caseload";
import { useStore } from "effector-react";
import {
	activeQuestionUidStore,
	fetchManagerQuestionsEffect,
	ManagerQuestion,
	setActiveQuestionUidEvent
} from "../../api/questions";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { CircleAvatar } from "../CircleAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CaseDetailItem } from "../CaseDetailItem";
import moment from "moment";
import { $caseLoadDetailTab } from "../../api/tabs";
import { formatDateTime } from "../../helpers/format";

interface Props {
	setPath: any,
	path: string,
	id: string,
	setId: any,
	setIdItem: any,
	idItem: string,
	search: string,
	setSearch: any,
	setGroup: any,
	setScrollChat: any
}

const CaseLoadItemsDetail = (props: Props) => {

	const history = useHistory();

	const [activeCaseload, setActiveCaseload] = useState<GroupByUser | null>(null);

	const caseload = useStore(caseloadStore);
	const activeCaseloadUid = useStore(activeCaseloadUidStore);
	const caseLoadDetailTab = useStore($caseLoadDetailTab)
	const activeUid = useStore(activeQuestionUidStore)

	useEffect(() => {
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
	})

	useEffect(() => {
		if (activeCaseloadUid !== null) {
			const group = caseload.get(activeCaseloadUid);
			group && setActiveCaseload(group);
			props.setGroup(group)
		}
	}, [activeCaseloadUid, caseload])

	useEffect(() => {
		fetchManagerQuestionsEffect().then()
	}, [])

	const goBack = async (id: string) => {
		props.setIdItem(id)
		props.setSearch('')
		history.push(`/caseload/detail/${props.id}/${id}`);
		let thisPath = window.location.pathname;
		props.setPath(thisPath)
		props.setScrollChat(true)
	};

	const goToChat = (uid: string) => {
		props.setIdItem(uid)
		props.setSearch('')
		setActiveQuestionUidEvent(uid);
		history.push(`/caseload/detail/${props.id}/${uid}`);
		props.setScrollChat(true)
	}

	const questionFilter = (question: ManagerQuestion) => {
		if (caseLoadDetailTab === "active") {
			return question.result === null
		}
		if (caseLoadDetailTab === "archived") {
			return question.result !== null
		}
	}
	const getActiveSecondaryColor = (iconName: string,uid: string) => {
		return activeUid === uid ? iconName ==='faComment' ? '#75ca90': '#6CA3DF' : 'white'
	}

	const getArchivedPrimaryColor = (iconName: string,uid: string) => {
		return activeUid === uid ? iconName ==='faComment' ? '#75ca90': 'rgba(0, 0, 0, 0.4)' : 'white'
	}
	const getActivePrimaryColor = (iconName: string,uid: string) => {
		return activeUid === uid ? 'white' : iconName ==='faComment' ? '#75ca90': '#6CA3DF'
	}
	const getArchivedSecondaryColor = (iconName:string,uid: string) => {
		return activeUid === uid ? iconName ==='faComment' ? '#75ca90': 'white' : 'rgba(0, 0, 0, 0.4)';
	}

	const getActiveQuestionBackgroundColor = (iconName:string,uid: string) => {
		return activeUid === uid ?iconName ==='faComment' ?  {backgroundColor: '#75ca90'}: {backgroundColor: 'rgb(108, 163, 223)'} : {};
	}

	const getArchivedQuestionBackgroundColor = (iconName:string,uid: string) => {
		return activeUid === uid ? iconName ==='faComment' ?  {backgroundColor: '#75ca90'}: {backgroundColor: 'rgba(0, 0, 0, 0.4)'} : {backgroundColor: "white"};
	}

	const getBackgroundColor = (iconName:string,active: boolean, uid: string) => {
		return active ? getActiveQuestionBackgroundColor(iconName,uid) : getArchivedQuestionBackgroundColor(iconName,uid)
	}

	const getPrimaryIconBgColor = (iconName: string,active: boolean, uid: string) => {
		return active ? getActivePrimaryColor(iconName,uid) : getArchivedSecondaryColor(iconName,uid)
	}

	const getSecondaryIconBgColor = (iconName: string,active: boolean, uid: string) => {
		return active ? getActiveSecondaryColor(iconName,uid) : getArchivedPrimaryColor(iconName,uid)
	}

	const renderQuestions = () => {
		return activeCaseload?.questions.filter(questionFilter).filter(el => props.search != '' ? el.title.toLowerCase().includes(props.search): el).map(question => {

			const styleWhite = activeUid === question.uid ? {color: 'white'} : {}
			const timeStyle = activeUid === question.uid ? {color: 'white'} : question.unread_count ? {color: '#33D165'} : {}
			const iconName = question.category?.icon || "faComment";
			// @ts-ignore
			const icon = faIcons[iconName];
			const avatar = <CircleAvatar
				icon={<FontAwesomeIcon icon={icon} size={"lg"} color={getSecondaryIconBgColor(iconName,question.result === null, question.uid)}/>}
				color={getPrimaryIconBgColor(iconName,question.result === null, question.uid)}/>;
			const bgStyle = getBackgroundColor(iconName,question.result === null, question.uid);
			return (
				<div key={question.uid} className={styles.item} onClick={() => goToChat(question.uid)} style={bgStyle}>
					<div className={styles.wrapperMain}>
						<div className={styles.wrapperMainIcon}>
							{avatar}
						</div>
					</div>
					<div className={styles.itemContentWrapper}>
						<div className={styles.itemInfo}>
							<div className={styles.type} style={styleWhite}>{question.title.length < 20 ? question.title : `${question.title.slice(0,20)}...`}</div>
							<div className={styles.message} style={styleWhite}>{question.last_message.length < 25 ? question.last_message : `${question.last_message.slice(0,25)}...`}</div>
						</div>
						<div className={styles.wrapperTime}>
							<div style={timeStyle}
							     className={styles.time}>{formatDateTime(question.changed_at)}</div>
							<div className={styles.stateItem}>
								{question.unread_count > 0 && <div className={styles.wrapperState}>
									<div className={styles.countMessages}>1</div>
								</div>}
								{question.result !== null ? <div className={styles.wrapperState}><span
									style={{color: `${question.uid == props.idItem ? 'white': 'rgba(0, 0, 0, 0.6)'}`}}>archived</span><ArchiveIcon color={`${question.uid == props.idItem ? 'white': 'rgba(0, 0, 0, 0.6)'}`} /></div> : null}
							</div>
						</div>
					</div>
				</div>
			)

		})
	}

	return (
		<>
			{renderQuestions()}
		</>
	)
}


export default CaseLoadItemsDetail;
