import { combine, createEffect, createEvent, createStore, forward, guard, sample } from "effector";
import axios from "axios";
import { API_ENDPOINT, DEBUG } from "./index";

import { interval } from 'rxjs'
import { fromObservable } from 'effector'
import { isAuthStore, userStore } from "./auth";
import { APP_VARIANT, AppVariant } from "../config";
import { strict } from "assert";

export const questionsFetchInterval = fromObservable(interval(5000))

export type QuestionResult = null | "success" | "failed" | "archived"

export interface Question {
	uid: string;
	category: {
		name: string;
		identifier: string;
		icon: string;
	} | null;
	created_at: string;
	changed_at: string;
	title: string;
	unread_count: number;
	last_message: string;
	result: QuestionResult
}

export interface ClientQuestion extends Question {
	employee: {
		name: string;
		uid: string;
		avatar: string | null
	}
}

export interface ManagerQuestion extends Question {
	client: {
		name: string,
		gender: string,
		uid: string;
	}
}

export interface CreateQuestionActionParams {
	title: string;
	message: {
		content: string;
		uuid: string
	}
}

export interface CreateQuestionActionResponse {
	question: {
		uid: string;
	}
}

export const createQuestionEffect = createEffect<CreateQuestionActionParams, CreateQuestionActionResponse>({
	handler: async (data) => {
		const req = await axios.post(`${API_ENDPOINT}/client/questions/create`, data);
		return req.data
	}
})

export interface FetchQuestionsActionResponse {
	questions: ClientQuestion[]
}

export const fetchClientQuestionsEffect = createEffect<void, FetchQuestionsActionResponse>({
	handler: async () => {
		const req = await axios.get(`${API_ENDPOINT}/client/questions`);
		return req.data
	}
});

export const fetchManagerQuestionsEffect = createEffect<void, { questions: ManagerQuestion[] }>()
	.use(async () => {
		const req = await axios.get(`${API_ENDPOINT}/employee/questions`);
		return req.data
	})


export const clientQuestionsStore = createStore<ClientQuestion[]>([])
	.on(fetchClientQuestionsEffect.doneData, (store, data) => data.questions)

export const managerQuestionsStore = createStore<ManagerQuestion[]>([])
	.on(fetchManagerQuestionsEffect.doneData, (_, data) => data.questions);

export const setActiveQuestionUidEvent = createEvent<string | null>();

export const activeQuestionUidStore = createStore<string | null>(null)
	.on(setActiveQuestionUidEvent, (store, id) => id);

export const activeQuestionStore = createStore<ClientQuestion | null>(null)

export const activeManagerQuestionStore = createStore<ClientQuestion | null>(null)

export const unreadCountStore = createStore<number>(0)
	.on(fetchClientQuestionsEffect.doneData, (store, data) => {
		return data.questions.reduce((prev, obj) => prev + obj.unread_count, 0)
	})
	.on(fetchManagerQuestionsEffect.doneData, (store, data) => {
		return data.questions.reduce((prev, obj) => prev + obj.unread_count, 0)
	})

export const closeQuestion = createEffect<{ uid: string, result: QuestionResult }, { result: "success" | "failed" | "archived" | null }>()
	.use(async ({uid, result}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${uid}/close`, {result});
		return req.data
	})

export const reopenQuestion = createEffect<{ uid: string }, { result: "success" | "failed" | "archived" | null }>()
	.use(async ({uid}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${uid}/reopen`);
		return req.data
	})

switch (APP_VARIANT) {
	case AppVariant.Client:
		guard(questionsFetchInterval, {filter: isAuthStore, target: fetchClientQuestionsEffect});
		break;
	case AppVariant.Manager:
		guard(questionsFetchInterval, {filter: isAuthStore, target: fetchManagerQuestionsEffect});
		break;
	case AppVariant.Web:
		guard(questionsFetchInterval, {
			filter: combine(userStore, isAuthStore, (user, isAuth) => {
				return isAuth && user?.type === "client"
			}), target: fetchClientQuestionsEffect
		});
		guard(questionsFetchInterval, {
			filter: combine(userStore, isAuthStore, (user, isAuth) => {
				return isAuth && user?.type === "employee"
			}), target: fetchManagerQuestionsEffect
		});
		break;
}

export const changeQuestionTitle = createEffect<{ uid: string, title: string }, []>()
	.use(async ({uid, title}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${uid}/change`, {title});
		return req.data
	})

export const changeQuestionCategory = createEffect<{ uid: string, category: string }, []>()
	.use(async ({uid, category}) => {
		const req = await axios.post(`${API_ENDPOINT}/employee/questions/${uid}/change`, {category});
		return req.data
	})

forward({from: changeQuestionTitle, to: fetchManagerQuestionsEffect});
forward({from: changeQuestionCategory, to: fetchManagerQuestionsEffect});
forward({from: closeQuestion, to: fetchManagerQuestionsEffect});
forward({from: reopenQuestion, to: fetchManagerQuestionsEffect});
