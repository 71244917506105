import React, { useEffect } from 'react';
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, isPlatform} from '@ionic/react';
import styles from './settings.module.css';

import { useHistory } from "react-router";
import './style.css';
import { useStore } from "effector-react";
import {
	$email,
	fetchStatusEffect,
	phoneStore,
	setAuthStateEvent,
	setRegisteredEvent,
	setSidEvent,
	userStore
} from "../../api/auth";
import { CircleAvatar } from "../../components/CircleAvatar";
import {avatarIcon, searchIcon} from "../../assets";

import { CameraResultType, Plugins } from '@capacitor/core';
import { uploadAvatar } from "../../api/user";
import { clearStorage } from "../../api/storage";

const {Camera, App} = Plugins;

const Settings: React.FC = () => {

	const history = useHistory();
	const user = useStore(userStore);
	const phone = useStore(phoneStore);
	const email = useStore($email);

	const logOut = async (force: boolean = false) => {
		setSidEvent(null);
		setAuthStateEvent(false);
		force && setRegisteredEvent(false);
		force && await clearStorage()
		history.replace("/")
	}

	useEffect(() => {
		return uploadAvatar.fail.watch(() => {
			alert("Upload avatar error")
		})
	}, [])

	const getPhoto = async () => {
		const file = await Camera.getPhoto({
			quality: 80,
			width: 300,
			correctOrientation: true,
			resultType: CameraResultType.Base64
		})
		file.base64String && await uploadAvatar("data:image/jpeg;base64," + file.base64String);
		await fetchStatusEffect();
	}

	return (
		<IonPage style={{backgroundColor: "#F8F7F7"}}>
			<IonHeader style={{backgroundColor: "#F8F7F7"}} mode="ios">
				<IonToolbar color="#F8F7F7" style={{marginTop: `${isPlatform('ios') ? '-8px' : '0px'}`,display: "flex", height:`${isPlatform('ios') ? '90px' : '50px'}` , justifyContent: "flex-start"}}>
					<div className={styles.title}>Settings</div>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-content-my'>
				<div className={styles.mainWrapper}>
					<div style={{display: "flex"}}>
						{user?.avatar ? <img src={user?.avatar ?? avatarIcon} alt="avatarIcon"
						                     onClick={getPhoto}
						                     style={{width: 60, height: 60, borderRadius: 30}}/> :
							<CircleAvatar name={user?.name}/>}
						<div style={{display: "flex", flexFlow: 'column', marginLeft: 20}}>
							<div className={styles.name}>{user?.name}</div>
							<div className={styles.phone}>+31 {phone}</div>
							<div className={styles.mail}>{email}</div>
						</div>
					</div>
				</div>
				<div className={styles.wrapperItemSetting}>
					<div className={styles.border} style={{justifyContent: "center"}} onClick={() => logOut()}>
						<span className={styles.titleItem} style={{color: "#FA4C41"}}>Log Out</span>
					</div>
				</div>
				<div className={styles.wrapperItemSetting}>
					<div className={styles.border} style={{justifyContent: "center"}} onClick={() => logOut(true)}>
						<span className={styles.titleItem} style={{color: "#FA4C41"}}>Logout and Disconnect This Mobile App</span>
					</div>
				</div>
				{/*<div className={styles.wrapperItemSetting}>
					<img className={styles.SystemIcon} src={passwordIcon} alt=""/>
					<div className={styles.border}>
						<span className={styles.titleItem}>Password</span>
						<div className={styles.countDevise}>
							<img src={arrowIcon} alt=""/>
						</div>
					</div>
				</div>*/}
			</IonContent>
		</IonPage>
	);
};
export default Settings;


