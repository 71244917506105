import React from "react";

export interface Props {
	color: string;
	style?: any;
	width?: string;
	height?: string
}

export const ArchiveIcon = ({color, style, width = "24", height = "21"}: Props) => {
	return (
		<div style={style}>
			<svg width={width} height={height} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.7143 7.57142V18.7143H4.28571V7.57142M10.2857 11H13.7143M2.57143 3.28571H21.4286V7.57142H2.57143V3.28571Z"
					stroke={color} strokeOpacity="0.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</div>

	)
};
