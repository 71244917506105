import { IonLabel, IonText } from "@ionic/react";
import { keyIcon, lockIcon, NonSignedUpIcon } from "../assets";
import React from "react";
import styles from './css/CaseItem.module.css';
import moment from "moment";
import { CircleAvatar } from "./CircleAvatar";
import { formatDateTime } from "../helpers/format";

export interface Props {
	name: string;
	text?: string,
	time?: string;
	count?: number;
	lock?: boolean;
	block?: boolean;
	onClick?: () => void;
	nonSigned?: boolean
}

export const CaseItem = ({text, name, time, count, lock, block, onClick, nonSigned}: Props) => {

	const renderCount = (count?: number) => {
		if (count === 0 || count === undefined) return null;
		return <div className={styles.count}>{count}</div>
	}

	const renderNonSignedStatus = () => {
		return (
			<div style={{display: "flex", alignItems: "center", color: "#FA4C41",height: '20px'}}>
				Not signed up
				<img className={styles.lock} src={NonSignedUpIcon} alt="lockIcon"/>
			</div>
		)
	}

	return (
		<div style={{textDecoration: "none"}} onClick={onClick}>
			<div className={styles.item}>
				<CircleAvatar name={name} color={`${nonSigned? 'rgba(250, 76, 65, 0.6)' :'#6EA4DD'}`}/>
				<IonLabel className={styles.dataContainer}>
					<div className={styles.leftSideArea}>
						<h2 className={styles.itemTitle}>{name}</h2>
						{time && <IonText className={`${styles.time}`}>{formatDateTime(time)}</IonText>}
					</div>
					<div className={styles.wrapperText}>
						<p className={styles.lastMsg}>{text && text.length < 20 ? text : `${text && text.slice(0,20)}...`}</p>
						<div className={styles.wrapperIcon}>
							{block && <img className={styles.lock} src={lockIcon} alt="lockIcon"/>}
							{nonSigned && renderNonSignedStatus()}
							{renderCount(count)}
						</div>
					</div>
				</IonLabel>
			</div>
		</div>
	)
};


