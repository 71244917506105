import { createEvent, createStore } from "effector";
import { Map } from "immutable";
import { ManagerQuestion, managerQuestionsStore, QuestionResult } from "./questions";

export interface GroupByUser {
	questions: ManagerQuestion[];
	unreadCount: number;
	uid: string;
	clientName: string;
	lastDate: string;
	lastTitle: string;
	gender: string;
	closed: boolean
}

const getResult = (questions: ManagerQuestion[]): boolean => {
	const arrResults: QuestionResult[] = []
	for (const question of questions) {
		arrResults.push(question.result);
	}
	return !arrResults.includes(null);
}

export const caseloadStore = createStore<Map<string, GroupByUser>>(Map())
	.on(managerQuestionsStore, (store, questions) => {
		const users: { [ket: string]: ManagerQuestion[] } = {}
		questions.forEach(question => {
			if (!users[question.client.uid]) {
				users[question.client.uid] = []
			}
			users[question.client.uid]?.push(question)
		});
		let proxy: Map<string, GroupByUser> = Map();
		for (const [userUid, questions] of Object.entries(users)) {
			questions.sort((d1, d2) => d1 > d2 ? -1 : d1 == d2 ? 0 : 1);
			let unreadCount = questions.reduce((count, object) => count + object.unread_count, 0);
			const result = getResult(questions);
			const obj = {
				questions,
				unreadCount,
				uid: userUid,
				gender: questions[0].client.gender,
				clientName: questions[0].client.name,
				lastDate: questions[0].changed_at,
				lastTitle: questions[0].title,
				closed: result
			};
			proxy = proxy.set(userUid, obj)
		}
		return proxy;
	})

export const setActiveCaseloadEvent = createEvent<GroupByUser>();
export const setActiveCaseloadUidEvent = createEvent<string | null>()

export const activeCaseloadStore = createStore<GroupByUser | null>(null)
	.on(setActiveCaseloadEvent, (_, data) => data)

export const activeCaseloadUidStore = createStore<string | null>(null)
	.on(setActiveCaseloadUidEvent, (_, value) => value)
