import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const RenameIcon = ({color,style}: Props) => {
	return (
		<div style={style}>
			<svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.14288 19.5714H19.7143M15.4286 2.42859L18.8572 5.85716L9.4286 15.2857H6.00003V11.8572L15.4286 2.42859Z" stroke={color} stroke-opacity="0.6" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>

)
};


