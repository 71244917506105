import React from "react";

export interface Props {
	color: string;
	style?: any;
}

export const AskNewIcon = ({color, style}: Props) => {
	return (
		<div style={style}>
			<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M24.2667 5.02821C24.2649 4.85347 24.1972 4.69187 24.0741 4.57068L23.4212 3.9243C23.1619 3.66781 22.74 3.67063 22.4836 3.92712C22.2994 4.11032 22.1359 4.27567 22.0223 4.39029L23.61 5.96114C23.7237 5.84558 23.8872 5.68023 24.0704 5.49703C24.1972 5.37113 24.2667 5.2039 24.2667 5.02821ZM3.7334 8.34025V21.8109C3.7334 23.1666 4.83262 24.2705 6.18269 24.2705H19.6054C20.9554 24.2705 22.0537 23.1666 22.0537 21.8109V10.5894L20.7337 11.9038V21.8109C20.7337 22.4366 20.2273 22.9448 19.6054 22.9448H6.18269C5.55979 22.9448 5.0534 22.4366 5.0534 21.8109V8.34025C5.0534 7.71548 5.55979 7.20721 6.18269 7.20721H16.2626L17.5948 5.88063H6.18269C4.83262 5.88063 3.7334 6.98454 3.7334 8.34025ZM11.9173 14.4562L11.3461 16.5842L13.506 16.0233L23.1444 6.41877L21.5595 4.84698L11.9173 14.4562Z"
				      fill={color}/>
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="22">
					<path fillRule="evenodd" clipRule="evenodd"
					      d="M24.2667 5.02821C24.2649 4.85347 24.1972 4.69187 24.0741 4.57068L23.4212 3.9243C23.1619 3.66781 22.74 3.67063 22.4836 3.92712C22.2994 4.11032 22.1359 4.27567 22.0223 4.39029L23.61 5.96114C23.7237 5.84558 23.8872 5.68023 24.0704 5.49703C24.1972 5.37113 24.2667 5.2039 24.2667 5.02821ZM3.7334 8.34025V21.8109C3.7334 23.1666 4.83262 24.2705 6.18269 24.2705H19.6054C20.9554 24.2705 22.0537 23.1666 22.0537 21.8109V10.5894L20.7337 11.9038V21.8109C20.7337 22.4366 20.2273 22.9448 19.6054 22.9448H6.18269C5.55979 22.9448 5.0534 22.4366 5.0534 21.8109V8.34025C5.0534 7.71548 5.55979 7.20721 6.18269 7.20721H16.2626L17.5948 5.88063H6.18269C4.83262 5.88063 3.7334 6.98454 3.7334 8.34025ZM11.9173 14.4562L11.3461 16.5842L13.506 16.0233L23.1444 6.41877L21.5595 4.84698L11.9173 14.4562Z"
					      fill={color}/>
				</mask>
				<g mask="url(#mask0)">
				</g>
			</svg>
		</div>

	)
};


